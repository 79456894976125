import { ChangeEvent } from "react";
import { KeyResult } from "types";
import { NumberInput } from "components/shared";

type KeyResultDeltaInputProps = {
  keyResult: KeyResult;
  disabled?: boolean;
  onChange?: (value: KeyResult) => void;
};

export const KeyResultDeltaInput = ({
  keyResult,
  disabled,
  onChange,
}: KeyResultDeltaInputProps) => {
  const handleChange = (name: string) => {
    return (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      if (onChange) {
        onChange({
          ...keyResult,
          [name]: event.target.value,
        });
      }
    };
  };

  return (
    <div className="inline-block mx-2 relative rounded-md shadow-sm border border-gray-300">
      <div className="flex flex-row">
        <div className="inset-y-0 left-0 flex items-center">
          <select
            name="deltaDirection"
            className="h-full py-0 pl-2 pr-7 mr-1 border-transparent focus:outline-none focus:ring-zinc-400 focus:border-zinc-400 text-gray-500 sm:text-sm rounded-md"
            value={keyResult.deltaDirection}
            onChange={handleChange("deltaDirection")}
            disabled={disabled}
          >
            <option value="increase">Increase</option>
            <option value="maintain">Maintain</option>
            <option value="decrease">Decrease</option>
            <option value="new">New</option>
          </select>
        </div>
        <div className="ml-1">
          <NumberInput
            disabled={disabled}
            name="deltaAmount"
            borderColor="border-transparent"
            width="w-20"
            min={0}
            textSize="text-sm"
            value={keyResult.deltaAmount}
            onChange={(value) =>
              onChange &&
              onChange({
                ...keyResult,
                deltaAmount: value,
              })
            }
          />
        </div>
        <div className="ml-1">
          <select
            disabled={disabled}
            name="deltaUnit"
            className="h-full py-0 pl-3 pr-7 border-transparent focus:outline-none focus:ring-zinc-400 focus:border-zinc-400 text-gray-500 sm:text-sm rounded-md"
            value={keyResult.deltaUnit}
            onChange={handleChange("deltaUnit")}
          >
            <option value="percent">%</option>
            <option value="fixed">Units</option>
          </select>
        </div>
      </div>
    </div>
  );
};
