import React, { SyntheticEvent } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useSelector } from "store";
import { selectSolutionByID } from "store/reducers";
import { Solution, UUID } from "types";
import {
  BluePill,
  FlexContainer,
  GrayPill,
  GreenPill,
  ListItem,
  SolutionModal,
} from "components/shared";
import { useModal } from "hooks/useModal";

type SolutionListItemProps = {
  solutionId: UUID;
  disabled?: boolean;
  onRemove?: (value: Solution) => void;
};

export const SolutionListItem = ({
  solutionId,
  disabled,
  onRemove,
}: SolutionListItemProps) => {
  const { openModal } = useModal();
  const entity = useSelector(selectSolutionByID(solutionId));

  if (!entity) {
    return null;
  }

  const renderEffectiveness = () => {
    switch (entity.capexImpact) {
      case "low":
        return <GrayPill label="Somewhat effective" />;
      case "medium":
        return <BluePill label="Effective" />;
      case "high":
        return <GreenPill label="Very effective" />;
      default:
        return null;
    }
  };

  const renderCost = () => {
    switch (entity.capexImpact) {
      case "low":
        return <GreenPill label="Low cost" />;
      case "medium":
        return <BluePill label="Medium cost" />;
      case "high":
        return <GrayPill label="Expensive" />;
      default:
        return null;
    }
  };

  const openSolutionModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    openModal(<SolutionModal solutionId={entity.id} />);
  };

  return (
    <ListItem>
      <FlexContainer alignItems="items-center" justify="justify-between">
        <FlexContainer
          direction="flex-col"
          className="cursor-pointer"
          onClick={openSolutionModal}
        >
          <div className="mb-1">{entity.name}</div>
          <div className="flex gap-2">
            {renderEffectiveness()}
            {renderCost()}
          </div>
        </FlexContainer>
        {!disabled && onRemove && (
          <FlexContainer>
            <button
              type="button"
              className="bg-white p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={() => onRemove(entity)}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </ListItem>
  );
};
