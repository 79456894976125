import { useDispatch, useSelector } from "react-redux";
import { ReactNode } from "react";
import { focusBlock } from "store/reducers";
import {
  selectBlockByID,
  selectFocusedBlockID,
} from "store/reducers/sheets/blocks.selectors";
import { TBlockType, UUID } from "types";
import {
  BlockControllers,
  DragItem,
  TextBlock,
  NoticeBlock,
  YoutubeBlockFrame,
  FileBlock,
} from "components/shared/cms";
import { Text } from "components/shared";
import { ImageBlock } from "components/shared/cms/ImageBlock/ImageBlock";

type SheetBlockProps = {
  blockId: UUID;
  onCreateBlock?: (type: TBlockType) => void;
  onRemoveBlock?: () => void;
};

export const SheetBlock = ({
  blockId,
  onCreateBlock,
  onRemoveBlock,
}: SheetBlockProps) => {
  const dispatch = useDispatch();
  const block = useSelector(selectBlockByID(blockId));
  const focusedBlockID = useSelector(selectFocusedBlockID);
  const active = blockId === focusedBlockID;

  const handleClick = () => {
    dispatch(focusBlock(blockId));
  };

  const renderContent = (): ReactNode => {
    if (!block) {
      return null;
    }

    const commonProps = {
      block,
      focused: active,
      onAddBlock: onCreateBlock,
      onRemoveBlock: onRemoveBlock,
    };

    switch (block?.type) {
      case "text":
        return <TextBlock key={block.id} {...commonProps} />;
      case "header1":
        return <TextBlock key={block.id} tag="h1" {...commonProps} />;
      case "header2":
        return <TextBlock key={block.id} tag="h2" {...commonProps} />;
      case "header3":
        return <TextBlock key={block.id} tag="h3" {...commonProps} />;
      case "notice":
        return <NoticeBlock key={block.id} {...commonProps} />;
      case "youtube":
        return <YoutubeBlockFrame key={block.id} {...commonProps} />;
      case "image":
        return <ImageBlock key={block.id} {...commonProps} />;
      case "file":
        return <FileBlock key={block.id} {...commonProps} />;
      default:
        return (
          <Text
            textColor="text-gray-400"
            lineHeight="leading-8"
            textSize="text-base"
          >
            Block type '{block.type}' is coming soon
          </Text>
        );
    }
  };

  return block ? (
    <DragItem
      index={block.order}
      active={active}
      dragHandle={
        <BlockControllers blockId={blockId} onCreateBlock={onCreateBlock} />
      }
      onClick={handleClick}
    >
      {renderContent()}
    </DragItem>
  ) : null;
};
