import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CoreMetric,
  CreateCoreMetricRequest,
  DataResponse,
  UpdateCoreMetricRequest,
} from "types";
import { API } from "lib/API";

export const fetchCoreMetrics = createAsyncThunk(
  "coreMetrics/list",
  async (): Promise<CoreMetric[]> => {
    const { data } = await API.GET<DataResponse<CoreMetric[]>>("/core-metrics");
    return data;
  }
);

export const createCoreMetric = createAsyncThunk(
  "coreMetrics/create",
  async (request: CreateCoreMetricRequest): Promise<CoreMetric> => {
    const { data } = await API.POST<
      CreateCoreMetricRequest,
      DataResponse<CoreMetric>
    >("/core-metrics", request);
    return data;
  }
);

export const updateCoreMetric = createAsyncThunk(
  "coreMetrics/update",
  async (request: UpdateCoreMetricRequest): Promise<CoreMetric> => {
    const { data } = await API.PUT<
      UpdateCoreMetricRequest,
      DataResponse<CoreMetric>
    >(`/core-metrics/${request.id}`, request);
    return data;
  }
);
