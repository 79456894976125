import { ComponentProps } from "react";
import classNames from "classnames";
import { ChatMessageAuthor } from "features/conversations/types";
import userLogo from "assets/images/person.png";

export type ChatBubbleProps = ComponentProps<"div"> & {
  author: ChatMessageAuthor;
};

export const ChatBubble = ({ author, children, ...props }: ChatBubbleProps) => {
  return (
    <div
      className={classNames(
        "flex flex-row items-end justify-start gap-2",
        author === ChatMessageAuthor.User && "flex-row-reverse"
      )}
    >
      {author === ChatMessageAuthor.User && (
        <img
          src={userLogo}
          className="rounded-2xl shadow-lg w-16 h-16 bg-no-repeat object-cover"
        />
      )}
      <div
        {...props}
        className={classNames(
          "p-4 rounded-3xl shadow-sm w-fit max-w-[85%] whitespace-pre-line",
          author === ChatMessageAuthor.Bot
            ? "self-start rounded-bl-none bg-white text-gray-900"
            : "self-end rounded-br-none bg-green-500 text-white",
          props.className
        )}
      >
        {children}
      </div>
    </div>
  );
};
