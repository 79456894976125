import { DecisionChatProvider } from "features/decisions/providers";
import { ChatVideo, DecisionChat } from "features/decisions/components";
import styled from "styled-components";
import { Planet } from "features/planet/Planet";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  position: relative;
  border-radius: 1.5rem;
  background-color: #f8fafc;
  border: 1px solid rgb(243, 244, 246);
  padding: 2rem 1.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 60rem;
  z-index: 1;
  align-items: end;
`;

export const CreateDecisionPage = () => {
  return (
    <div className="flex pb-24">
      <div className="fixed z-0">
        <Planet />
      </div>
      <DecisionChatProvider>
        <Card>
          <ChatVideo />
          <DecisionChat />
        </Card>
      </DecisionChatProvider>
    </div>
  );
};
