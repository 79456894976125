import { Outlet } from "react-router-dom";
import { AppSidebar } from "components/shared";
import { useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import classNames from "classnames";

export const SidebarLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <AppSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16">
        <button
          type="button"
          className={classNames("px-4 focus:outline-none text-white")}
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Outlet />
    </>
  );
};
