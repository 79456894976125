import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { useDispatch } from "store";
import { TBlock, TBlockType, UUID } from "types";
import {
  updateBlocks,
  selectBlocksBySheetID,
  selectFocusedBlockID,
  focusBlock,
  deleteBlock,
  createBlock,
} from "store/reducers";
import {
  DragContainer,
  KEY_CODE_ARROW_DOWN,
  KEY_CODE_ARROW_UP,
  KEY_EVENT_DOWN,
  KeyHandler,
  SheetBlock,
} from "components/shared/cms";

type SheetBlocksProps = {
  sheetId: UUID;
};

export const SheetBlocks = ({ sheetId }: SheetBlocksProps) => {
  const dispatch = useDispatch();
  const blocks = useSelector(selectBlocksBySheetID(sheetId));
  const focusedBlockID = useSelector(selectFocusedBlockID);
  const ordered = orderBy(blocks, ["order", "asc"]);

  const handleSortEnd = async ({ oldIndex, newIndex }: any) => {
    const rearranged = arrayMoveImmutable(ordered, oldIndex, newIndex);
    for (let i = 0; i < ordered.length; i++) {
      rearranged[i] = {
        ...rearranged[i],
        order: i,
      };
    }
    dispatch(updateBlocks(rearranged));
  };

  const handleSortStart = () => {
    // TODO: Do we need to add zIndex here?
  };

  const focusPreviousBlock = () => {
    for (let i = 0; i < ordered.length; i++) {
      if (ordered[i].id === focusedBlockID && i !== 0) {
        dispatch(focusBlock(ordered[i - 1].id));
        return;
      }
    }
  };

  const focusNextBlock = () => {
    for (let i = 0; i < ordered.length; i++) {
      if (ordered[i].id === focusedBlockID && i < ordered.length - 1) {
        dispatch(focusBlock(ordered[i + 1].id));
        return;
      }
    }
  };

  const handleCreateBlock = (index: number, type: TBlockType) => {
    const blocksCache = [...ordered];
    dispatch(
      createBlock({
        type: type,
        sheetId: sheetId,
        order: index + 1,
        properties: {
          value: "",
        },
      })
    )
      .unwrap()
      .then((newBlock) => {
        dispatch(focusBlock(newBlock.id));

        // Reorder the blocks
        blocksCache.splice(index + 1, 0, newBlock);
        for (let i = 0; i < blocksCache.length; i++) {
          blocksCache[i] = {
            ...blocksCache[i],
            order: i,
          };
        }
        dispatch(updateBlocks(blocksCache));
      });
  };

  const handleRemoveBlock = (index: number) => {
    const currentBlock = ordered[index];
    const previousBlockId = index !== 0 ? ordered[index - 1].id : undefined;
    const nextBlockId =
      index < ordered.length - 1 ? ordered[index + 1].id : undefined;

    if (previousBlockId) {
      dispatch(focusBlock(previousBlockId));
    } else if (nextBlockId) {
      dispatch(focusBlock(nextBlockId));
    } else {
      dispatch(focusBlock(undefined));
    }

    dispatch(deleteBlock(currentBlock.id));
  };

  return (
    <>
      <KeyHandler
        keyCode={KEY_CODE_ARROW_UP}
        keyEventName={KEY_EVENT_DOWN}
        onKeyHandle={focusPreviousBlock}
      />
      <KeyHandler
        keyCode={KEY_CODE_ARROW_DOWN}
        keyEventName={KEY_EVENT_DOWN}
        onKeyHandle={focusNextBlock}
      />
      <DragContainer
        distance={5}
        onSortEnd={handleSortEnd}
        onSortStart={handleSortStart}
        useDragHandle
      >
        {ordered.map(({ id }: TBlock, index) => (
          <SheetBlock
            key={id}
            blockId={id}
            onCreateBlock={(type) => handleCreateBlock(index, type)}
            onRemoveBlock={() => handleRemoveBlock(index)}
          />
        ))}
      </DragContainer>
    </>
  );
};
