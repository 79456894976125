import { Objective } from "types";
import { ChangeEvent, useState } from "react";
import { InputGroup, TextInput } from "components/shared/inputs";
import { useDispatch } from "store";
import { openPopupFlag, updateObjective } from "store/reducers";

type ObjectiveFormProps = {
  entity: Objective;
};

export const ObjectiveForm = ({ entity }: ObjectiveFormProps) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(entity);
  const [dirty, setDirty] = useState(false);

  const handleBlur = () => {
    if (dirty) {
      saveChanges();
    }
  };

  const saveChanges = () => {
    dispatch(updateObjective(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Objective saved",
          })
        );
      });
  };

  const handleChange = (name: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setDirty(true);
      setValues({
        ...values,
        [name]: event.target.value,
      });
    };
  };

  return (
    <div className="max-w-lg">
      <InputGroup label="Name">
        <TextInput
          name="name"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Description">
        <TextInput
          name="description"
          value={values.description}
          onChange={handleChange("description")}
          onBlur={handleBlur}
        />
      </InputGroup>
    </div>
  );
};
