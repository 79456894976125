import { useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { Combobox, Portal } from "@headlessui/react";
import { EyeIcon, EyeOffIcon, SearchIcon } from "@heroicons/react/outline";
import { Solution, UUID } from "types";
import { useSelector } from "store";
import { selectSolutionsList } from "store/reducers";
import { Popover } from "components/shared/popovers/Popover/Popover";
import {
  SolutionSelectorItem,
  SolutionSelectorButton,
  SolutionSelectorEmpty,
  SecondaryButton,
} from "components/shared";

type SolutionSelectorProps = {
  label?: string;
  selected?: UUID[];
  coreMetricIds?: UUID[];
  onSelect: (value: Solution) => void;
};

export const SolutionSelector = ({
  label,
  selected,
  coreMetricIds,
  onSelect,
}: SolutionSelectorProps) => {
  const [focused, setFocused] = useState<boolean>(true);
  const solutionsList = useSelector(selectSolutionsList);
  const focusOnCoreMetrics: Record<UUID, UUID> = useMemo(() => {
    if (!coreMetricIds) {
      return {};
    }
    return coreMetricIds.reduce(
      (values, coreMetricId) => ({ ...values, [coreMetricId]: coreMetricId }),
      {}
    );
  }, [coreMetricIds]);

  const availableOptions = solutionsList.filter(({ id, coreMetricIDs }) => {
    // If an option is already selected, never show it.
    if (selected && selected.indexOf(id) !== -1) {
      return false;
    }

    // If the user is focusing on relevant solutions, only show solutions associated with the core metrics.
    if (focused) {
      const exists = coreMetricIDs.find(
        (coreMetricID) => focusOnCoreMetrics[coreMetricID]
      );
      return !!exists;
    }

    return true;
  });

  // Pick solution button
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
  });

  const [query, setQuery] = useState("");
  const filteredSolutions =
    query === ""
      ? []
      : availableOptions.filter((solution) => {
          return solution.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button as="div" ref={setReferenceElement}>
            <SolutionSelectorButton label={label} open={open} />
          </Popover.Button>
          <Portal>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="flex flex-row z-30 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 rounded-lg"
            >
              <Combobox
                as="div"
                className="divide-gray-100 w-72 transition-all"
                onChange={() => undefined}
                value={undefined}
              >
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                    placeholder="Search..."
                    autoFocus
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  {focused ? (
                    <SecondaryButton
                      leadingIcon={EyeIcon}
                      rounding="rounded-none"
                      onClick={() => setFocused(false)}
                    >
                      Show all
                    </SecondaryButton>
                  ) : (
                    <SecondaryButton
                      leadingIcon={EyeOffIcon}
                      rounding="rounded-none"
                      onClick={() => setFocused(true)}
                    >
                      Show recommended
                    </SecondaryButton>
                  )}
                </div>
                {availableOptions.length === 0 ||
                (query !== "" && filteredSolutions.length === 0) ? (
                  <SolutionSelectorEmpty query={query} />
                ) : (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {(query === "" ? availableOptions : filteredSolutions).map(
                      (solution) => (
                        <SolutionSelectorItem
                          key={solution.id}
                          solution={solution}
                          onClick={() => {
                            onSelect(solution);
                            close();
                          }}
                        />
                      )
                    )}
                  </Combobox.Options>
                )}
              </Combobox>
            </Popover.Panel>
          </Portal>
        </>
      )}
    </Popover>
  );
};
