import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataResponse, TFile, UUID } from "types";
import { API } from "lib/API";

export const fetchFiles = createAsyncThunk(
  "files/list",
  async (): Promise<TFile[]> => {
    const { data } = await API.GET<DataResponse<TFile[]>>("/files");
    return data;
  }
);

export const fetchFile = createAsyncThunk(
  "files/getById",
  async (id: UUID): Promise<TFile> => {
    const { data } = await API.GET<DataResponse<TFile>>(`/files/${id}`);
    return data;
  }
);

export const createFile = createAsyncThunk(
  "files/create",
  async (file: File): Promise<TFile> => {
    const formData = new FormData();
    formData.append("file", file);
    const { data } = await API.POST<FormData, DataResponse<TFile>>(
      "/files",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }
);
