import { ChatMessage } from "features/conversations/types";
import { Text } from "components/shared";

export type TextChatMessageProps = {
  message: ChatMessage;
};

export const TextChatMessage = ({ message }: TextChatMessageProps) => {
  return <Text>{message.message}</Text>;
};
