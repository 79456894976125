import { Fragment, ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { Popover } from "components/shared/popovers/Popover/Popover";
import { UUID } from "types";
import { Heading, Text } from "components/shared/typography";
import { DataGrid, DataItem } from "components/shared/layouts";
import { DelayRender } from "components/shared/layouts/DelayRender/DelayRender";

type CoreDashboardMetricPoliciesPopoverProps = {
  coreMetricId: UUID;
  children: ReactNode;
};

const PopoverContent = ({
  coreMetricId,
}: Pick<CoreDashboardMetricPoliciesPopoverProps, "coreMetricId">) => {
  const renderMockContent = () => {
    switch (coreMetricId) {
      // TONY CHECK THIS OUT
      // Human development index
      case "00000000-0000-0000-0000-000000000000":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-sky-400">0.737</Text>}
            />
            <DataItem label="Date published" value={<Text>2019</Text>} />
            <DataItem label="Since" value={<Text>1990</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  United Nations Development Programme (UNDP). See:
                  <a
                    href="https://hdr.undp.org/en/content/latest-human-development-index-ranking"
                    target="_blank"
                  >
                    https://hdr.undp.org/en/content/latest-human-development-index-ranking
                  </a>
                  . latest reports are available here:
                  <a
                    href="https://hdr.undp.org/en/2020-report/download"
                    target="_blank"
                  >
                    https://hdr.undp.org/en/2020-report/download
                  </a>
                  . Data available in Table 1 of the full report.
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>
                  The Human Development Index (HDI) was designed to incorporate
                  human development as a criterion for measuring a country's
                  development, rather than simply relying on just economic
                  growth factors. HDI is a metric in the range 0 to 1 (with 1
                  highest), and is derived by combining three indices on life
                  expectancy, level of education, and standard of living. For a
                  description of this metric see:{" "}
                  <a
                    href="https://hdr.undp.org/en/content/human-development-index-hdi"
                    target="_blank"
                  >
                    https://hdr.undp.org/en/content/human-development-index-hdi
                  </a>
                  .
                </Text>
              }
            />
          </DataGrid>
        );
      // CO2
      case "1baa89e3-70d6-4128-8a8c-67c46e2d864e":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-rose-400">417 ppm</Text>}
            />
            <DataItem label="Date" value={<Text>April 2022</Text>} />
            <DataItem label="Since" value={<Text>2005</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ice-sheets/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ice-sheets/
                  </a>
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>
                  Global distribution and variation of the concentration of
                  mid-tropospheric carbon dioxide in parts per million (ppm).
                  Direct measurements taken since 2005; indirect (proxy)
                  measurements (reconstructed from ice cores) from ~800K years
                  prior.
                </Text>
              }
            />
          </DataGrid>
        );
      // Global temp increase
      case "71e8dd18-719c-4f04-9af7-9677df65766d":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-rose-400">+0.85 C</Text>}
            />
            <DataItem label="Date published" value={<Text>2021</Text>} />
            <DataItem label="Since" value={<Text>1880</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ice-sheets/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ice-sheets/
                  </a>
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>Average change in global temperature since 1880.</Text>
              }
            />
          </DataGrid>
        );
      // Arctic Sea Ice Loss
      case "8d7ef0f8-4bc3-4be9-93ea-be8974ae43a3":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-rose-400">-13%</Text>}
            />
            <DataItem label="Date published" value={<Text>2022</Text>} />
            <DataItem label="Since" value={<Text>1979</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ice-sheets/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ice-sheets/
                  </a>
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>September decline, rate of change, % per decade.</Text>
              }
            />
          </DataGrid>
        );
      // Ice Sheet Loss
      case "a2ea56cb-effb-4662-afd9-c303dc75ae79":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-yellow-400">-152.0 bmt</Text>}
            />
            <DataItem label="Date" value={<Text>2022</Text>} />
            <DataItem label="Since" value={<Text>2002</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ice-sheets/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ice-sheets/
                  </a>
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>
                  Average of Antarctic and Greenland Ice Sheet losses in billion
                  metric tons (bmt) per year (275 and 152 bmt respectively).
                </Text>
              }
            />
          </DataGrid>
        );
      // Sea level rise
      case "d3bd370b-07ac-4784-8364-5c16a5666113":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-yellow-400">+100.8 mm</Text>}
            />
            <DataItem label="Date" value={<Text>Jan 2022</Text>} />
            <DataItem label="Since" value={<Text>1993</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ice-sheets/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ice-sheets/
                  </a>
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>
                  Change in sea level since 1993. (+/- 4.0 mm). Note that this
                  includes a 4 to 5 month lag needed for data processing.
                </Text>
              }
            />
          </DataGrid>
        );
      // Ocean heat content
      case "d3bd370b-07ac-4784-8364-5c16a5666114":
        return (
          <DataGrid>
            <DataItem
              label="Value"
              value={<Text textColor="text-yellow-400">337 zj</Text>}
            />
            <DataItem label="Date" value={<Text>Dec 2021</Text>} />
            <DataItem label="Since" value={<Text>1955</Text>} />
            <DataItem
              label="Source"
              value={
                <Text>
                  "Global Climate Change, Vital Signs of the Planet", NASA,
                  2022. See:{" "}
                  <a
                    href="https://climate.nasa.gov/vital-signs/ocean-heat/"
                    target="_blank"
                  >
                    https://climate.nasa.gov/vital-signs/ocean-heat/
                  </a>
                  . Data source: NOAA/NCEI World Ocean Database
                </Text>
              }
            />
            <DataItem
              label="Description"
              value={
                <Text>
                  Ocean heat content changes since 1955 in zettajoules (+/- 2).
                </Text>
              }
            />
          </DataGrid>
        );
      default:
        return (
          <DelayRender textColor="text-gray-700">
            <Text textColor="text-gray-400">No information available</Text>
          </DelayRender>
        );
    }
  };

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="flex flex-col gap-2 text-gray-700 p-5">
        <Heading>Metric information</Heading>
        {renderMockContent()}
      </div>
    </Transition>
  );
};

export const CoreDashboardMetricPoliciesPopover = ({
  coreMetricId,
  children,
}: CoreDashboardMetricPoliciesPopoverProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const openPanel = () => {
    referenceElement?.click();
  };

  return (
    <Popover className="relative" onMouseLeave={openPanel}>
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            ref={setReferenceElement}
            className="cursor-pointer"
            onMouseEnter={openPanel}
          >
            {children}
          </Popover.Button>
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-20 px-4 transform -translate-x-1/2 left-1/2 sm:px-0 max-w-[50rem] max-h-[22rem] bg-white rounded-lg overflow-y-scroll"
          >
            {open && <PopoverContent coreMetricId={coreMetricId} />}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
