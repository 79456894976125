import { createSlice } from "@reduxjs/toolkit";
import { Solution, UUID } from "types";
import { createSolution, fetchSolutions, updateSolution } from "store/reducers";

export type SolutionsReducerStateData = Record<UUID, Solution>;

export interface SolutionsReducerState {
  data: SolutionsReducerStateData;
}

const initialState: SolutionsReducerState = {
  data: {},
};

export const solutionsSlice = createSlice({
  name: "solutions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSolutions.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: SolutionsReducerStateData, value: Solution) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(createSolution.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateSolution.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      });
  },
});

export const solutionsReducer = solutionsSlice.reducer;
