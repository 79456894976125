import { Route, Routes } from "react-router-dom";
import { AuthLayout, LoginPage, LogoutPage } from "pages";
import { RoutePath } from "routes/RoutePath";

export const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={RoutePath.login} element={<LoginPage />} />
        <Route path={RoutePath.logout} element={<LogoutPage />} />
      </Route>
    </Routes>
  );
};
