import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.coreMetrics;

export const selectCoreMetrics = createSelector(
  selectState,
  (state) => state.data
);

export const selectCoreMetricsList = createSelector(selectCoreMetrics, (data) =>
  Object.values(data)
);

export const selectCoreMetricByID = (id?: UUID) =>
  createSelector(selectCoreMetrics, (data) => (id ? data[id] : undefined));
