import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.metrics;

export const selectKeyPerformanceMetrics = createSelector(
  selectState,
  (state) => state.data
);

export const selectKeyPerformanceMetricsList = createSelector(
  selectKeyPerformanceMetrics,
  (data) => Object.values(data)
);

export const selectKeyPerformanceMetricByID = (id: UUID) =>
  createSelector(selectKeyPerformanceMetrics, (data) => data[id]);
