import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { Metric } from "types";

type KeyResultSelectorItemProps = {
  keyPerformanceMetric: Metric;
};

export const KeyResultSelectorItem = ({
  keyPerformanceMetric,
}: KeyResultSelectorItemProps) => {
  return (
    <Combobox.Option
      key={keyPerformanceMetric.id}
      value={keyPerformanceMetric}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none px-4 py-2",
          active && "bg-zinc-600  text-white"
        )
      }
    >
      {keyPerformanceMetric.name}
    </Combobox.Option>
  );
};
