import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.keyResults;

export const selectKeyResults = createSelector(
  selectState,
  (state) => state.data
);

export const selectKeyResultsList = createSelector(selectKeyResults, (data) =>
  Object.values(data)
);

export const selectKeyResultByID = (id: UUID) =>
  createSelector(selectKeyResults, (data) => data[id]);
