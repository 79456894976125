import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import * as icons from "@heroicons/react/outline";
import applicationMenu from "./menu-items.json";

type AppSidebarMenuProps = {
  setSidebarOpen: (value: boolean) => void;
};

export const AppSidebarMenu = ({ setSidebarOpen }: AppSidebarMenuProps) => {
  return (
    <nav className="px-3">
      <div className="space-y-1">
        {applicationMenu.map((item) => {
          const Icon = icons[item.icon as keyof typeof icons];

          return !item.children ? (
            <NavLink
              key={item.name}
              to={item.to}
              onClick={() => setSidebarOpen(false)}
              className={({ isActive }) => {
                return classNames(
                  isActive
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                );
              }}
            >
              {({ isActive }) => (
                <>
                  <Icon
                    className={classNames(
                      isActive
                        ? "text-gray-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </>
              )}
            </NavLink>
          ) : (
            <Disclosure as="div" key={item.name} className="space-y-1">
              {({ open }) => {
                return (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        open
                          ? "bg-gray-100 text-gray-900"
                          : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-pantone-500"
                      )}
                    >
                      <Icon
                        className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="flex-1">{item.name}</span>
                      <svg
                        className={classNames(
                          open ? "text-gray-400 rotate-90" : "text-gray-300",
                          "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      {item.children.map((subItem: any) => (
                        <NavLink
                          key={subItem.name}
                          to={subItem.to}
                          onClick={() => setSidebarOpen(false)}
                          className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          {subItem.name}
                        </NavLink>
                      ))}
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          );
        })}
      </div>
    </nav>
  );
};
