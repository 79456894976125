import * as jwt from "jose";
import Cookies from "universal-cookie";
import { CookieSetOptions } from "universal-cookie/cjs/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { login } from "store/reducers/accounts/accounts.thunks";
import { AuthSession } from "types";

const hydrateSession = (): AuthSession | undefined => {
  const cookies = new Cookies();
  const token = cookies.get("session");
  if (!token) {
    return;
  }
  return jwt.decodeJwt(token) as AuthSession;
};

const sessionExpiry = (session: AuthSession): number => {
  return session.exp * 1000;
};

const getCookieOptions = (session: AuthSession): CookieSetOptions => {
  return {
    expires: new Date(sessionExpiry(session)),
  };
};

export interface AccountsReducerState {
  session?: AuthSession;
}

const initialState: AccountsReducerState = {
  session: hydrateSession(),
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    logout: (state, _: PayloadAction) => {
      if (state.session) {
        const cookies = new Cookies();
        cookies.remove("session", getCookieOptions(state.session));
        state.session = undefined;
      }
      window.location.href = "/login";
    },
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, action) => {
      const { token } = action.payload;
      const cookies = new Cookies();
      const session: AuthSession = jwt.decodeJwt(token) as AuthSession;
      const options = getCookieOptions(session);
      cookies.set("session", token, options);
      state.session = session;
    });
  },
});

export const { logout } = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
