import { createSlice } from "@reduxjs/toolkit";
import { TFile, UUID } from "types";
import { createFile, fetchFile, fetchFiles } from "store/reducers";

export type FilesReducerStateData = Record<UUID, TFile>;

export interface FilesReducerState {
  data: FilesReducerStateData;
}

const initialState: FilesReducerState = {
  data: {},
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: FilesReducerStateData, value: TFile) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(fetchFile.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createFile.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      });
  },
});

export const filesReducer = filesSlice.reducer;
