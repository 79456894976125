import classNames from "classnames";
import {
  CogIcon,
  ExternalLinkIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { App } from "types/app";

type AppIconProps = {
  app: App;
  disabled?: boolean;
  onClick: (app: App) => void;
};

export const AppIcon = ({ app, disabled = false, onClick }: AppIconProps) => {
  return (
    <li
      key={app.name}
      className={classNames(
        "col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 overflow-hidden",
        disabled && "opacity-50 pointer-events-none"
      )}
    >
      <div className="relative flex-1 flex flex-col p-8">
        {app.imageUrl && (
          <img
            className="absolute top-0 left-0 w-full h-full flex-shrink-0 mx-auto contrast-[.2]"
            src={app.imageUrl}
            alt=""
          />
        )}
        <div
          className={classNames(
            "relative w-16 h-16 flex items-center justify-center font-bold text-lg flex-shrink-0 mx-auto rounded-full z-10",
            app.backgroundColor
          )}
        >
          {app.name.toUpperCase().slice(0, 2)}
        </div>
        <h3 className="mt-6 text-gray-900 text-sm text-center font-medium z-10 text-white">
          {app.name}
        </h3>
        <dl className="flex-grow flex flex-col text-center justify-between  z-10">
          <dt className="sr-only">Status</dt>
          <dd className="mt-3">
            <span
              className={classNames(
                "px-2 py-1 text-xs font-medium rounded-full",
                app.configured
                  ? app.native
                    ? "text-sky-800 bg-sky-100"
                    : "text-green-800 bg-green-100"
                  : "text-red-800 bg-red-100"
              )}
            >
              {app.configured
                ? app.native
                  ? "Native"
                  : "Active"
                : "Not configured"}
            </span>
          </dd>
        </dl>
      </div>
      {app.configured ? (
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <div
                onClick={disabled ? undefined : () => onClick(app)}
                className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                {typeof app.openIcon === "undefined" ? (
                  <ExternalLinkIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  app.openIcon
                )}
                <span className="ml-3">Open</span>
              </div>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <div className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <CogIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span className="ml-3">Settings</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <div className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                <PencilIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">Configure</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};
