import { App } from "types/app";
import { parse, stringify } from "query-string";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "hooks/useModal";
import { ConfirmModal } from "components/modals";
import { AppIcon } from "components/shared/features/apps/AppIcon/AppIcon";
import app1 from "assets/images/apps/app_1.jpeg";
import app2 from "assets/images/apps/app_2.jpeg";
import app3 from "assets/images/apps/app_3.png";
import app4 from "assets/images/apps/app_4.jpeg";
import app5 from "assets/images/apps/app_5.jpeg";
import app6 from "assets/images/apps/app_6.jpeg";
import app7 from "assets/images/apps/app_7.jpeg";
import app8 from "assets/images/apps/app_8.jpeg";
import app9 from "assets/images/apps/app_9.jpeg";
import app10 from "assets/images/apps/app_10.jpeg";
import app11 from "assets/images/apps/app_11.jpeg";
import app12 from "assets/images/apps/app_12.png";
import app13 from "assets/images/apps/app_13.jpeg";
import app14 from "assets/images/apps/app_14.jpeg";
import app15 from "assets/images/apps/app_15.jpeg";

const mockApps: App[] = [
  {
    name: "Solution explorer",
    configured: true,
    native: true,
    backgroundColor: "bg-rose-300",
    openIcon: null,
    imageUrl: app6,
  },
  {
    name: "Climate PI Buildings",
    configured: true,
    backgroundColor: "bg-gold-600",
    imageUrl: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=640",
    appUrl: "https://buildings.climatepi.earth"
  },
  {
    name: "Climate PI Green IT",
    configured: true,
    backgroundColor: "bg-gold-600",
    imageUrl: "https://images.unsplash.com/photo-1544197150-b99a580bb7a8?q=80&w=640",
    appUrl: "https://greenit.climatepi.earth"
  },
  {
    name: "Climate PI Mining",
    configured: true,
    backgroundColor: "bg-gold-600",
    imageUrl: "https://images.unsplash.com/photo-1523848309072-c199db53f137?q=80&w=640",
    appUrl: "https://mining.climatepi.earth"
  },
  {
    name: "Climate PI Cities",
    configured: true,
    backgroundColor: "bg-gold-600",
    imageUrl: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?q=80&w=640",
    appUrl: "https://cities.climatepi.earth"
  },
  {
    name: "Riversmarts",
    configured: true,
    backgroundColor: "bg-orange-300",
    imageUrl: app14,
  },
  {
    name: "Landsmarts",
    configured: true,
    backgroundColor: "bg-amber-300",
    imageUrl: app13,
  },
  {
    name: "Drawdown",
    configured: true,
    backgroundColor: "bg-pink-400",
    imageUrl: app3,
  },
  {
    name: "Study Climate",
    configured: true,
    backgroundColor: "bg-lime-400",
    imageUrl: app2,
  },
  {
    name: "SmartGrid",
    configured: false,
    backgroundColor: "bg-yellow-400",
    imageUrl: app12,
  },
  {
    name: "CO2 Calculator",
    configured: false,
    backgroundColor: "bg-lime-500",
    imageUrl: app10,
  },
  {
    name: "iCities",
    configured: false,
    backgroundColor: "bg-green-400",
    imageUrl: app11,
  },
  {
    name: "Agriculture monitor",
    configured: false,
    backgroundColor: "bg-violet-400",
    imageUrl: app9,
  },
  {
    name: "Air Quality Monitor",
    configured: false,
    backgroundColor: "bg-pink-400",
    imageUrl: app8,
  },
  {
    name: "Energy Monitor",
    configured: false,
    backgroundColor: "bg-rose-400",
    imageUrl: app6,
  },
  {
    name: "IoT City",
    configured: false,
    backgroundColor: "bg-amber-300",
    imageUrl: app7,
  },
  {
    name: "Regulatory (RegCheck)",
    configured: false,
    backgroundColor: "bg-lime-300",
    imageUrl: app5,
  },
  {
    name: "Biodiversity Monitoring",
    configured: false,
    backgroundColor: "bg-violet-400",
    imageUrl: app4,
  },
];

type MockAppsStoreProps = {
  apps?: App[];
};

export const MockAppsStore = ({ apps = mockApps }: MockAppsStoreProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const [onlyActive, setOnlyActive] = useState(false);

  const activateSolution = (app: App) => {
    if (!app.configured) {
      return;
    }
    const { action, ...otherParams } = parse(search);
    const queryParams = {
      action: "create_policy",
      ...otherParams,
    };

    if (app.native) {
      navigate(`/apps/native/summary?${stringify(queryParams)}`, {});
      return;
    }

    openModal(
      <ConfirmModal
        title="Your being navigated outside PI"
        description={
        <span>
          Are you sure you would like to open this project in the <strong>{app.name}</strong> app?
        </span>
        }
        actionText="Open app"
        icon={ExternalLinkIcon}
        onConfirm={() => {
          const baseUrl = app.appUrl || "https://cities.climatepi.earth";
          const url = `${baseUrl}/dashboard?${stringify(queryParams)}`;
          window.open(url, "_blank");
        }}
      />
    );
  };

  useEffect(() => {
    const { action } = parse(search);
    if (action === "select_app") {
      setOnlyActive(true);
    }
  }, [search]);

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      {apps.map((app: App) => (
        <AppIcon
          app={app}
          disabled={onlyActive && !app.configured}
          onClick={activateSolution}
        />
      ))}
    </ul>
  );
};
