import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.files;

export const selectFiles = createSelector(selectState, (state) => state.data);

export const selectFilesList = createSelector(selectFiles, (data) =>
  Object.values(data)
);

export const selectFileByID = (id?: UUID) =>
  createSelector(selectFiles, (data) => (id ? data[id] : undefined));
