import {GenericInputProps, Input} from "./Input";

type PasswordInputProps = GenericInputProps & {};

export const PasswordInput = (props: PasswordInputProps) => {
    return (
        <Input {...props} type="password" autoComplete={props.autoComplete || "current-password"} />
    );
};



