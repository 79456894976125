import { DecisionModel } from "features/decisions/types/decisionModel";
import { baseApi } from "store/base.api";
import { UUID } from "types";

export type CreateDecisionRequest = {
  useCaseId: UUID;
  useCaseDomain: string;
  useCase: string;
  objective: string;
};

export type CreateDecisionResponse = {
  data: DecisionModel;
};

export const decisionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createDecision: builder.mutation<
      CreateDecisionResponse["data"],
      CreateDecisionRequest
    >({
      query: (body) => ({
        url: "decisions",
        method: "POST",
        body,
      }),
      transformResponse: (response: CreateDecisionResponse) => response.data,
    }),
  }),
});

export const { useCreateDecisionMutation } = decisionsApi;
