import { useDispatch, useSelector } from "store";
import { fetchSolutions, selectSolutionsList } from "store/reducers";
import { InitiativeTableRow } from "components/shared";
import { useEffect } from "react";

type InitiativeTableProps = {};

export const InitiativeTable = (props: InitiativeTableProps) => {
  const dispatch = useDispatch();
  const solutionsList = useSelector(selectSolutionsList);

  useEffect(() => {
    dispatch(fetchSolutions());
  }, []);

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Efficacy
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            URL
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Category
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Provider
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {solutionsList.map((solution) => (
          <InitiativeTableRow key={solution.id} solutionId={solution.id} />
        ))}
      </tbody>
    </table>
  );
};
