import { useSelector } from "store";
import { selectCoreMetricsList } from "store/reducers";
import { CoreMetricsTableRow } from "components/shared";

type KeyPerformanceMetricsTableProps = {};

export const CoreMetricsTable = (props: KeyPerformanceMetricsTableProps) => {
  const coreMetricsList = useSelector(selectCoreMetricsList);

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {coreMetricsList.map((coreMetric) => (
          <CoreMetricsTableRow
            key={coreMetric.id}
            coreMetricId={coreMetric.id}
          />
        ))}
      </tbody>
    </table>
  );
};
