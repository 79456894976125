import { SecondaryButton } from "components/shared/buttons";
import { PlusIcon } from "@heroicons/react/solid";

type SolutionSelectorButtonProps = {
  label?: string;
  open: boolean;
};

export const SolutionSelectorButton = ({
  label = "Add another",
  open,
}: SolutionSelectorButtonProps) => {
  return (
    <SecondaryButton
      width=""
      leadingIcon={PlusIcon}
      background={open ? "bg-gray-200" : "bg-gray-100"}
    >
      {label}
    </SecondaryButton>
  );
};
