import { ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindFontWeight,
  TailwindLineHeight,
  TailwindTextColor,
  TailwindTextSize,
} from "types/tailwind";

type HeaderProps = {
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textSize?: TailwindTextSize;
  lineHeight?: TailwindLineHeight;
  children?: ReactNode;
};

export const Header = ({
  textColor = "text-current",
  textSize = "text-lg",
  fontWeight = "font-medium",
  lineHeight = "leading-6",
  children,
}: HeaderProps) => {
  const classes = classNames(textColor, textSize, fontWeight, lineHeight);
  return <h2 className={classes}>{children}</h2>;
};
