import styled from "styled-components";

export const BlockControl = styled.div`
  color: #a6a6a6;
  cursor: pointer;
  margin-right: 0.5rem;
  position: relative;
  width: 1rem;
  height: 1rem;

  &:hover:after {
    display: block;
    content: "";
    height: 1.75rem;
    width: 1.75rem;
    background-color: grey;
    opacity: 0.13;
    border-radius: 3px;
    position: absolute;
    top: -0.375rem;
    left: -0.375rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;
