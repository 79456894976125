import { Text, Title } from "components/shared/typography";
import { FlexContainer } from "components/shared/layouts";
import { PrimaryButton } from "components/shared/buttons";
import { PolicyNewsFeed } from "components/shared/features/policies/PolicyNewsFeed/PolicyNewsFeed";

type PoliciesRatingSummaryProps = {
  onEnterPI: () => void;
};

export const PoliciesRatingSummary = ({
  onEnterPI,
}: PoliciesRatingSummaryProps) => {
  return (
    <div className="flex bg-ocean-900/80 flex-col text-center z-10 relative left-24 rounded-lg p-5 w-96 items-center pointer-events-none">
      <Title textColor="text-white" fontWeight="font-thin">
        The Planet at a Glance
      </Title>
      <div className="mt-2">
        <p className="text-white/80 mt-1 text-[12px]">
          The planet’s land and ocean’s sinks can only remove 41% of humanity’s
          carbon footprint. We need to take action on the remaining 59%.
        </p>
      </div>
      <div className="flex flex-row gap-5 mt-5">
        <FlexContainer
          direction="flex-col"
          justify="justify-center"
          alignItems="items-center"
        >
          <Text
            textSize="text-2xl"
            fontWeight="font-bold"
            textColor="text-white"
          >
            420 Gt
          </Text>
          <p className="text-white/80 text-[12px]">Carbon Budget (CO2)</p>
        </FlexContainer>
        <FlexContainer
          direction="flex-col"
          justify="justify-center"
          alignItems="items-center"
        >
          <Text
            textSize="text-2xl"
            fontWeight="font-bold"
            textColor="text-white"
          >
            2021 – 29th July
          </Text>
          <p className="text-white/80 text-[12px]">Earth Overshoot Day</p>
        </FlexContainer>
      </div>
      {/*<div className="mt-5 flex flex-row gap-2 pointer-events-auto">*/}
      {/*  <PrimaryButton onClick={onEnterPI}>View planet</PrimaryButton>*/}
      {/*</div>*/}
      <div className="mt-5 self-start flex flex-row gap-2 pointer-events-auto text-left">
        <PolicyNewsFeed policyId="123" />
      </div>
    </div>
  );
};
