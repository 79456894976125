import { ObjectivesTable } from "components/shared";

export const ObjectivesSettingsPage = () => {
  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
        Objectives
      </h1>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <ObjectivesTable />
      </div>
    </div>
  );
};
