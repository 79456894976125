import React from "react";
import { useSelector } from "react-redux";
import SuccessIcon from "@atlaskit/icon/glyph/check-circle";
import InfoIcon from "@atlaskit/icon/glyph/info";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import { G400, N500, R400, Y200 } from "@atlaskit/theme/colors";
import { AutoDismissFlag, FlagGroup } from "@atlaskit/flag";
import { useDispatch } from "store";
import { closePopupFlag, selectPopupFlags } from "store/reducers";
import { PopupFlag } from "types";

export const AutoDismissFlags = () => {
  const dispatch = useDispatch();
  const flags = useSelector(selectPopupFlags);

  const handleDismiss = (id: string | number) => {
    if (typeof id === "string") {
      dispatch(closePopupFlag(id));
    }
  };

  const renderFlag = (flag: PopupFlag) => {
    const appearance = flag.appearance || "info";
    let icon;
    switch (appearance) {
      case "success":
        icon = <SuccessIcon label="Success" secondaryColor={G400} />;
        break;
      case "warning":
        icon = <WarningIcon label="Warning" secondaryColor={Y200} />;
        break;
      case "error":
        icon = <ErrorIcon label="Error" secondaryColor={R400} />;
        break;
      default:
        icon = <InfoIcon label="Info" secondaryColor={N500} />;
    }
    return (
      <AutoDismissFlag
        key={flag.id}
        appearance={appearance}
        icon={icon}
        id={flag.id}
        title={flag.title}
        description={flag.description}
      />
    );
  };

  return (
    <FlagGroup onDismissed={handleDismiss}>{flags.map(renderFlag)}</FlagGroup>
  );
};
