import classNames from "classnames";
import { SimpleGlobe } from "components/shared/charts/globes/SimpleGlobe/SimpleGlobe";

export const Planet = () => {
  return (
    <div
      className={classNames(
        "transition-opacity duration-300 ease-in-out delay-[2000ms] opacity-100"
      )}
    >
      <div
        className={classNames(
          "fixed top-0 w-screen h-screen transition-all ease-in-out duration-300 delay-300 -right-1/3"
        )}
      >
        <div className="flex h-full items-center">
          <SimpleGlobe />
        </div>
      </div>
    </div>
  );
};
