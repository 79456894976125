import { createSlice } from "@reduxjs/toolkit";
import { CoreMetric, UUID } from "types";
import {
  createCoreMetric,
  fetchCoreMetrics,
  updateCoreMetric,
} from "store/reducers";

export type CoreMetricsReducerStateData = Record<UUID, CoreMetric>;

export interface CoreMetricsReducerState {
  data: CoreMetricsReducerStateData;
}

const initialState: CoreMetricsReducerState = {
  data: {},
};

export const coreMetricsSlice = createSlice({
  name: "coreMetrics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCoreMetrics.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: CoreMetricsReducerStateData, value: CoreMetric) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(createCoreMetric.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateCoreMetric.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      });
  },
});

export const coreMetricsReducer = coreMetricsSlice.reducer;
