import React from "react";
import styled from "styled-components";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import { wrapEmojis } from "components/shared/cms/Sheet/utils";
import { TBlockType } from "types";
import classNames from "classnames";

const StyledContentEditable = styled(ContentEditable)`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;

  [contenteditable]:empty:before {
    content: attr(placeholder);
    display: block;
  }
`;

export type EditableTextProps = {
  focused: boolean;
  disabled: boolean;
  value: string;
  tag?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  onAddBlock?: (type: TBlockType) => void;
  onRemoveBlock?: () => void;
  className?: string;
};

type EditableTextState = {
  previousKey: string;
};

export class EditableText extends React.Component<
  EditableTextProps,
  EditableTextState
> {
  static defaultProps = {
    tag: "div",
    placeholder: "Type something",
  };

  element: any;

  constructor(props: EditableTextProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.element = React.createRef();
    this.state = {
      previousKey: "",
    };
  }

  componentDidUpdate(prevProps: EditableTextProps) {
    if (!prevProps.focused && this.props.focused) {
      this.element.current?.focus();
    }
  }

  handleChange = (event: ContentEditableEvent) => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur(this.element.current.textContent);
  };

  isEmpty = () => {
    return this.element.current.textContent === "";
  };

  handleKeyDown(e: any) {
    if (e.key === "Backspace" && this.isEmpty()) {
      this.props.onRemoveBlock && this.props.onRemoveBlock();
    } else if (e.key === "Enter" && this.state.previousKey !== "Shift") {
      // If the user presses Enter, we want to add a new block
      // Only the Shift-Enter-combination should add a new paragraph,
      // i.e. Shift-Enter acts as the default enter behaviour
      e.preventDefault();
      this.props.onAddBlock && this.props.onAddBlock("text");
    }
    // We need the previousKey to detect a Shift-Enter-combination
    this.setState({ previousKey: e.key });
  }

  render = () => {
    const classes = ["leading-normal"];
    if (this.props.tag === "h1") {
      classes.push("font-extrabold", "text-3xl", "tracking-tight");
    }
    if (this.props.tag === "h2") {
      classes.push("font-bold", "text-lg", "leading-6");
    }
    if (this.props.tag === "h3") {
      classes.push("font-bold");
    }
    return (
      <StyledContentEditable
        innerRef={this.element}
        disabled={this.props.disabled}
        className={classNames(classes, this.props.className)}
        html={wrapEmojis(this.props.value)}
        tagName={this.props.tag}
        onChange={this.handleChange}
        onFocus={this.props.onFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
      />
    );
  };
}
