import { Routes, Route } from "react-router-dom";
import { RoutePath } from "routes/RoutePath";
import { CoreMetricsSettingsPage } from "pages/features/settings/CoreMetricsSettingsPage/CoreMetricsSettingsPage";
import { MetricsSettingsPage } from "pages/features/settings/MetricsSettingsPage/MetricsSettingsPage";
import { MetricSettingsPage } from "pages/features/settings/MetricSettingsPage/MetricSettingsPage";
import { ObjectivesSettingsPage } from "pages/features/settings/ObjectivesSettingsPage/ObjectivesSettingsPage";
import { SolutionsSettingsPage } from "pages/features/settings/SolutionsSettingsPage/SolutionsSettingsPage";
import { AppSettingsLayout } from "pages/layouts/AppSettingsLayout/AppSettingsLayout";
import { CoreMetricSettingsPage } from "pages/features/settings/CoreMetricSettingsPage/CoreMetricSettingsPage";
import { ObjectiveSettingsPage } from "pages/features/settings/ObjectiveSettingsPage/ObjectiveSettingsPage";
import { SolutionSettingsPage } from "pages/features/settings/SolutionSettingsPage/SolutionSettingsPage";

export const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<AppSettingsLayout />}>
        <Route
          path={RoutePath.dashboard.coreMetrics}
          element={<CoreMetricsSettingsPage />}
        />
        <Route path="core-metrics/:id" element={<CoreMetricSettingsPage />} />
        <Route
          path={RoutePath.dashboard.kpis}
          element={<MetricsSettingsPage />}
        />
        <Route path="kpis/:id" element={<MetricSettingsPage />} />
        <Route
          path={RoutePath.dashboard.objectives}
          element={<ObjectivesSettingsPage />}
        />
        <Route path="objectives/:id" element={<ObjectiveSettingsPage />} />
        <Route
          path={RoutePath.dashboard.interventions}
          element={<SolutionsSettingsPage />}
        />
        <Route path="interventions/:id" element={<SolutionSettingsPage />} />
        <Route path="*" element={<CoreMetricsSettingsPage />} />
      </Route>
    </Routes>
  );
};
