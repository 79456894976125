import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateSolutionRequest,
  DataResponse,
  GetSolutionsResponse,
  Solution,
  UpdateSolutionRequest,
} from "types";
import { API } from "lib/API";

export const fetchSolutions = createAsyncThunk(
  "solutions/list",
  async (): Promise<Solution[]> => {
    const { data } = await API.GET<GetSolutionsResponse>("/solutions", {
      pageLimit: 200,
    });
    return data;
  }
);

export const createSolution = createAsyncThunk(
  "solutions/create",
  async (request: CreateSolutionRequest): Promise<Solution> => {
    const { data } = await API.POST<
      CreateSolutionRequest,
      DataResponse<Solution>
    >("/solutions", request);
    return data;
  }
);

export const updateSolution = createAsyncThunk(
  "solutions/update",
  async (request: UpdateSolutionRequest): Promise<Solution> => {
    const { data } = await API.PUT<
      UpdateSolutionRequest,
      DataResponse<Solution>
    >(`/solutions/${request.id}`, request);
    return data;
  }
);
