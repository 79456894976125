import { useState } from "react";
import getYouTubeID from "get-youtube-id";
import YouTube, { Options } from "react-youtube";
import styled from "styled-components";
import { FilmIcon } from "@heroicons/react/outline";
import { TBlock, TYoutubeBlock } from "types";
import { useSheet } from "hooks/useSheet";
import { Text } from "components/shared/typography";
import { YoutubeBlockMenu } from "components/shared/cms/YoutubeBlockMenu/YoutubeBlockMenu";

const StyledYouTube = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 0.25rem;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(241, 241, 239);
  color: rgb(156, 163, 175);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgb(229, 229, 229);
  }
`;

const PlaceholderIcon = styled(FilmIcon)`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

type YoutubeBlockFrameProps = {
  block: TBlock;
};

export const YoutubeBlockFrame = ({ block }: YoutubeBlockFrameProps) => {
  const { openSheetMenu } = useSheet();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const properties = block.properties as TYoutubeBlock;
  const videoID = getYouTubeID(properties.url);
  const options: Options = {
    playerVars: {
      autoplay: 0,
    },
  };

  const openMenu = () => {
    openSheetMenu(<YoutubeBlockMenu refElement={ref} blockId={block.id} />);
  };

  if (!videoID) {
    return (
      <Placeholder ref={setRef} onClick={openMenu}>
        <PlaceholderIcon />
        <Text
          textColor="text-gray-400"
          lineHeight="leading-8"
          textSize="text-sm"
        >
          Embed a Youtube video
        </Text>
      </Placeholder>
    );
  }

  return (
    <StyledYouTube>
      <YouTube videoId={videoID} opts={options} />
    </StyledYouTube>
  );
};
