import {GenericInputProps, Input} from "./Input";

type EmailInputProps = GenericInputProps & {};

export const EmailInput = (props: EmailInputProps) => {
    return (
        <Input {...props} type="email" autoComplete={props.autoComplete || "email"} />
    );
};



