import { useDecisionChat } from "features/decisions/hooks";
import {
  ChatBubble,
  ChatMessage,
  ChatMessageAuthor,
  ChatMessageType,
  TextChatMessage,
} from "features/conversations";
import { ReactNode } from "react";
import { UseCasePickerChatMessage } from "features/decisions/components/UseCasePickerChatMessage";
import { ObjectivePickerChatMessage } from "features/decisions/components/ObjectivePickerChatMessage";
import { Text } from "components/shared";
import { DecisionModelReviewChatMessage } from "features/decisions/components/DecisionModelReviewChatMessage";

export type DecisionChatProps = {};

export const DecisionChat = (props: DecisionChatProps) => {
  const { typing, messages } = useDecisionChat();

  const renderChatMessage = (message: ChatMessage): ReactNode | null => {
    switch (message.type) {
      case ChatMessageType.Text:
        return <TextChatMessage message={message} />;
      case ChatMessageType.UseCasePicker:
        return <UseCasePickerChatMessage message={message} />;
      case ChatMessageType.ObjectivePicker:
        return <ObjectivePickerChatMessage message={message} />;
      case ChatMessageType.ReviewModel:
        return <DecisionModelReviewChatMessage message={message} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-4">
      {messages.map((message: ChatMessage) => (
        <ChatBubble key={message.id} author={message.author}>
          {renderChatMessage(message)}
        </ChatBubble>
      ))}
      {typing && (
        <ChatBubble author={ChatMessageAuthor.Bot}>
          <Text>PI Bot is typing...</Text>
        </ChatBubble>
      )}
    </div>
  );
};
