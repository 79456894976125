import { UUID } from "types";
import { useSelector } from "store";
import {
  selectCoreMetricByID,
  selectKeyPerformanceMetricByID,
} from "store/reducers";
import { Text } from "components/shared";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { RoutePath } from "routes/RoutePath";

type KeyPerformanceMetricsTableRowProps = {
  keyPerformanceMetricId: UUID;
};

export const KeyPerformanceMetricsTableRow = ({
  keyPerformanceMetricId,
}: KeyPerformanceMetricsTableRowProps) => {
  const navigate = useNavigate();
  const entity = useSelector(
    selectKeyPerformanceMetricByID(keyPerformanceMetricId)
  );
  const coreMetric = useSelector(selectCoreMetricByID(entity?.coreMetricId));

  if (!entity) {
    return null;
  }

  return (
    <tr
      className="cursor-pointer hover:bg-blue-50 hover:bg-opacity-50"
      onClick={() =>
        navigate(generatePath(RoutePath.dashboard.kpi, { id: entity.id }))
      }
    >
      <td className="px-6 py-4 text-sm text-gray-900">
        <div className="text-sm mb-0.5 font-medium text-gray-900">
          {entity.name}
        </div>
        <div className="text-sm">
          <Text textColor="text-gray-400">
            {entity.description || "No description"}
          </Text>
        </div>
      </td>
      <td className="px-6 py-4 text-sm text-gray-600">{entity.value}</td>
      <td className="px-6 py-4 text-sm text-gray-900">
        {coreMetric ? (
          <Link
            to={generatePath(RoutePath.dashboard.coreMetric, { id: entity.id })}
            className="text-pantone-700"
          >
            {coreMetric.name}
          </Link>
        ) : (
          <Text textColor="text-gray-400">None</Text>
        )}
      </td>
    </tr>
  );
};
