import React, { SyntheticEvent } from "react";
import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { Solution } from "types";
import { BluePill, GrayPill, GreenPill } from "components/shared/pills";
import { useModal } from "hooks/useModal";
import { SolutionModal } from "components/shared/features/initiatives/SolutionModal/SolutionModal";

type SolutionSelectorItemProps = {
  solution: Solution;
  onClick: () => void;
};

export const SolutionSelectorItem = ({
  solution,
  onClick,
}: SolutionSelectorItemProps) => {
  const { openModal } = useModal();

  const renderEffectiveness = () => {
    switch (solution.capexImpact) {
      case "low":
        return <GrayPill label="Somewhat effective" />;
      case "medium":
        return <BluePill label="Effective" />;
      case "high":
        return <GreenPill label="Very effective" />;
      default:
        return null;
    }
  };

  const renderCost = () => {
    switch (solution.capexImpact) {
      case "low":
        return <GreenPill label="Low cost" />;
      case "medium":
        return <BluePill label="Medium cost" />;
      case "high":
        return <GrayPill label="Expensive" />;
      default:
        return null;
    }
  };

  const openSolutionModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    openModal(
      <SolutionModal
        solutionId={solution.id}
        onSuccess={() => {
          onClick();
        }}
      />
    );
  };

  return (
    <Combobox.Option
      key={solution.id}
      value={solution}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none py-2",
          active && "bg-zinc-600  text-white"
        )
      }
    >
      <div onClick={openSolutionModal}>
        <div className="px-4">{solution.name}</div>
        <div className="flex gap-2 mt-1 px-4">
          {renderEffectiveness()}
          {renderCost()}
        </div>
      </div>
    </Combobox.Option>
  );
};
