import { useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectSolutionByID } from "store/reducers";
import { UUID } from "types";
import { LoadingIcon, SolutionForm } from "components/shared";

export const SolutionSettingsPage = () => {
  let { id } = useParams();
  const entity = useSelector(selectSolutionByID(id as UUID));

  if (!entity) {
    return <LoadingIcon />;
  }

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <SolutionForm entity={entity} />
    </div>
  );
};
