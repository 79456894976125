import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import {
  DashboardLayout,
  HomePage,
  NotFoundPage,
  PolicyLayout,
  PolicyPage,
  SidebarLayout,
  CreateProjectPage,
  SolutionSettingsPage,
  AppsPage,
  SettingsPage,
  AppPage,
} from "pages";
import { RoutePath } from "routes/RoutePath";
import { useDispatch, useSelector } from "store";
import { fetchCoreMetrics, selectAuthSession } from "store/reducers";
import { CreateDecisionPage } from "features/decisions";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectAuthSession);

  useEffect(() => {
    if (!session) return;
    dispatch(fetchCoreMetrics());
  }, [dispatch, session]);

  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route element={<DashboardLayout />}>
          <Route path={`${RoutePath.dashboard.root}*`} element={<HomePage />} />
        </Route>
        <Route element={<PolicyLayout />}>
          <Route path={RoutePath.policies.policy} element={<PolicyPage />} />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route
            path={`${RoutePath.settings.root}*`}
            element={<SettingsPage />}
          />
        </Route>

        <Route element={<PolicyLayout />}>
          <Route
            path={`${RoutePath.projects.create}/:step`}
            element={<CreateProjectPage />}
          />
          <Route
            path={RoutePath.projects.create}
            element={<Navigate to={`${RoutePath.projects.create}/location`} />}
          />
        </Route>

        <Route path="decisions">
          <Route path="create" element={<CreateDecisionPage />} />
        </Route>

        <Route element={<PolicyLayout />}>
          <Route path={RoutePath.apps.root} element={<AppsPage />} />
          <Route path={RoutePath.apps.app} element={<AppPage />} />
        </Route>

        <Route
          path={RoutePath.solutions.solution}
          element={
            <div className="flex justify-center">
              <div className="bg-white max-w-3xl w-full rounded-lg p-5 m-5">
                <SolutionSettingsPage />
              </div>
            </div>
          }
        />
        <Route
          path="/"
          element={
            <Navigate
              to={session ? RoutePath.dashboard.root : RoutePath.login}
            />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
