import Cookies from "universal-cookie";
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { logout } from "../reducers";
import { isRequestErrorResponse } from "./is-request-response-error.guard";

const prepareHeaders: FetchBaseQueryArgs["prepareHeaders"] = (headers) => {
  const cookies = new Cookies();
  const authToken = cookies.get("session");
  if (authToken) {
    headers.set("Authorization", authToken);
  }
  return headers;
};

export const createBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = process.env["REACT_APP_BACKEND_API_URL"];
  const fetch = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  });
  const result = await fetch(args, api, extraOptions);
  if (isRequestErrorResponse(result.error) && result.error.status === 403) {
    api.dispatch(logout());
  }
  return result;
};
