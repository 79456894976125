import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text } from "components/shared/typography";
import { Group } from "components/shared/layouts/Group/Group";
import style from "./dataGrid.module.scss";

export const DataGrid = ({ className, children }: any) => (
  <dl className={classNames(style.dataGrid, className)}>{children}</dl>
);

DataGrid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export const DataItem = ({
  label,
  description,
  value,
  children,
  labelClassName,
  valueClassName,
}: any) => (
  <React.Fragment>
    <dt className={classNames(style.dataLabel, labelClassName)}>
      {typeof label !== "string" ? (
        label
      ) : (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      {typeof description !== "string" ? (
        description
      ) : (
        <Text>{description}</Text>
      )}
    </dt>
    <dd className={classNames(style.dataValue, valueClassName)}>
      {value || children}
    </dd>
  </React.Fragment>
);

DataItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
};

export const DataItemGroup = ({ ...otherProps }) => {
  return <Group {...otherProps} />;
};
