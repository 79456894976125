import { useEffect, useState } from "react";
import { GreenPill } from "components/shared/pills";
import { ProblemSolution } from "types/problem";
import { LoadingIcon } from "components/shared/icons";

type MockSolutionsTableProps = {
  value?: ProblemSolution[];
  onChange?: (solution: ProblemSolution[]) => void;
};

export const MockSolutionsTable = ({
  value = [],
  onChange,
}: MockSolutionsTableProps) => {
  const [solutions, setSolutions] = useState<Array<ProblemSolution>>();
  const map = value.reduce(
    (values, entity) => ({ ...values, [entity.id]: entity }),
    {}
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(
      "https://gist.githubusercontent.com/vincentracine/c00c7fbc6bb97a87d8cb4ee9e5b5b687/raw/pi-solutions.json"
    );
    const data = await response.json();
    setSolutions(data);
  };

  if (!solutions) {
    return <LoadingIcon />;
  }

  const toggleSolution = (solution: ProblemSolution) => {
    if (!onChange) {
      return;
    }
    const selected = solution.id in map;
    if (selected) {
      const newMap: any = {
        ...map,
      };
      delete newMap[solution.id];
      onChange(Object.values(newMap));
    } else {
      onChange(
        Object.values({
          ...map,
          [solution.id]: solution,
        })
      );
    }
  };

  return (
    <table className="text-left text-sm">
      <thead>
        <tr>
          <th className="pl-6 py-3 text-xs font-medium text-white uppercase tracking-wider" />
          <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
            Solution
          </th>
          <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
            Cost
          </th>
          <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
            Timescale
          </th>
          <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
            Details
          </th>
          <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
            Deploys
          </th>
        </tr>
      </thead>
      <tbody>
        {solutions.map((solution) => {
          const { name, capexImpact, timescale, deploys, solutionUrl } =
            solution;
          const isSelected = solution.id in map;
          return (
            <tr>
              <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                {isSelected && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-pantone-600" />
                )}
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-pantone-600 focus:ring-pantone-500 sm:left-6"
                  value={solution.id}
                  checked={isSelected}
                  onChange={() => toggleSolution(solution)}
                />
              </td>

              <td
                className="pl-1 py-1 cursor-pointer whitespace-nowrap hover:text-pantone-600"
                onClick={() => toggleSolution(solution)}
              >
                {name}
              </td>
              <td className="px-2 py-1">
                {capexImpact === "low" ? (
                  <GreenPill label="$" />
                ) : capexImpact === "medium" ? (
                  <GreenPill label="$$" />
                ) : (
                  <GreenPill label="$$$" />
                )}
              </td>
              <td className="px-2 py-1">{timescale} months</td>
              <td className="px-2 py-1">
                <a
                  href={solutionUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer whitespace-nowrap"
                >
                  More info
                </a>
              </td>
              <td className="px-2 py-1">{deploys}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
