import { useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectObjectiveByID } from "store/reducers";
import { UUID } from "types";
import { LoadingIcon, ObjectiveForm } from "components/shared";

export const ObjectiveSettingsPage = () => {
  let { id } = useParams();
  const entity = useSelector(selectObjectiveByID(id as UUID));

  if (!entity) {
    return <LoadingIcon />;
  }

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
        {entity.name}
      </h1>
      <ObjectiveForm entity={entity} />
    </div>
  );
};
