import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.cms;

export const selectBlocks = createSelector(
  selectState,
  (state) => state.blocks
);

export const selectFocusedBlockID = createSelector(
  selectState,
  (state) => state.focusedBlockID
);

export const selectBlocksList = createSelector(selectBlocks, (data) =>
  Object.values(data)
);

export const selectBlocksBySheetID = (id?: UUID) =>
  createSelector(selectBlocksList, (blocks) =>
    id ? blocks.filter(({ sheetId }) => sheetId === id) : []
  );

export const selectBlockByID = (id?: UUID) =>
  createSelector(selectBlocks, (data) => (id ? data[id] : undefined));
