import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { useSelector } from "store";
import { selectKeyPerformanceMetricByID } from "store/reducers";
import { KeyResult } from "types";
import {
  Container,
  FlexContainer,
  KeyResultDeltaInput,
  ListItem,
  Text,
} from "components/shared";

type KeyResultListItemProps = {
  keyResult: KeyResult;
  disabled?: boolean;
  onChange?: (value: KeyResult) => void;
  onRemove?: (value: KeyResult) => void;
};

export const KeyResultListItem = ({
  keyResult,
  disabled,
  onChange,
  onRemove,
}: KeyResultListItemProps) => {
  const keyPerformanceMetric = useSelector(
    selectKeyPerformanceMetricByID(keyResult.metricId)
  );
  if (!keyPerformanceMetric) {
    return null;
  }
  return (
    <ListItem>
      <FlexContainer alignItems="items-center">
        <FlexContainer direction="flex-col" paddingRight="pr-4" flex="flex-1">
          <Text
            textSize="text-sm"
            textColor="text-gray-900"
            fontWeight="font-medium"
          >
            {keyPerformanceMetric.name}
          </Text>
          <Container marginTop="mt-1">
            <Text textSize="text-sm" textColor="text-gray-300">
              {keyPerformanceMetric.description}
            </Text>
          </Container>
        </FlexContainer>
        <FlexContainer>
          <KeyResultDeltaInput
            disabled={disabled}
            keyResult={keyResult}
            onChange={onChange}
          />
        </FlexContainer>
        {!disabled && (
          <FlexContainer>
            <button
              type="button"
              className="bg-white p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={() => {
                if (!disabled && onRemove) {
                  onRemove(keyResult);
                }
              }}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </ListItem>
  );
};
