import { Solution } from "types";
import { ChangeEvent, useState } from "react";
import { InputGroup, TextInput } from "components/shared/inputs";
import { useDispatch } from "store";
import { createSolution, openPopupFlag, updateSolution } from "store/reducers";
import { Sheet } from "components/shared/cms";
import { SecondaryButton } from "components/shared/buttons";
import { createSheet } from "store/reducers/sheets/sheets.thunks";
import { PlusIcon } from "@heroicons/react/solid";

type SolutionFormProps = {
  entity: Solution;
};

export const SolutionForm = ({ entity }: SolutionFormProps) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(entity);
  const [dirty, setDirty] = useState(false);

  const handleBlur = () => {
    if (dirty) {
      saveChanges();
    }
  };

  const saveChanges = () => {
    dispatch(updateSolution(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Intervention saved",
          })
        );
      });
  };

  const handleChange = (name: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setDirty(true);
      setValues({
        ...values,
        [name]: event.target.value,
      });
    };
  };

  const createSolutionSheet = () => {
    dispatch(
      createSheet({
        blocks: [
          {
            type: "text",
            properties: {
              value: "",
            },
            order: 0,
          },
        ],
      })
    )
      .unwrap()
      .then((sheet) => {
        dispatch(
          updateSolution({
            ...entity,
            sheetId: sheet.id,
          })
        )
          .unwrap()
          .then(() => {
            openPopupFlag({
              appearance: "success",
              title: "Sheet created",
            });
          });
      });
  };

  return (
    <div className="px-12">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
        {entity.name}
      </h1>
      <InputGroup label="Name">
        <TextInput
          name="name"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Description">
        <TextInput
          name="description"
          value={values.description}
          onChange={handleChange("description")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="CMS">
        {entity.sheetId ? (
          <Sheet disabled={false} sheetId={entity.sheetId} />
        ) : (
          <SecondaryButton leadingIcon={PlusIcon} onClick={createSolutionSheet}>
            Create Sheet
          </SecondaryButton>
        )}
      </InputGroup>
    </div>
  );
};
