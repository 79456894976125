import ReactPlayer from "react-player";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDecisionChat } from "features/decisions/hooks";

const VideoPlayer = styled.div`
  border-radius: 1rem;
  width: 6rem;
  height: 6rem;
  background-color: white;
  overflow: hidden;
  transform: scale(1);
  border: 1px solid rgb(243, 244, 246);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.5) translateX(-1.5rem);

    video {
      //transition-delay: 500ms;
      transform: scale(1) translateY(0.5rem);
    }
  }

  video {
    transition: transform 0.2s ease-in-out;
    transform: scale(0.8);
    margin-left: -6rem;
  }
`;

export const ChatVideo = () => {
  const { useCase, objective } = useDecisionChat();
  const [videoUrl, setVideoUrl] = useState<string>(
    "/videos/Climate PI Chat - Use case.mp4"
  );

  useEffect(() => {
    if (!useCase) return;
    setVideoUrl("/videos/Climate PI Chat - Objective.mp4");
  }, [useCase]);

  useEffect(() => {
    if (!objective) return;
    setVideoUrl("/videos/Climate PI Chat - Preparing CDD.mp4");
  }, [objective]);

  const startChat = () => {};
  return (
    <VideoPlayer className="shadow-lg">
      <ReactPlayer
        playing
        muted
        url={videoUrl}
        width="18rem"
        height="fit-content"
        onStart={startChat}
      />
    </VideoPlayer>
  );
};
