import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Ion } from "cesium";
import store from "store";
import { ModalProvider } from "components/modals/ModalProvider/ModalProvider";
import reportWebVitals from "./reportWebVitals";
import { AuthRouter, AppRouter } from "routes";
import "../node_modules/cesium/Build/Cesium/Widgets/widgets.css";
import "base.scss";

// Cesium JS
Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OTZkODMxOS04NjNhLTQyMGEtOTBjNi1lYzczNGJlMDhmYWIiLCJpZCI6ODQ0MzYsImlhdCI6MTY0NjI5ODY1N30.6NpePyHqZoGOTOi7Nw7aELZ9sJRKiL2L9HstJuV_8wI";

// @ts-ignore
window.CESIUM_BASE_URL = "/cesium";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ModalProvider>
        <AuthRouter />
        <AppRouter />
      </ModalProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
