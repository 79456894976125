import { TFile, UUID } from "types";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { Popover } from "@headlessui/react";
import { useSheet } from "hooks/useSheet";
import { createFile, selectBlockByID, updateBlock } from "store/reducers";
import { useDispatch, useSelector } from "store";
import { Label } from "components/shared/typography";
import { PrimaryButton } from "components/shared/buttons";
import { LoadingIcon } from "components/shared/icons";

type FileBlockMenuProps = {
  title: string;
  refElement?: Element | null;
  blockId: UUID;
  mimeType?: string;
};

export const FileBlockMenu = ({
  title,
  refElement,
  blockId,
  mimeType,
}: FileBlockMenuProps) => {
  const dispatch = useDispatch();
  const { closeSheetMenu } = useSheet();
  const block = useSelector(selectBlockByID(blockId));
  const [uploading, setUploading] = useState<boolean>(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(refElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });

  const uploadImage = () => {
    if (!block) {
      return;
    }

    const input = document.createElement("input");
    if (!input) {
      return;
    }

    input.setAttribute("type", "file");
    if (mimeType) {
      input.setAttribute("accept", mimeType);
    }
    input.onchange = async () => {
      if (!input.files) {
        return;
      }
      const file = input.files[0];
      setUploading(true);
      dispatch(createFile(file))
        .unwrap()
        .then((newFile: TFile) => {
          closeSheetMenu();
          dispatch(
            updateBlock({
              ...block,
              properties: {
                url: newFile.url,
                fileId: newFile.id,
              },
            })
          );
        })
        .finally(() => {
          setUploading(false);
        });
    };
    input.click();
  };

  return (
    <Popover as="div">
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        static
        className="w-72 flex flex-col z-30 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 rounded-lg px-1 py-1"
      >
        <div className="flex flex-col px-2 my-1 gap-2 leading-none text-xs">
          <Label textColor="text-gray-400" textSize="text-xs">
            {title}
          </Label>
          <PrimaryButton
            type="button"
            paddingY="py-1"
            onClick={uploadImage}
            disabled={uploading}
          >
            {!uploading ? "Upload a file" : <LoadingIcon />}
          </PrimaryButton>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
