//@ts-nocheck
import { useCallback, useState } from "react";
import Globe from "react-globe.gl";
import "./SimpleGlobe.module.scss";

type SimpleGlobeProps = {
  onClick?: () => void;
};

export const SimpleGlobe = ({ onClick }: SimpleGlobeProps) => {
  const [initialised, setInitialised] = useState(false);
  const globeRef = useCallback(
    (node) => {
      if (node && !initialised) {
        setInitialised(true);
        node.pointOfView(
          {
            lat: 48.8566,
            lng: 2.3522,
            altitude: 1,
          },
          500
        );
        node.controls().enableZoom = false;
        node.controls().autoRotate = true;
        node.controls().autoRotateSpeed = 0.7;
      }
    },
    [initialised]
  );

  return (
    <Globe
      ref={globeRef}
      globeImageUrl="https://unpkg.com/three-globe/example/img/earth-night.jpg"
      onGlobeClick={onClick}
    />
  );
};
