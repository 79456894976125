import { UseCase } from "features/decisions/types/useCase";

const data = {
  cities: {
    id: "b793e755-d300-48fa-a5bb-43b9cc1cbcc8",
    name: "Cities",
    prompt: "I'm a policy maker for a city or municipality",
    url: "cities.climatepi.earth",
  },
  buildings: {
    id: "35a69f50-3ad0-498b-841c-1a34bef89de9",
    name: "Decarbonising Buildings",
    prompt: "I'm a manager wanting to decarbonise my buildings",
    url: "buildings.climatepi.earth",
  },
  mining: {
    id: "10337ea5-d81f-460b-8085-084f2c97a8d8",
    name: "Mining",
    prompt: "I'm the manager at a mining company",
    url: "mining.climatepi.earth",
  },
  greenit: {
    id: "7ae34a96-f301-472b-8b7b-d2985a3ea4d7",
    name: "Sustainable IT",
    prompt: "I'm a manager wanting improve our IT sustainability",
    url: "greenit.climatepi.earth",
  },
};

type UseCaseFixtureKey = keyof typeof data;
export const useCaseFixtures: Record<UseCaseFixtureKey, UseCase> = data;
