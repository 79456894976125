import { ThunkAction, Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import createStore from "./create";

const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useDispatch = () => useReduxDispatch<ThunkAppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
