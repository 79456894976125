import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "store";
import { selectAuthSession } from "store/reducers";
import {
  FlexContainer,
  Title,
  Heading,
  Text,
  CoreDashboardMetrics,
  InteractiveGlobe,
  PoliciesRatingSummary,
  Header,
} from "components/shared";
import { SimpleGlobe } from "components/shared/charts/globes/SimpleGlobe/SimpleGlobe";
import planetBoundaries from "assets/images/planetray-boundaries_1280.png";
import { BrandLogo } from "components/shared/icons/BrandLogo/BrandLogo";
import { Planet } from "features/planet/Planet";

const HomePageSections = ({
  setInteractive,
}: {
  setInteractive: (v: boolean) => void;
}) => {
  return (
    <>
      <FlexContainer
        direction="flex-col"
        width="w-full"
        position="relative"
        flex="flex-1"
        pointerEvents="pointer-events-none"
      >
        <div className="mb-3 pointer-events-auto">
          <div className="flex-shrink-0 mb-2 text-white w-60">{BrandLogo}</div>
          <Title textColor="text-white">The Climate PI</Title>
        </div>

        <FlexContainer
          direction="flex-row"
          width="w-full"
          position="relative"
          flex="flex-1"
        >
          <div>
            <div className="mb-10">
              <Heading textColor="text-white">The Tipping Points</Heading>
              <div className="relative max-w-screen2/3 lg:max-w-screen1/2 w-full mb-5 pointer-events-auto justify-start">
                <CoreDashboardMetrics />
              </div>
            </div>
            <div className="flex flex-col gap-6 max-w-screen1/2 text-white pointer-events-auto">
              <Header textColor="text-white">The 9 Planetary Boundaries</Header>
              <div>
                <Text>
                  The following diagram shows estimates of how the different
                  control variables for planetary boundaries have changed from
                  1950 to present. The pink shaded bars represent the high risk
                  operating space (beyond the 'zone of uncertainty'). Source:
                  Steffen et al. 2015.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <img src={planetBoundaries} alt="The 9 Planetary boundaries" />
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Stratospheric ozone depletion
                </Heading>
                <Text textColor="text-white">
                  The ozone layer in the atmosphere filters out ultraviolet (UV)
                  radiation from the sun. If this layer decreases, increasing
                  amounts of UV radiation will reach ground level. This can
                  cause a higher incidence of skin cancer as well as damage to
                  terrestrial and marine biological systems. Due to the actions
                  taken as a result of the Montreal Protocol, we appear to be on
                  the path to remain within the safe (lower risk) boundary.
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Loss of biosphere integrity (biodiversity loss and
                  extinctions)
                </Heading>
                <Text textColor="text-white">
                  The Millennium Ecosystem Assessment of 2005 concluded that
                  changes to ecosystems due to human activities were more rapid
                  in the past 50 years than at any time in human history,
                  increasing the risks of abrupt and irreversible changes. The
                  key drivers of change are the demand for food, water, and
                  natural resources, causing severe biodiversity loss and
                  leading to changes in ecosystem services. Current high rates
                  of ecosystem damage and extinction can be slowed by efforts to
                  protect the integrity of living systems (biosphere). Further
                  research is underway to improve the availability of data for
                  use as the 'control variables' here.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Chemical pollution and the release of novel entities
                </Heading>
                <Text textColor="text-white">
                  Emissions of toxic and long-lived substances such as synthetic
                  organic pollutants, heavy metal compounds and radioactive
                  materials represent some of the key human-driven changes to
                  the planetary environment. These compounds can have
                  potentially irreversible effects on living organisms and on
                  the physical environment (by affecting atmospheric processes
                  and climate). At present, it is not possible to reliably
                  quantify a single chemical pollution boundary, although the
                  associated risks make it a priority for precautionary action
                  and research.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Climate Change
                </Heading>
                <Text textColor="text-white">
                  Recent evidence suggests that the Earth, now passing 350 ppm
                  CO2 in the atmosphere, has already transgressed the planetary
                  boundary and is approaching several Earth system thresholds.
                  We have reached a point at which the loss of summer polar
                  sea-ice may be irreversible. This is one example of a
                  threshold above which rapid physical feedback mechanisms can
                  start to drive change. A major question is how long we can
                  remain over this boundary before irreversible changes become
                  unavoidable.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Ocean acidification
                </Heading>
                <Text textColor="text-white">
                  Around a quarter of the CO2 that humanity emits into the
                  atmosphere is ultimately dissolved in the oceans. Here it
                  forms carbonic acid, altering ocean chemistry and decreasing
                  the pH of the surface water. This increased acidity reduces
                  the amount of available carbonate ions, an essential 'building
                  block' used by many marine species for shell and skeleton
                  formation. Beyond a threshold concentration, this rising
                  acidity makes it hard for organisms such as corals and some
                  shellfish and plankton species to survive, changing the
                  structure and dynamics of ocean ecosystems. Compared to
                  pre-industrial times, surface ocean acidity has already
                  increased by 30%.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Freshwater consumption and the global hydrological cycle
                </Heading>
                <Text textColor="text-white">
                  The freshwater cycle is strongly affected by climate change
                  and its boundary is closely linked to the climate boundary,
                  yet human pressure is now the dominant driving force
                  determining the functioning and distribution of global
                  freshwater systems. The consequences of human modification of
                  water bodies include both global-scale river flow changes and
                  shifts in vapour flows arising from land use change. These
                  shifts in the hydrological system can be abrupt and
                  irreversible. Water is becoming increasingly scarce - by 2050
                  about half a billion people are likely to be subject to
                  water-stress, increasing the pressure to intervene in water
                  systems.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Land system change
                </Heading>
                <Text textColor="text-white">
                  Land is converted to human use all over the planet. Forests,
                  grasslands, wetlands and other vegetation types have primarily
                  been converted to agricultural land. This land-use change is a
                  driving force behind major reductions in biodiversity, and it
                  has impacts on water flows and on the biogeochemical cycling
                  of carbon, nitrogen and phosphorus and other important
                  elements.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Nitrogen and phosphorus flows to the biosphere and oceans
                </Heading>
                <Text textColor="text-white">
                  The biogeochemical cycles of nitrogen and phosphorus have been
                  radically changed by humans as a result of many industrial and
                  agricultural processes. Nitrogen and phosphorus are both
                  essential elements for plant growth, and fertiliser production
                  and application is the main concern. Human activities now
                  convert more atmospheric nitrogen into reactive forms than all
                  of the Earth's terrestrial processes combined.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
              <div>
                <Heading textSize="text-lg" textColor="text-white">
                  Atmospheric aerosol loading
                </Heading>
                <Text textColor="text-white">
                  An atmospheric aerosol planetary boundary was proposed
                  primarily because of the influence of aerosols on Earth's
                  climate system. Through their interaction with water vapour,
                  aerosols play a critically important role in the hydrological
                  cycle affecting cloud formation and global-scale and regional
                  patterns of atmospheric circulation, such as the monsoon
                  systems in tropical regions. They also have a direct effect on
                  climate, by changing how much solar radiation is reflected or
                  absorbed in the atmosphere.{" "}
                  <a
                    href="https://www.stockholmresilience.org/research/planetary-boundaries/the-nine-planetary-boundaries.html"
                    target="_blank"
                    className="text-pantone-600 underline block"
                  >
                    Read more...
                  </a>
                </Text>
              </div>
            </div>
          </div>
          <div className="text-white">
            <PoliciesRatingSummary onEnterPI={() => setInteractive(true)} />
          </div>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export const HomePage = () => {
  const navigate = useNavigate();
  const session = useSelector(selectAuthSession);
  const [interactive, setInteractive] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (!session) {
      navigate("/logout", { replace: true });
    }
  }, [session]);

  return (
    <>
      <Planet />
      <Transition
        show={!interactive && ready}
        className="opacity-0"
        enter="transition ease-in-out duration-500 delay-700"
        entered="opacity-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <HomePageSections setInteractive={setInteractive} />
      </Transition>
    </>
  );
};
