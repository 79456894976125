import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  UUID,
  TBlock,
  TBlockData,
  CreateBlockRequest,
  UpdateBlockRequest,
  GetBlocksResponse,
  GetBlockResponse,
  DataResponse,
} from "types";
import { API } from "lib/API";

export const mapBlock = (block: TBlock): TBlock => ({
  ...block,
  properties: JSON.parse(block.properties as unknown as string) as TBlockData,
});

export const fetchBlocks = createAsyncThunk(
  "blocks/list",
  async (): Promise<TBlock[]> => {
    const { data } = await API.GET<GetBlocksResponse>("/blocks", {
      pageLimit: 200,
    });
    return data.map(mapBlock);
  }
);

export const fetchBlockById = createAsyncThunk(
  "blocks/getById",
  async (id: UUID): Promise<TBlock> => {
    const { data } = await API.GET<GetBlockResponse>(`/blocks/${id}`);
    return mapBlock(data);
  }
);

export const createBlock = createAsyncThunk(
  "blocks/create",
  async (request: CreateBlockRequest): Promise<TBlock> => {
    const { data } = await API.POST<any, DataResponse<TBlock>>("/blocks", {
      ...request,
      properties: JSON.stringify(request.properties),
    });
    return mapBlock(data);
  }
);

export const updateBlocks = createAsyncThunk(
  "blocks/updateBlocks",
  async (blocks: TBlock[]): Promise<TBlock[]> => {
    const { data } = await API.PUT<any, DataResponse<TBlock[]>>("/blocks", {
      data: blocks.map((block) => ({
        ...block,
        properties: JSON.stringify(block.properties),
      })),
    });
    return data.map(mapBlock);
  }
);

export const updateBlock = createAsyncThunk(
  "blocks/update",
  async (request: UpdateBlockRequest): Promise<TBlock> => {
    const { data } = await API.PUT<any, DataResponse<TBlock>>(
      `/blocks/${request.id}`,
      {
        ...request,
        properties: JSON.stringify(request.properties),
      }
    );
    return mapBlock(data);
  }
);

export const deleteBlock = createAsyncThunk(
  "blocks/delete",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/blocks/${id}`);
  }
);
