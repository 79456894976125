import {
  TextBlock,
  TextBlockProps,
} from "components/shared/cms/TextBlock/TextBlock";
import styled from "styled-components";

const Notice = styled(TextBlock)`
  background-color: rgb(241, 241, 239);
  border-radius: 0.25rem;
  padding: 1rem;
`;

type NoticeBlockProps = TextBlockProps;

export const NoticeBlock = (props: NoticeBlockProps) => {
  return <Notice {...props} />;
};
