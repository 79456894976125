import styled from "styled-components";
import { useState } from "react";
import { CameraIcon } from "@heroicons/react/outline";
import { TBlock, TImageBlock } from "types";
import { useSheet } from "hooks/useSheet";
import { Text, FileBlockMenu } from "components/shared";

const ResponsiveContainer = styled.div`
  width: 100%;
`;

const ResponsiveImage = styled.img`
  width: 100%;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(241, 241, 239);
  color: rgb(156, 163, 175);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgb(229, 229, 229);
  }
`;

const PlaceholderIcon = styled(CameraIcon)`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

type ImageBlockProps = {
  block: TBlock;
};

export const ImageBlock = ({ block }: ImageBlockProps) => {
  const { openSheetMenu } = useSheet();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const properties = block.properties as TImageBlock;

  const openMenu = () => {
    openSheetMenu(
      <FileBlockMenu
        refElement={ref}
        blockId={block.id}
        title="EMBED IMAGE"
        mimeType="image/*"
      />
    );
  };

  if (!properties.url) {
    return (
      <Placeholder ref={setRef} onClick={openMenu}>
        <PlaceholderIcon />
        <Text
          textColor="text-gray-400"
          lineHeight="leading-8"
          textSize="text-sm"
        >
          Embed an image
        </Text>
      </Placeholder>
    );
  }

  return (
    <ResponsiveContainer>
      <ResponsiveImage src={properties.url} />
    </ResponsiveContainer>
  );
};
