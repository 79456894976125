import { useCaseFixtures } from "features/decisions/fixtures/useCaseFixtures";
import { Objective } from "features/decisions/types";

const data = {
  "6cb4ab86": {
    id: "6cb4ab86-e8c6-405d-9a93-71133e34d390",
    name: "Improve Mining Industry Brand",
    useCases: [useCaseFixtures.mining.id],
  },
  cb6c7693: {
    id: "cb6c7693-b4d2-4ca0-9b5c-1c5aee590b4b",
    name: "Reduce Operational Emissions by 33%",
    useCases: [useCaseFixtures.mining.id],
  },
  "7b057a9a": {
    id: "7b057a9a-231a-4c5e-954c-633ef779f111",
    name: "Increase Energy Efficiency by 25%",
    useCases: [useCaseFixtures.mining.id],
  },
  "8e4904f6": {
    id: "8e4904f6-0c7a-4bae-b631-417b0a7e2495",
    name: "Nature Positive by 2030",
    useCases: [useCaseFixtures.mining.id],
  },
  "04b3f9c9": {
    id: "04b3f9c9-0fa8-42a9-b67c-5634b8ed5397",
    name: "Prevent Child Labour & Forced Labour",
    useCases: [useCaseFixtures.mining.id],
  },
  "6995f9a1": {
    id: "6995f9a1-6cdc-41c1-b760-2fea2c25bd4f",
    name: "Transform All Industrial Waste by 2040",
    useCases: [useCaseFixtures.mining.id],
  },
  "9208e467": {
    id: "9208e467-3eff-4a4f-b6de-9f4c259b76ed",
    name: "Improve Exploration Efficiency",
    useCases: [useCaseFixtures.mining.id],
  },
  "65e3a946": {
    id: "65e3a946-5417-4d32-affe-cb0da0cbdad7",
    name: "Increase Energy Efficiency",
    useCases: [useCaseFixtures.buildings.id],
  },
  "434d44a7": {
    id: "434d44a7-c0aa-4474-b379-9cb24c7224f0",
    name: "Implement Passivhaus Design/Retrofit",
    useCases: [useCaseFixtures.buildings.id],
  },
  "76751ed6": {
    id: "76751ed6-6999-49fd-8037-69f5d7c9bef3",
    name: "Switch to Renewable Energy",
    useCases: [useCaseFixtures.buildings.id],
  },
  "79bd48be": {
    id: "79bd48be-08c7-4b2c-99d7-5c8040be0346",
    name: "Air Filtration",
    useCases: [useCaseFixtures.buildings.id],
  },
  "17a66cfa": {
    id: "17a66cfa-782b-4d01-a601-15b87a27d95d",
    name: "Use Sustainable Building Materials",
    useCases: [useCaseFixtures.buildings.id],
  },
  "72bdfe1a": {
    id: "72bdfe1a-1e66-46e3-91a1-ba745c79ac8f",
    name: "Optimise Building Maintenance",
    useCases: [useCaseFixtures.buildings.id],
  },
  b5893ab4: {
    id: "b5893ab4-0193-4a24-bd5d-683a682c1a61",
    name: "Increase Material Use Efficiency",
    useCases: [useCaseFixtures.buildings.id],
  },
  "85198569": {
    id: "85198569-d95d-49e1-9bf9-65ac1609b1d1",
    name: "Reduce Water Use",
    useCases: [useCaseFixtures.buildings.id],
  },
  "63dc9eb1": {
    id: "63dc9eb1-543b-4bd4-b0f7-2e10ece4cef8",
    name: "Optimise Building for Hybrid Work",
    useCases: [useCaseFixtures.buildings.id],
  },
  "038cb933": {
    id: "038cb933-524c-4ef9-b171-bf968df13799",
    name: "Encourage Green Transport",
    useCases: [useCaseFixtures.buildings.id],
  },
  "7bff5a98": {
    id: "7bff5a98-113e-4ad6-b5b4-dbe3e9994826",
    name: "Reduce Flooding",
    useCases: [useCaseFixtures.cities.id],
  },
  "91447f8b": {
    id: "91447f8b-91ca-4a0e-90b0-8d6798e97ceb",
    name: "Reduce Heat Island Effect",
    useCases: [useCaseFixtures.cities.id],
  },
  "001eda3c": {
    id: "001eda3c-c1c4-4820-8294-232dd4f85d55",
    name: "Net Zero for City Institutions by 2030",
    useCases: [useCaseFixtures.cities.id],
  },
  "13c8957f": {
    id: "13c8957f-f062-471f-b8f8-6a6f1a329bc3",
    name: "Increase Renewable Energy Use",
    useCases: [useCaseFixtures.cities.id],
  },
  c6561d57: {
    id: "c6561d57-b6b2-49e2-abea-b12c34ca9b1e",
    name: "Reduce Water Waste",
    useCases: [useCaseFixtures.cities.id],
  },
  "1679016f": {
    id: "1679016f-177d-4234-95ae-094be73bdf00",
    name: "Increase Composting & Recycling",
    useCases: [useCaseFixtures.cities.id],
  },
  "44c7fa89": {
    id: "44c7fa89-4519-432d-9f2e-50c9e0821f12",
    name: "Reduce Energy Demand",
    useCases: [useCaseFixtures.cities.id],
  },
  a9c36bac: {
    id: "a9c36bac-8ae3-480e-89cd-7278b1659d64",
    name: "Improve Air Quality",
    useCases: [useCaseFixtures.cities.id],
  },
  "96813217": {
    id: "96813217-1091-4402-86fb-0517d3cf84c2",
    name: "Reduce GHG Emissions",
    useCases: [useCaseFixtures.cities.id],
  },
  "481cec79": {
    id: "481cec79-4b08-4151-a248-4ce9995534a1",
    name: "Energy Efficiency",
    useCases: [useCaseFixtures.greenit.id],
  },
  f7b516cc: {
    id: "f7b516cc-8ba0-4cb7-a066-6944bcdc5744",
    name: "Waste disposal",
    useCases: [useCaseFixtures.greenit.id],
  },
  deac8b55: {
    id: "deac8b55-be5c-4e87-a011-f1770fadc7f1",
    name: "Energy Consumption",
    useCases: [useCaseFixtures.greenit.id],
  },
  "95d55b14": {
    id: "95d55b14-9d4d-4c9c-aa83-d10ffc80da37",
    name: "Percentage Hosted on Cloud",
    useCases: [useCaseFixtures.greenit.id],
  },
  "51c0de07": {
    id: "51c0de07-f490-4959-9da3-6a0c4d4a908c",
    name: "Hardware/Equipment Lifecycle Emissions",
    useCases: [useCaseFixtures.greenit.id],
  },
  "4d524295": {
    id: "4d524295-827d-485d-be99-b9501bed904c",
    name: "Percentage Equipment Recycled/Refurbished",
    useCases: [useCaseFixtures.greenit.id],
  },
  "10a0654c": {
    id: "10a0654c-9096-49b6-9a65-27b85811ae52",
    name: "Percentage Equipment Rented",
    useCases: [useCaseFixtures.greenit.id],
  },
  fdfe2d15: {
    id: "fdfe2d15-ddbf-419a-a786-9f01100156cc",
    name: "Equipment Useage Data",
    useCases: [useCaseFixtures.greenit.id],
  },
  "7cdf4c2b": {
    id: "7cdf4c2b-ce15-49d7-95d0-c2804295c9bd",
    name: "Hybrid Work Percentage",
    useCases: [useCaseFixtures.greenit.id],
  },
  "32f9886b": {
    id: "32f9886b-2e79-4aef-b940-8c9bf9a07600",
    name: "Worker Transportation Methods",
    useCases: [useCaseFixtures.greenit.id],
  },
  "793e01ca": {
    id: "793e01ca-caec-4168-a613-52df29adbdea",
    name: "Building Occupancy Data",
    useCases: [useCaseFixtures.greenit.id],
  },
  cb0a902d: {
    id: "cb0a902d-1c0e-4211-bb51-b397718c99ae",
    name: "Equpment Energy Certifications",
    useCases: [useCaseFixtures.greenit.id],
  },
  "106ea9b6": {
    id: "106ea9b6-2065-47d8-bde5-76faa88684cb",
    name: "Supplier Energy Use",
    useCases: [useCaseFixtures.greenit.id],
  },
  "4a0b27f5": {
    id: "4a0b27f5-f9b7-4553-bf11-eb44f2c128fc",
    name: "Water Consumption",
    useCases: [useCaseFixtures.greenit.id],
  },
  "13478de6": {
    id: "13478de6-f49c-4c8b-8220-84386c6531db",
    name: "Percentage Equipment Owned/Rented",
    useCases: [useCaseFixtures.greenit.id],
  },
  "794af13d": {
    id: "794af13d-c305-438d-ad8f-9c11fd4cda95",
    name: "Maintenance Costs",
    useCases: [useCaseFixtures.greenit.id],
  },
};

type ObjectiveFixtureKey = keyof typeof data;
export const objectiveFixtures: Record<ObjectiveFixtureKey, Objective> = data;
