export const BrandLogo = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1460.6 743.9"
    xmlSpace="preserve"
    width="100%"
    height="100%"
    fill="currentColor"
  >
    <g>
      <g>
        <g>
          <path
            className="st0"
            d="M373,332.3c-9.6,0-17.4,7.8-17.4,17.4s7.8,17.4,17.4,17.4s17.4-7.8,17.4-17.4
				C390.4,340.1,382.6,332.3,373,332.3z"
          />
          <path
            className="st0"
            d="M248,248v248h248V248H248z M440.3,349.9c0,0.2,0,0.4,0,0.6s-0.2,5.6-0.5,8.1c0,0.1,0,0.2,0,0.3
				c-0.1,0.4-0.1,0.8-0.2,1.2c-2.1,14.3-8.6,25.4-18.6,33.4c-8.7,7.5-19.7,12.4-31.8,13.5v-26h-49.3v7.4h5.1c6.6,0,12,5.4,12,12V450
				h-49.5V294h66.6c39.3,0,66.2,18.5,66.2,55.5V349.9z"
          />
        </g>
      </g>
      <g>
        <path
          className="st0"
          d="M601.2,315.7c0,3.8-0.7,7-2.1,9.6c-1.4,2.6-3.3,4.7-5.7,6.3c-2.4,1.6-5.2,2.8-8.3,3.5s-6.4,1.1-9.8,1.1h-7.8
			v24.6h-15.7v-65.1h23.9c3.6,0,6.9,0.4,10,1.1s5.8,1.8,8.1,3.4s4.1,3.6,5.4,6.2C600.5,308.8,601.2,311.9,601.2,315.7z M585.5,315.8
			c0-1.5-0.3-2.8-0.9-3.8c-0.6-1-1.4-1.7-2.5-2.3c-1-0.6-2.2-0.9-3.5-1.1s-2.7-0.3-4.1-0.3h-6.9v15.4h6.6c1.5,0,2.9-0.1,4.2-0.4
			c1.3-0.2,2.6-0.7,3.6-1.3c1.1-0.6,1.9-1.4,2.5-2.4C585.1,318.6,585.5,317.3,585.5,315.8z"
        />
        <path
          className="st0"
          d="M608.8,360.8v-65.1h15.8v51.4h25.2v13.7H608.8z"
        />
        <path
          className="st0"
          d="M706.9,360.8l-5.1-12.8h-25.2l-4.8,12.8h-17.1l27.3-65.1h15.3l27.1,65.1H706.9z M689.4,313l-8.3,22.4h16.4
			L689.4,313z"
        />
        <path
          className="st0"
          d="M771.2,360.8L745,318.2h-0.3l0.4,42.6h-15.3v-65.1h17.9l26.1,42.5h0.3l-0.4-42.5H789v65.1L771.2,360.8
			L771.2,360.8z"
        />
        <path
          className="st0"
          d="M802.5,360.8v-65.1h43.8V309h-28.6v12.3h27.1v12.6h-27.1v13.5H848v13.4L802.5,360.8L802.5,360.8z"
        />
        <path
          className="st0"
          d="M891.8,309.1v51.7H876v-51.7h-18.4v-13.4h52.5v13.4H891.8z"
        />
        <path
          className="st0"
          d="M959.4,360.8l-5.1-12.8h-25.2l-4.8,12.8h-17.1l27.3-65.1h15.3l27.1,65.1H959.4z M941.9,313l-8.3,22.4H950
			L941.9,313z"
        />
        <path
          className="st0"
          d="M1019,360.8l-14.1-25.9h-5.4v25.9h-15.4v-65.1h24.8c3.1,0,6.2,0.3,9.2,1c3,0.6,5.6,1.7,8,3.3
			c2.4,1.5,4.2,3.6,5.7,6.1c1.4,2.5,2.1,5.6,2.1,9.4c0,4.4-1.2,8.1-3.6,11.1s-5.7,5.2-9.9,6.4l17,27.9L1019,360.8L1019,360.8z
			 M1018.4,315.6c0-1.5-0.3-2.8-1-3.7c-0.6-1-1.5-1.7-2.5-2.2s-2.2-0.9-3.4-1.1c-1.3-0.2-2.5-0.3-3.7-0.3h-8.4v15.2h7.5
			c1.3,0,2.6-0.1,4-0.3c1.4-0.2,2.6-0.6,3.7-1.2c1.1-0.6,2-1.3,2.7-2.4C1018,318.6,1018.4,317.2,1018.4,315.6z"
        />
        <path
          className="st0"
          d="M1076.8,333.2v27.6h-15.7v-27.6l-24.5-37.5h19l13.9,24.1l13.9-24.1h18.4L1076.8,333.2z"
        />
        <path className="st0" d="M551.8,451.5v-65.1h15.8v65.1H551.8z" />
        <path
          className="st0"
          d="M622.4,451.5l-26.2-42.6h-0.3l0.4,42.6H581v-65.1h18l26.1,42.5h0.3l-0.4-42.5h15.3v65.1H622.4z"
        />
        <path
          className="st0"
          d="M683.5,399.8v51.7h-15.7v-51.7h-18.4v-13.4h52.5v13.4H683.5z"
        />
        <path
          className="st0"
          d="M710.9,451.5v-65.1h43.8v13.3h-28.6V412h27.1v12.6h-27.1v13.5h30.3v13.4H710.9z"
        />
        <path
          className="st0"
          d="M768.6,451.5v-65.1h15.8v51.4h25.2v13.7H768.6z"
        />
        <path
          className="st0"
          d="M818.8,451.5v-65.1h15.8v51.4h25.2v13.7H818.8z"
        />
        <path className="st0" d="M869.1,451.5v-65.1h15.8v65.1H869.1z" />
        <path
          className="st0"
          d="M945,451.6c-4.3,1-8.9,1.6-13.9,1.6c-5.2,0-10-0.8-14.4-2.5s-8.2-4-11.3-7c-3.2-3-5.6-6.6-7.4-10.8
			c-1.8-4.2-2.7-8.9-2.7-14c0-5.2,0.9-9.9,2.7-14.2c1.8-4.2,4.3-7.8,7.5-10.8s6.9-5.3,11.2-6.9s8.9-2.4,13.9-2.4
			c5.2,0,9.9,0.8,14.4,2.3s8,3.7,10.8,6.3l-9.9,11.3c-1.5-1.8-3.6-3.2-6.1-4.4c-2.5-1.1-5.4-1.7-8.6-1.7c-2.8,0-5.3,0.5-7.6,1.5
			s-4.4,2.4-6.1,4.2c-1.7,1.8-3.1,4-4,6.4c-1,2.5-1.4,5.2-1.4,8.1c0,3,0.4,5.8,1.3,8.3c0.9,2.5,2.1,4.7,3.8,6.5s3.8,3.2,6.3,4.2
			s5.3,1.5,8.5,1.5c1.8,0,3.6-0.1,5.2-0.4c1.7-0.3,3.2-0.7,4.6-1.3v-11.9h-12.4v-12.7h26.9v34.5C953,449.2,949.3,450.6,945,451.6z"
        />
        <path
          className="st0"
          d="M969.1,451.5v-65.1h43.8v13.3h-28.6V412h27.1v12.6h-27.1v13.5h30.3v13.4H969.1z"
        />
        <path
          className="st0"
          d="M1068.2,451.5l-26.2-42.6h-0.3l0.4,42.6h-15.3v-65.1h17.9l26.1,42.5h0.3l-0.4-42.5h15.3v65.1H1068.2z"
        />
        <path
          className="st0"
          d="M1146.1,450.4c-4.2,1.9-9.1,2.9-14.6,2.9c-5,0-9.7-0.8-13.9-2.5c-4.3-1.7-8-4-11.1-7s-5.6-6.7-7.4-10.9
			c-1.8-4.2-2.7-8.9-2.7-14c0-5.2,0.9-9.9,2.7-14.2c1.8-4.2,4.3-7.8,7.5-10.8s6.9-5.3,11.2-6.9c4.3-1.6,8.9-2.4,13.9-2.4
			c4.6,0,9.1,0.8,13.6,2.4c4.4,1.6,8.1,4,10.8,7.1l-10.7,10.7c-1.5-2-3.4-3.5-5.8-4.5s-4.8-1.5-7.4-1.5c-2.8,0-5.3,0.5-7.6,1.5
			c-2.3,1-4.3,2.4-5.9,4.2c-1.7,1.8-2.9,3.9-3.9,6.3c-0.9,2.4-1.4,5.1-1.4,8s0.5,5.6,1.4,8.1s2.2,4.6,3.8,6.3
			c1.6,1.7,3.6,3.1,5.8,4.1c2.3,1,4.8,1.5,7.5,1.5c3.1,0,5.9-0.6,8.2-1.8c2.3-1.2,4.2-2.8,5.6-4.8l11,10.3
			C1153.9,445.8,1150.3,448.5,1146.1,450.4z"
        />
        <path
          className="st0"
          d="M1167.2,451.5v-65.1h43.8v13.3h-28.6V412h27.1v12.6h-27.1v13.5h30.3v13.4H1167.2z"
        />
      </g>
    </g>
  </svg>
);
