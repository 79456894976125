import { ChatMessage } from "features/conversations/types";
import { ButtonGroup, GrayButton, Text, WhiteButton } from "components/shared";
import { useDecisionChat } from "features/decisions/hooks";
import { useCaseFixtures } from "features/decisions/fixtures/useCaseFixtures";

export type UseCasePickerChatMessageProps = {
  message: ChatMessage;
};

export const UseCasePickerChatMessage = ({
  message,
}: UseCasePickerChatMessageProps) => {
  const { useCase, setUseCase } = useDecisionChat();
  return (
    <div className="flex flex-col gap-4">
      <Text>{message.message}</Text>
      {!useCase && (
        <ButtonGroup className="flex-col">
          {Object.values(useCaseFixtures).map((value) => (
            <GrayButton key={value.id} onClick={() => setUseCase(value)}>
              {value.name}
            </GrayButton>
          ))}
        </ButtonGroup>
      )}
    </div>
  );
};
