import { Button, GenericButtonProps } from "components/shared/buttons";

export type GrayButtonProps = GenericButtonProps & {};

export const GrayButton = ({ ...otherProps }: GrayButtonProps) => {
  return (
    <Button
      background="bg-gray-100"
      textColor="text-gray-900"
      backgroundHover="hover:bg-gray-200"
      ringFocusColor="focus:ring-transparent"
      borderColor="border-transparent"
      shadow="shadow-none"
      {...otherProps}
    />
  );
};
