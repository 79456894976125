import { Button, GenericButtonProps } from "components/shared/buttons";
import { forwardRef } from "react";

export type SecondaryButtonProps = GenericButtonProps & {};

export const SecondaryButton = forwardRef(
  ({ ...otherProps }: SecondaryButtonProps, ref: any) => {
    return (
      <Button
        background="bg-gray-100"
        textColor="text-gray-500"
        backgroundHover="hover:bg-gray-200"
        ringFocusColor="focus:ring-gray-500"
        {...otherProps}
        ref={ref}
      />
    );
  }
);
