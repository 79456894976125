import { Pill, PillProps } from "components/shared";

type GreenPillProps = PillProps & {};

export const GreenPill = (props: GreenPillProps) => {
  return (
    <Pill
      {...props}
      textColor="text-green-800"
      backgroundColor="bg-green-100"
    />
  );
};
