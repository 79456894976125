import { Solution } from "types";
import { useDispatch } from "store";
import { createSolution } from "store/reducers";

type SolutionSelectorEmptyProps = {
  query?: string;
  onCreate?: (value: Solution) => void;
};

export const SolutionSelectorEmpty = ({
  query,
  onCreate,
}: SolutionSelectorEmptyProps) => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    if (query) {
      dispatch(
        createSolution({
          name: query,
          description: "",
          coreMetricIDs: [],
        })
      )
        .unwrap()
        .then((data) => onCreate && onCreate(data));
    }
  };
  return (
    <div className="p-4 text-sm text-center">
      <p className="text-gray-500">No solutions found</p>
      {query && (
        <p
          className="block mt-5 rounded-md text-blue-400 cursor-pointer hover:bg-blue-50 px-2 py-1"
          onClick={handleCreate}
        >
          Add "{query}" to solutions
        </p>
      )}
    </div>
  );
};
