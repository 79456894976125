import { ChatMessage } from "features/conversations/types";
import { ButtonGroup, GrayButton, Text } from "components/shared";
import { useDecisionChat } from "features/decisions/hooks";
import { objectiveFixtures } from "features/decisions/fixtures/objectiveFixtures";

export type ObjectivePickerChatMessageProps = {
  message: ChatMessage;
};

export const ObjectivePickerChatMessage = ({
  message,
}: ObjectivePickerChatMessageProps) => {
  const { useCase, objective, setObjective } = useDecisionChat();
  if (!useCase) return null;
  return (
    <div className="flex flex-col gap-4">
      <Text>{message.message}</Text>
      {!objective && (
        <ButtonGroup className="flex-col">
          {Object.values(objectiveFixtures)
            .filter((fixture) => fixture.useCases.includes(useCase.id))
            .map((value) => (
              <GrayButton key={value.id} onClick={() => setObjective(value)}>
                {value.name}
              </GrayButton>
            ))}
        </ButtonGroup>
      )}
    </div>
  );
};
