import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/PI_Logo_Small_white.svg";
import { useDispatch, useSelector } from "store";
import { login, selectAuthSession } from "store/reducers";
import { EmailInput, PasswordInput } from "components/shared/inputs";
import { FlexContainer, PrimaryButton, Text } from "components/shared";
import { BrandLogo } from "components/shared/icons/BrandLogo/BrandLogo";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectAuthSession);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (session) {
      navigate("/dashboard", { replace: true });
    }
  }, [session]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setError(undefined);
    dispatch(
      login({
        email,
        password,
      })
    )
      .unwrap()
      .catch((err) => {
        setError(err.message || "Something went wrong, please try again");
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <div>
          <div className="mx-auto h-20 w-auto text-white">{BrandLogo}</div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <EmailInput
                rounding="rounded-t-md"
                id="email-address"
                name="email"
                placeholder="Email address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                lineHeight="leading-8"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <PasswordInput
                rounding="rounded-b-md"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                lineHeight="leading-8"
              />
            </div>
          </div>

          {error && (
            <FlexContainer>
              <Text textColor="text-white">{error}</Text>
            </FlexContainer>
          )}

          <div>
            <PrimaryButton type="submit">Sign in</PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};
