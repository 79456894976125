import emojiRegex from "emoji-regex";
import orderBy from "lodash/orderBy";
import { TBlock, UUID } from "types";

export const getCaretCoordinates = () => {
  let x, y;
  const selection = window.getSelection();
  if (selection?.rangeCount !== 0) {
    const range = selection?.getRangeAt(0).cloneRange();
    range?.collapse(false);
    const rect = range?.getClientRects()[0];
    if (rect) {
      x = rect.left;
      y = rect.top;
    }
  }
  return { x, y };
};

export const setCaretToEnd = (element: any) => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection?.removeAllRanges();
  selection?.addRange(range);
  element.focus();
};

export const wrapEmojis = (text: string = "") => {
  if (!text) {
    return "";
  }
  return text.replace(emojiRegex(), (emoji) => {
    return `<span class="emoji">${emoji}</span>`;
  });
};

export const orderPageBlocks = (
  pageBlocks: TBlock[] | Record<UUID, TBlock>
): TBlock[] => {
  return orderBy(
    Array.isArray(pageBlocks) ? pageBlocks : Object.values(pageBlocks),
    "order",
    "asc"
  );
};

/**
 * Get the DOM node for a block
 * @param {string} blockID
 * @return {Element|null}
 */
export const getDOMNodeForBlockID = (blockID: UUID) => {
  const blockNode = document.getElementById(blockID);
  return blockNode || null;
};

/**
 *
 * @param {Object} pageBlocks
 * @param {string} siblingBlockID
 * @param {TBlock} newBlock
 * @return {Array.<PageBlock>}
 */
export const insertBlockAfterSibling = (
  pageBlocks: Record<UUID, TBlock>,
  siblingBlockID: UUID,
  newBlock: TBlock
) => {
  const siblingPageBlock = pageBlocks[siblingBlockID];
  const currentBlockOrder = siblingPageBlock.order;

  // Order the existing page block
  let newPageBlocks = orderPageBlocks(pageBlocks).map((pageBlock) => ({
    ...pageBlock,
    order:
      pageBlock.order > currentBlockOrder
        ? pageBlock.order + 1
        : pageBlock.order,
  }));

  // Add the new page block
  newPageBlocks.push({
    ...newBlock,
    id: newBlock.id,
    order: currentBlockOrder + 1,
  });

  // Reset the order numbers now
  return orderPageBlocks(newPageBlocks).map((block, index) => ({
    ...block,
    order: index,
  }));
};

/**
 * Add a block to the page blocks
 * @param {Object} pageBlocks
 * @param {TBlock|null} block
 * @return {Array.<PageBlock>}
 */
export const updateBlock = (
  pageBlocks: Record<UUID, TBlock>,
  block: TBlock
) => {
  return Object.values({
    ...pageBlocks,
    [block.id]: {
      ...pageBlocks[block.id],
      block: block,
    },
  });
};

/**
 * Remove a block from the page blocks
 * @param {Object} pageBlocks
 * @param {string} blockID
 * @return {Array.<PageBlock>}
 */
export const removeBlockFromPageBlocks = (
  pageBlocks: Record<UUID, TBlock>,
  blockID: UUID
) => {
  const newPageBlocks = {
    ...pageBlocks,
  };
  delete newPageBlocks[blockID];
  return Object.values(orderPageBlocks(newPageBlocks));
};
