import { createContext, ReactNode, useEffect, useState } from "react";
import {
  ChatMessage,
  ChatMessageAuthor,
  ChatMessageType,
} from "features/conversations/types/chatMessage";
import cuid from "cuid";
import { UseCase, Objective } from "features/decisions/types";
import { useCreateDecisionMutation } from "features/decisions/apis/decisions.api";
import { DecisionModel } from "features/decisions/types/decisionModel";

export type DecisionChatContext = {
  messages: ChatMessage[];
  typing: boolean;
  useCase?: UseCase;
  objective?: Objective;
  decision?: DecisionModel;
  regenerateDecision: () => void;
  setUseCase: (value: UseCase) => void;
  setObjective: (value: Objective) => void;
  addMessage: (message: ChatMessage, timeout?: number) => void;
};

const noop = () => {};

export const DecisionChatContext = createContext<DecisionChatContext>({
  messages: [],
  typing: false,
  regenerateDecision: noop,
  setUseCase: noop,
  setObjective: noop,
  addMessage: noop,
});

export type DecisionChatProviderProps = {
  children: ReactNode;
};

export const DecisionChatProvider = (props: DecisionChatProviderProps) => {
  const [typing, setTyping] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [useCase, setUseCase] = useState<UseCase>();
  const [decision, setDecision] = useState<DecisionModel>();
  const [objective, setObjective] = useState<Objective>();
  const [createDecision, { isLoading }] = useCreateDecisionMutation();

  useEffect(() => {
    const init = async () => {
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.User,
        message: "Hi, I would like to create a decision model for my use case.",
        createdAt: Date.now(),
      });
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.Bot,
        message:
          "Hey, I'm Climate PI. I'm here to help you build your Causal Decision Model.",
        createdAt: Date.now(),
      });
      await delay(4500);
      await addMessage({
        id: cuid(),
        type: ChatMessageType.UseCasePicker,
        author: ChatMessageAuthor.Bot,
        message: "What is your use case?",
        createdAt: Date.now(),
      });
    };
    init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: messages.length === 0 ? 0 : document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  }, [messages.length]);

  useEffect(() => {
    if (!useCase) return;
    addMessage({
      id: cuid(),
      type: ChatMessageType.Text,
      author: ChatMessageAuthor.User,
      message: `I want to create a decision model for ${useCase.name}.`,
      createdAt: Date.now(),
    }).then(async () => {
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.Bot,
        message: "I can help you with that!",
        createdAt: Date.now(),
      });
      await delay(1000);
      await addMessage({
        id: cuid(),
        type: ChatMessageType.ObjectivePicker,
        author: ChatMessageAuthor.Bot,
        message: "What is your objective?",
        createdAt: Date.now(),
      });
    });
  }, [useCase]);

  useEffect(() => {
    if (!useCase) return;
    if (!objective) return;
    addMessage({
      id: cuid(),
      type: ChatMessageType.Text,
      author: ChatMessageAuthor.User,
      message: objective.name,
      createdAt: Date.now(),
    }).then(async () => {
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.Bot,
        message: `Great, give me a moment!\n\nI'm putting together your Causal Decision Model, using my climate expertise, vast knowledge of best practices and validated solutions, together with your context and constraints.`,
        createdAt: Date.now(),
      });
      setTimeout(async () => {
        await addMessage({
          id: cuid(),
          type: ChatMessageType.Text,
          author: ChatMessageAuthor.Bot,
          message: `I'm currently thinking through which of the 20,000+ solutions are worth recommending to you.`,
          createdAt: Date.now(),
        });
      }, 12_250);
      await generateDecision();
    });
  }, [objective]);

  const regenerateDecision = async () => {
    addMessage({
      id: cuid(),
      type: ChatMessageType.Text,
      author: ChatMessageAuthor.User,
      message: "Please regenerate the decision model.",
      createdAt: Date.now(),
    }).then(async () => {
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.Bot,
        message: "Sure thing! Give me a moment.",
        createdAt: Date.now(),
      });
      await generateDecision();
    });
  };

  const generateDecision = async () => {
    if (!useCase) return;
    if (!objective) return;
    const startTime = Date.now();

    setTimeout(async () => {
      await addMessage({
        id: cuid(),
        type: ChatMessageType.Text,
        author: ChatMessageAuthor.Bot,
        message: `Alright, I'm ready, let me write up your Causal Decision Model.`,
        createdAt: Date.now(),
      });
      setTyping(true);
    }, 20_250);

    const response = await createDecision({
      useCaseId: useCase.id,
      useCaseDomain: useCase.url,
      useCase: useCase.name,
      objective: `${useCase.prompt}, please help me achieve my objective of "${objective.name}"`,
    }).unwrap();
    setDecision(response);

    // If it has been less than 21 seconds, wait until 21 seconds have passed
    if (startTime - Date.now() < 20_000) {
      await delay(21_000 - (Date.now() - startTime));
    }

    await addMessage({
      id: cuid(),
      type: ChatMessageType.ReviewModel,
      author: ChatMessageAuthor.Bot,
      message: response.decisionModelText || response.rawLlmResponse,
      createdAt: Date.now(),
    });
  };

  const delay = async (timeout: number) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  };

  const addMessage = async (message: ChatMessage, timeout = 750) => {
    return new Promise<void>((resolve) => {
      // Uses do not type and messages are visible immediately
      if (message.author === ChatMessageAuthor.User) {
        setMessages((prevState) => [...prevState, message]);
        resolve();
        return;
      }

      setTyping(true);
      setTimeout(() => {
        setMessages((prevState) => [...prevState, message]);
        resolve();
      }, timeout);
    }).finally(() => setTyping(false));
  };

  return (
    <DecisionChatContext.Provider
      value={{
        messages,
        typing: typing,
        useCase,
        objective,
        decision,
        regenerateDecision,
        setUseCase,
        setObjective,
        addMessage,
      }}
      {...props}
    />
  );
};
