import { CoreMetric } from "types";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "store";
import { openPopupFlag, updateCoreMetric } from "store/reducers";
import { InputGroup, NumberInput, TextInput } from "components/shared/inputs";

type CoreMetricFormProps = {
  entity: CoreMetric;
};

export const CoreMetricForm = ({ entity }: CoreMetricFormProps) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(entity);
  const [dirty, setDirty] = useState(false);

  const handleBlur = () => {
    if (dirty) {
      saveChanges();
    }
  };

  const saveChanges = () => {
    dispatch(updateCoreMetric(values))
      .unwrap()
      .then((data) => {
        setValues(data);
        dispatch(
          openPopupFlag({
            appearance: "success",
            title: "Core metric saved",
          })
        );
      });
  };

  const handleChange = (name: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setDirty(true);
      setValues({
        ...values,
        [name]: event.target.value,
      });
    };
  };

  return (
    <div className="max-w-lg">
      <InputGroup label="Name">
        <TextInput
          name="name"
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Description">
        <TextInput
          name="description"
          value={values.description}
          onChange={handleChange("description")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Color">
        <TextInput
          name="color"
          value={values.color}
          onChange={handleChange("color")}
          onBlur={handleBlur}
        />
      </InputGroup>
      <InputGroup label="Max">
        <NumberInput
          name="max"
          value={values.max}
          min={0}
          onBlur={handleBlur}
          onChange={(value: number) =>
            setValues({
              ...values,
              max: value,
            })
          }
        />
      </InputGroup>
      <InputGroup label="Value">
        <NumberInput
          name="value"
          value={values.value}
          min={0}
          onBlur={handleBlur}
          onChange={(value: number) =>
            setValues({
              ...values,
              value: value,
            })
          }
        />
      </InputGroup>
    </div>
  );
};
