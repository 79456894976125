import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { CoreMetric } from "types";

type CoreMetricSelectorItemProps = {
  coreMetric: CoreMetric;
};

export const CoreMetricSelectorItem = ({
  coreMetric,
}: CoreMetricSelectorItemProps) => {
  return (
    <Combobox.Option
      key={coreMetric.id}
      value={coreMetric}
      className={({ active }) =>
        classNames(
          "cursor-pointer select-none px-4 py-2",
          active && "bg-zinc-600  text-white"
        )
      }
    >
      {coreMetric.name}
    </Combobox.Option>
  );
};
