import { GenericInputProps, Input } from "./Input";
import { ChangeEvent } from "react";

type NumberInputProps = GenericInputProps & {
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
};

export const NumberInput = (props: NumberInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.valueAsNumber);
  };
  return <Input {...props} type="number" onChange={handleChange} />;
};
