import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { UUID } from "types";
import { focusBlock, selectSheetByID } from "store/reducers";
import { selectBlocksBySheetID } from "store/reducers/sheets/blocks.selectors";
import { SheetBlocks, SheetProvider } from "components/shared/cms";
import { LoadingIcon } from "components/shared/icons";
import { fetchSheetById } from "store/reducers/sheets/sheets.thunks";

const StyledSheet = styled.div`
  color: rgb(17, 24, 39);
`;

type SheetsProps = {
  sheetId: UUID;
  disabled: boolean; // Allows the user to edit the sheet or not.
};

export const Sheet = ({ sheetId, disabled }: SheetsProps) => {
  const dispatch = useDispatch();
  const sheet = useSelector(selectSheetByID(sheetId));
  const blocks = useSelector(selectBlocksBySheetID(sheetId));

  useEffect(() => {
    if (!sheet) {
      dispatch(fetchSheetById(sheetId));
    }
  }, []);

  useEffect(() => {
    // When only one block exists, select it
    if (blocks?.length === 1) {
      dispatch(focusBlock(blocks[0].id));
    }
  }, [blocks]);

  if (!sheet) {
    return <LoadingIcon textColor="currentColor" />;
  }

  return (
    <SheetProvider disabled={disabled}>
      <StyledSheet>
        <SheetBlocks sheetId={sheetId} />
      </StyledSheet>
    </SheetProvider>
  );
};
