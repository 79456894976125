import { useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectKeyPerformanceMetricByID } from "store/reducers";
import { UUID } from "types";
import { LoadingIcon, MetricForm } from "components/shared";

export const MetricSettingsPage = () => {
  let { id } = useParams();
  const entity = useSelector(selectKeyPerformanceMetricByID(id as UUID));

  if (!entity) {
    return <LoadingIcon />;
  }

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
        {entity.name}
      </h1>
      <MetricForm entity={entity} />
    </div>
  );
};
