import { useState } from "react";
import styled from "styled-components";
import { PlusIcon } from "@heroicons/react/solid";
import { TBlockType, UUID } from "types";
import { useSheet } from "hooks/useSheet";
import {
  BlockControl,
  DragHandle,
  EditBlockMenu,
  CreateBlockMenu,
} from "components/shared/cms";

const StyledBlockControllers = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -3rem;
`;

type BlockControllersProps = {
  blockId: UUID;
  onCreateBlock?: (type: TBlockType) => void;
};

export const BlockControllers = ({
  blockId,
  onCreateBlock,
}: BlockControllersProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { disabled, openSheetMenu } = useSheet();

  const handleClick = () => {
    openSheetMenu(
      <CreateBlockMenu refElement={ref} onCreateBlock={onCreateBlock} />
    );
  };

  const handleShowMenu = () => {
    openSheetMenu(
      <EditBlockMenu
        refElement={ref}
        blockId={blockId}
        onCreateBlock={onCreateBlock}
      />
    );
  };

  if (disabled) {
    return null;
  }

  return (
    <StyledBlockControllers ref={setRef}>
      <BlockControl onClick={handleClick}>
        <PlusIcon />
      </BlockControl>
      <DragHandle onClick={handleShowMenu} />
    </StyledBlockControllers>
  );
};
