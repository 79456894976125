import { ApexOptions } from "apexcharts";
import styled from "styled-components";
import { Chart } from "components/shared/charts/Chart/Chart";

const ChartContainer = styled.div`
  position: relative;
`;

const Label = styled.div`
  vertical-align: top;
  text-align: center;
  padding-bottom: 1rem;
  color: white;
`;

type CDMChartProps = {
  label: string;
  value: number;
  max: number;
  divide?: number;
  height?: number;
  primaryColor?: string;
  hideSign?: boolean;
};

export const CDMChart = ({
  label,
  value = 0,
  max,
  divide = 100,
  height = 130,
  primaryColor = "#008f98",
  hideSign = false,
}: CDMChartProps) => {
  const series = [(value / max) * 100];
  const options: ApexOptions = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 0,
          background: "#000011",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 8,
            fontSize: "1rem",
            fontWeight: 600,
            color: "white",
            formatter: (): string => {
              let sign = "";
              if (value > 0 && !hideSign) {
                sign = "+";
              }
              return sign + (value / divide).toString();
            },
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: [primaryColor],
    },
    labels: [""],
  };

  return (
    <ChartContainer>
      <Chart
        type="radialBar"
        options={options}
        series={series}
        height={height}
      />
      <Label>{label}</Label>
    </ChartContainer>
  );
};
