import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.objectives;

export const selectObjectives = createSelector(
  selectState,
  (state) => state.data
);

export const selectObjectivesList = createSelector(selectObjectives, (data) =>
  Object.values(data)
);

export const selectObjectiveByID = (id?: UUID) =>
  createSelector(selectObjectives, (data) => (id ? data[id] : undefined));
