import { useSelector } from "store";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { selectSolutionByID } from "store/reducers";
import { useModal } from "hooks/useModal";
import { UUID } from "types";
import { FlexContainer } from "components/shared/layouts";
import { PrimaryButton, WhiteButton } from "components/shared/buttons";
import { Sheet } from "components/shared/cms";

type SolutionModalProps = {
  solutionId: UUID;
  onSuccess?: () => void;
};

export const SolutionModal = ({
  solutionId,
  onSuccess,
}: SolutionModalProps) => {
  const { closeModal } = useModal();
  const solution = useSelector(selectSolutionByID(solutionId));

  return (
    <div className="p-4 h-screen w-screen flex flex-col items-center justify-center">
      <div className="inline-block flex flex-col align-bottom bg-white h-full w-full rounded-lg p-5 pb-4 text-left shadow-xl transform transition-all">
        <div className="flex flex-row justify-between item-center gap-10">
          <div />
          <div className="h-7">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={closeModal}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto max-w-2xl w-full px-12 mx-auto">
          {solution.sheetId && <Sheet disabled sheetId={solution.sheetId} />}
        </div>
        <FlexContainer justify="justify-end">
          <FlexContainer marginTop="mt-5" padding="p-1">
            <div className="mr-2">
              <WhiteButton onClick={closeModal}>Close</WhiteButton>
            </div>
            {onSuccess && (
              <PrimaryButton
                leadingIcon={CheckIcon}
                onClick={() => {
                  onSuccess();
                  closeModal();
                }}
              >
                Select solution
              </PrimaryButton>
            )}
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>
  );
};
