import classNames from "classnames";
import { Link, useParams } from "react-router-dom";

const mockTabs: ToolbarTab[] = [
  { name: "Summary", path: "summary" },
  { name: "Impact analysis", path: "impact" },
  { name: "Risks", path: "risks" },
  { name: "Cost analysis", path: "costs" },
  { name: "Datasets", path: "datasets" },
];

export type ToolbarTab = {
  name: string;
  path: string;
};

type MockAppToolbarProps = {
  tabs?: ToolbarTab[];
};

export const MockAppToolbar = ({ tabs = mockTabs }: MockAppToolbarProps) => {
  const { tabId } = useParams();
  const current = tabs.find((tab) => tab.path === tabId);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-pantone-500 focus:border-pantone-500 border-gray-300 rounded-md"
          defaultValue={current?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={`/apps/native/${tab.path}`}
              className={classNames(
                tab.path === current?.path
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.path === current?.path
                    ? "bg-pantone-700"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-1"
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};
