import { Button, GenericButtonProps } from "components/shared/buttons";

export type TransparentButtonProps = GenericButtonProps & {};

export const TransparentButton = ({
  background = "bg-transparent",
  ...otherProps
}: TransparentButtonProps) => {
  return (
    <Button
      background={background}
      textColor="text-gray-700"
      backgroundHover="hover:bg-zinc-100"
      ringFocusColor="focus:ring-transparent"
      shadow=""
      {...otherProps}
    />
  );
};
