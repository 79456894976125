import { useSelector } from "store";
import { selectObjectivesList } from "store/reducers";
import { ObjectivesTableRow } from "components/shared";

type ObjectivesTableProps = {};

export const ObjectivesTable = (props: ObjectivesTableProps) => {
  const objectivesList = useSelector(selectObjectivesList);

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Core Metric
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {objectivesList.map((objective) => (
          <ObjectivesTableRow key={objective.id} objectiveId={objective.id} />
        ))}
      </tbody>
    </table>
  );
};
