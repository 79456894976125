import { createSlice } from "@reduxjs/toolkit";
import { Metric, UUID } from "types";
import { createMetric, fetchMetrics, updateMetric } from "store/reducers";

export type MetricsReducerStateData = Record<UUID, Metric>;

export interface MetricsReducerState {
  data: MetricsReducerStateData;
}

const initialState: MetricsReducerState = {
  data: {},
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: MetricsReducerStateData, value: Metric) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(createMetric.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateMetric.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      });
  },
});

export const metricsReducer = metricsSlice.reducer;
