import { ChatMessage } from "features/conversations/types";
import {
  ButtonGroup,
  PrimaryButton,
  GrayButton,
  Text,
  GrayPill,
} from "components/shared";
import { useDecisionChat } from "features/decisions/hooks";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { useModal } from "hooks/useModal";
import { ConfirmModal } from "components/modals";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { stringify } from "query-string";

const Pill = styled(GrayPill)`
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
`;

const ListItemText = styled.p`
  margin-bottom: 0.5rem;
`;

const List = styled.ol`
  list-style: decimal;
  padding-left: 1rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

export type DecisionModelReviewChatMessageProps = {
  message: ChatMessage;
};

export const DecisionModelReviewChatMessage = ({
  message,
}: DecisionModelReviewChatMessageProps) => {
  const { openModal } = useModal();
  const { useCase, decision, regenerateDecision } = useDecisionChat();
  if (!decision) return null;

  const formatDecision = (): ReactNode => {
    const sections = [
      <p>
        <strong>Decision:</strong> {decision.decision}
      </p>,
      <br />,
      <strong>Objectives:</strong>,
      <br />,
      <List>
        {decision.outcomes.map((text) => (
          <li key={text}>{text}</li>
        ))}
      </List>,
      <br />,
      <strong>Intermediates:</strong>,
      <br />,
      <List>
        {decision.metrics.map((value, index) => (
          <li key={index}>
            <ListItemText>{value.intermediate}</ListItemText>
            {value.metric && <Pill label={`📊 ${value.metric.name}`} />}
          </li>
        ))}
      </List>,
      <br />,
      <strong>Actions:</strong>,
      <br />,
      <List>
        {decision.actions.map((value, index) => (
          <li key={index}>
            <ListItemText>{value.action}</ListItemText>
            <div className="flex flex-row gap-2 flex-wrap">
              {value.solutions.map((solution) => (
                <Pill key={solution.id} label={`💡 ${solution.name}`} />
              ))}
            </div>
          </li>
        ))}
      </List>,
      <br />,
      <strong>Externals:</strong>,
      <br />,
      <List>
        {decision.externals.map((text) => (
          <li key={text}>{text}</li>
        ))}
      </List>,
    ];

    return sections;
  };

  const regenerate = () => {
    openModal(
      <ConfirmModal
        description="Please confirm you want me to regenerate this decision model?"
        onConfirm={regenerateDecision}
      />
    );
  };

  const beginTracking = () => {
    if (!useCase?.url) return;
    openModal(
      <ConfirmModal
        title="Your being navigated outside Climate PI"
        description={
          <span>
            Are you sure you would like to open this decision in{" "}
            <strong>{useCase.url}</strong>?
          </span>
        }
        actionText="Continue"
        icon={ExternalLinkIcon}
        onConfirm={() => {
          const baseUrl =
            process.env.NODE_ENV === "development"
              ? "http://localhost:4000"
              : `https://${useCase.url}`;
          const url = `${baseUrl}/dashboard?decisionId=${decision.id}`;
          window.open(url, "_blank");
        }}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Text>{formatDecision()}</Text>
      <ButtonGroup className="flex-col">
        <PrimaryButton onClick={beginTracking}>
          Continue with decision
        </PrimaryButton>
        <GrayButton onClick={regenerate}>Regenerate</GrayButton>
      </ButtonGroup>
    </div>
  );
};
