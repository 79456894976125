import { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { SortableElement } from "react-sortable-hoc";

const DragHandleContainer = styled.div`
  display: none;
  position: absolute;
  align-items: center;
  height: 100%;
`;

const StyledDragItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 3rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  & ${DragHandleContainer} {
    display: flex;
    pointer-events: none;
    opacity: 0;
  }
  &:hover ${DragHandleContainer} {
    opacity: 1;
    pointer-events: all;
  }

  ${({ active }) =>
    active &&
    css`
      & [contenteditable]:empty:before {
        content: attr(placeholder);
        display: block;
        -webkit-text-fill-color: rgba(55, 53, 47, 0.4);
      }
    `}
`;

const DragItemContent = styled.div`
  flex: 1;
`;

interface DragItemProps {
  showSorting?: boolean;
  active: boolean;
  dragHandle?: ReactNode;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const DragItem = SortableElement(
  ({ active, children, dragHandle, className, onClick }: DragItemProps) => {
    return (
      <StyledDragItem active={active} className={className} onClick={onClick}>
        <DragHandleContainer>{dragHandle}</DragHandleContainer>
        <DragItemContent>{children}</DragItemContent>
      </StyledDragItem>
    );
  }
);
