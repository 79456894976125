import { CoreMetricsTable, FlexContainer } from "components/shared";

export const CoreMetricsSettingsPage = () => {
  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <FlexContainer justify="justify-between">
        <h1 className="text-3xl font-extrabold text-blue-gray-900 mb-10">
          Core Metrics
        </h1>
      </FlexContainer>
      <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-hidden">
        <CoreMetricsTable />
      </div>
    </div>
  );
};
