import { createSlice } from "@reduxjs/toolkit";
import { TBlock, TSheet, UUID } from "types";
import {
  fetchSheets,
  fetchSheetById,
  createSheet,
  deleteSheet,
  updateSheet,
} from "store/reducers/sheets/sheets.thunks";
import {
  fetchBlocks,
  fetchBlockById,
  createBlock,
  deleteBlock,
  updateBlock,
  updateBlocks,
} from "store/reducers/sheets/blocks.thunks";

export type SheetsReducerStateSheetsData = Record<UUID, TSheet>;
export type SheetsReducerStateBlocksData = Record<UUID, TBlock>;

export interface SheetsReducerState {
  sheets: SheetsReducerStateSheetsData;
  blocks: SheetsReducerStateBlocksData;
  focusedBlockID?: UUID;
}

const initialState: SheetsReducerState = {
  sheets: {},
  blocks: {},
  focusedBlockID: undefined,
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    focusBlock: (state, action) => {
      state.focusedBlockID = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSheets.fulfilled, (state, action) => {
        state.sheets = action.payload.reduce(
          (data: SheetsReducerStateSheetsData, value: TSheet) => ({
            ...data,
            [value.id]: value,
          }),
          state.sheets
        );
        state.blocks = action.payload.reduce(
          (data: SheetsReducerStateBlocksData, value: TSheet) => {
            const sheetBlocks = value.blocks.reduce(
              (data: SheetsReducerStateBlocksData, value: TBlock) => ({
                ...data,
                [value.id]: value,
              }),
              {}
            );
            return {
              ...data,
              ...sheetBlocks,
            };
          },
          state.blocks
        );
      })
      .addCase(fetchSheetById.fulfilled, (state, action) => {
        state.sheets[action.payload.id] = action.payload;
        state.blocks = action.payload.blocks.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => {
            return {
              ...data,
              [value.id]: value,
            };
          },
          state.blocks
        );
      })
      .addCase(createSheet.fulfilled, (state, action) => {
        state.sheets[action.payload.id] = action.payload;
        state.blocks = action.payload.blocks.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => {
            return {
              ...data,
              [value.id]: value,
            };
          },
          state.blocks
        );
      })
      .addCase(updateSheet.fulfilled, (state, action) => {
        state.sheets[action.payload.id] = action.payload;
        state.blocks = action.payload.blocks.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => {
            return {
              ...data,
              [value.id]: value,
            };
          },
          state.blocks
        );
      })
      .addCase(deleteSheet.fulfilled, (state, action) => {
        const entity = state.sheets[action.meta.arg];
        if (entity) {
          delete state.sheets[entity.id];
        }
        // TODO: Remove the sheet blocks?
      })
      .addCase(fetchBlocks.fulfilled, (state, action) => {
        state.blocks = action.payload.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => ({
            ...data,
            [value.id]: value,
          }),
          state.blocks
        );
      })
      .addCase(fetchBlockById.fulfilled, (state, action) => {
        state.blocks[action.payload.id] = action.payload;
      })
      .addCase(createBlock.fulfilled, (state, action) => {
        state.blocks[action.payload.id] = action.payload;
      })
      .addCase(updateBlocks.pending, (state, action) => {
        state.blocks = action.meta.arg.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => ({
            ...data,
            [value.id]: value,
          }),
          state.blocks
        );
      })
      .addCase(updateBlocks.fulfilled, (state, action) => {
        state.blocks = action.payload.reduce(
          (data: SheetsReducerStateBlocksData, value: TBlock) => ({
            ...data,
            [value.id]: value,
          }),
          state.blocks
        );
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        state.blocks[action.payload.id] = action.payload;
      })
      .addCase(deleteBlock.pending, (state, action) => {
        const entity = state.blocks[action.meta.arg];
        if (entity) {
          delete state.blocks[entity.id];
        }
      });
  },
});

export const { focusBlock } = cmsSlice.actions;

export const cmsReducer = cmsSlice.reducer;
