import { UUID } from "types";
import { useSelector } from "store";
import { selectCoreMetricByID } from "store/reducers";
import { LoadingIcon } from "components/shared/icons";
import { FlexContainer } from "components/shared/layouts";
import { CoreDashboardMetricPoliciesPopover } from "components/shared/features/coreDashboardMetrics/CoreDashboardMetricPoliciesPopover/CoreDashboardMetricPoliciesPopover";
import { CDMChart } from "components/shared/charts";

type CoreDashboardMetricProps = {
  coreMetricId: UUID;
};

export const CoreDashboardMetric = ({
  coreMetricId,
}: CoreDashboardMetricProps) => {
  const coreMetric = useSelector(selectCoreMetricByID(coreMetricId));

  if (!coreMetric) {
    return <LoadingIcon />;
  }

  return (
    <FlexContainer>
      <CoreDashboardMetricPoliciesPopover coreMetricId={coreMetricId}>
        <CDMChart
          label={coreMetric.name}
          value={coreMetric.value}
          max={coreMetric.max}
        />
      </CoreDashboardMetricPoliciesPopover>
    </FlexContainer>
  );
};
