import { useState } from "react";
import {
  FlexContainer,
  InputGroup,
  SelectProjectLocationMap,
  PrimaryButton,
  TextInput,
  Title,
  SecondaryButton,
  ButtonGroup,
  Heading,
} from "components/shared";
import { MockProblemsTable } from "components/shared/features/projects/MockProblemsTable/MockProblemsTable";
import { DelayRender } from "components/shared/layouts/DelayRender/DelayRender";
import { Problem, ProblemSolution } from "types/problem";
import { MockSolutionsTable } from "components/shared/features/projects/MockSolutionsTable/MockSolutionsTable";
import { BookOpenIcon } from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RoutePath } from "routes/RoutePath";

enum Step {
  LOCATION = "location",
  PROBLEM = "problem",
  SOLUTION = "solution",
}

type URLParams = { step: Step };

export const CreateProjectPage = () => {
  const navigate = useNavigate();
  const { step } = useParams() as URLParams;
  const [address, setAddress] = useState("Luxor, Egypt");
  const [problem, setProblem] = useState<Problem>();
  const [solutions, setSolutions] = useState<ProblemSolution[]>([]);

  const activeSolutions = () => {
    if (solutions.length === 0) {
      return;
    }
    const timescale = solutions[0].timescale;
    navigate(
      `/apps?action=select_app&solutionIds=${solutions
        .map(({ id }) => id)
        .join(",")}&objectiveId=${
        problem?.objectiveId
      }&timescale=${timescale}&direction=${problem?.direction}`
    );
  };

  return (
    <FlexContainer
      direction="flex-row"
      gap="gap-10"
      flex="flex-1"
      width="w-full"
    >
      <FlexContainer direction="flex-col" gap="gap-5" width="w-1/2">
        <div>
          <Heading>Create a new project</Heading>
          <Title>
            {step === Step.LOCATION && "Choose a location"}
            {step === Step.PROBLEM && "Location based problems"}
            {step === Step.SOLUTION && "Location based solutions"}
          </Title>
        </div>
        {step === Step.LOCATION && (
          <FlexContainer direction="flex-col" gap="gap-5" width="w-2/3">
            <InputGroup
              label="Choose a location on the map by using your mouse, or enter an address below"
              labelTextColor="text-white"
            >
              <TextInput
                name="address"
                value={address}
                placeholder="Enter a location address"
                onChange={(event: any) => setAddress(event.target.value)}
              />
            </InputGroup>
            <Link to={`${RoutePath.projects.create}/problem`}>
              <PrimaryButton width="">Search for problems</PrimaryButton>
            </Link>
          </FlexContainer>
        )}
        {step === Step.PROBLEM && (
          <DelayRender delay={2500}>
            <div>
              <InputGroup
                label="The following issues have been identified at this location:"
                labelTextColor="text-white"
              >
                <MockProblemsTable
                  initialValue="93da3b60-584a-4998-92a6-e46f2e5fc2ad"
                  value={problem}
                  onChange={setProblem}
                />
              </InputGroup>
              {problem && (
                <ButtonGroup>
                  <Link to={`${RoutePath.projects.create}/solution`}>
                    <PrimaryButton width="">Recommend solutions</PrimaryButton>
                  </Link>
                  <SecondaryButton width="">Case studies</SecondaryButton>
                </ButtonGroup>
              )}
            </div>
          </DelayRender>
        )}
        {step === Step.SOLUTION && (
          <DelayRender delay={2500}>
            <div>
              <InputGroup
                label="The following issues have been identified at this location:"
                labelTextColor="text-white"
              >
                <MockSolutionsTable value={solutions} onChange={setSolutions} />
              </InputGroup>
              {solutions && (
                <ButtonGroup>
                  <PrimaryButton
                    width=""
                    leadingIcon={BookOpenIcon}
                    onClick={activeSolutions}
                  >
                    Recommend apps
                  </PrimaryButton>
                  <SecondaryButton width="">Case studies</SecondaryButton>
                </ButtonGroup>
              )}
            </div>
          </DelayRender>
        )}
      </FlexContainer>
      <FlexContainer direction="flex-col" gap="gap-2" width="w-1/2">
        <div className="bg-white overflow-hidden shadow rounded-lg w-full">
          <div className="h-[60vh]">
            <SelectProjectLocationMap />
          </div>
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};
