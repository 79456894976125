import { createSlice } from "@reduxjs/toolkit";
import { Objective, UUID } from "types";
import {
  createObjective,
  fetchObjectives,
  updateObjective,
} from "store/reducers/objectives/objectives.thunks";

export type ObjectivesReducerStateData = Record<UUID, Objective>;

export interface ObjectivesReducerState {
  data: ObjectivesReducerStateData;
}

const initialState: ObjectivesReducerState = {
  data: {},
};

export const objectivesSlice = createSlice({
  name: "objectives",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchObjectives.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: ObjectivesReducerStateData, value: Objective) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(createObjective.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateObjective.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      });
  },
});

export const objectivesReducer = objectivesSlice.reducer;
