import { PrimaryButton } from "components/shared/buttons";

type InteractiveGlobeProps = {
  onClose?: () => void;
};

export const InteractiveGlobe = ({ onClose }: InteractiveGlobeProps) => {
  const src = "https://globe.peoplesmarts.earth";
  return (
    <>
      {onClose && (
        <div className="absolute bottom-3 left-3">
          <PrimaryButton onClick={onClose}>Close Planet</PrimaryButton>
        </div>
      )}
      <iframe
        title="interactive-globe"
        id="interactive-globe"
        src={src}
        width="100%"
        height="100%"
      />
    </>
  );
};
