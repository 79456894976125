import {
  TBlockType,
  TFileBlock,
  TImageBlock,
  TYoutubeBlock,
  UUID,
} from "types";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import {
  ColorSwatchIcon,
  DownloadIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  SortDescendingIcon,
  SwitchHorizontalIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import { useSheet } from "hooks/useSheet";
import { Label, Text } from "components/shared/typography";
import { deleteBlock, selectBlockByID, updateBlock } from "store/reducers";
import { useDispatch, useSelector } from "store";

type EditBlockMenuProps = {
  refElement?: Element | null;
  blockId: UUID;
  onCreateBlock?: (type: TBlockType) => void;
};

type Action = {
  label: string;
  icon: any;
  onClick?: () => void;
};

const MenuItem = ({ icon: Icon, label, onClick }: Action) => {
  return (
    <Menu.Item
      as="div"
      className="text-gray-900 group flex cursor-pointer rounded-md items-center w-full px-2 py-1 hover:bg-gray-100"
      onClick={onClick}
    >
      <Icon className="w-5 h-5 mr-2" aria-hidden="true" />
      <div>
        <Text textSize="text-sm" fontWeight="font-medium">
          {label}
        </Text>
      </div>
    </Menu.Item>
  );
};

export const EditBlockMenu = ({
  refElement,
  blockId,
  onCreateBlock,
}: EditBlockMenuProps) => {
  const dispatch = useDispatch();
  const { openSheetMenu, closeSheetMenu } = useSheet();
  const block = useSelector(selectBlockByID(blockId));
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: "right-start",
    strategy: "absolute",
  });
  const actions: Array<Array<Action> | boolean> = [
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: () => {
          dispatch(deleteBlock(blockId));
          closeSheetMenu();
        },
      },
      {
        label: "Duplicate",
        icon: DuplicateIcon,
      },
      {
        label: "Turn into",
        icon: SwitchHorizontalIcon,
      },
      {
        label: "Move to",
        icon: SortDescendingIcon,
      },
    ],
    block?.type === "text" && [
      {
        label: "Color",
        icon: ColorSwatchIcon,
      },
    ],
    block?.type === "image" && [
      {
        label: "Remove image",
        icon: XIcon,
        onClick: () => {
          closeSheetMenu();
          dispatch(
            updateBlock({
              ...block,
              properties: {
                url: "",
              },
            })
          );
        },
      },
      {
        label: "Download image",
        icon: DownloadIcon,
        onClick: () => {
          closeSheetMenu();
          const properties = block.properties as TImageBlock;
          if (properties.url) {
            window.open(properties.url, "_blank");
          }
        },
      },
    ],
    block?.type === "file" && [
      {
        label: "Remove file",
        icon: XIcon,
        onClick: () => {
          closeSheetMenu();
          dispatch(
            updateBlock({
              ...block,
              properties: {
                fileId: "",
                url: "",
              },
            })
          );
        },
      },
      {
        label: "Download file",
        icon: DownloadIcon,
        onClick: () => {
          closeSheetMenu();
          const properties = block.properties as TFileBlock;
          if (properties.url) {
            window.open(properties.url, "_blank");
          }
        },
      },
    ],
    block?.type === "youtube" && [
      {
        label: "Remove video",
        icon: XIcon,
        onClick: () => {
          closeSheetMenu();
          dispatch(
            updateBlock({
              ...block,
              properties: {
                url: "",
              },
            })
          );
        },
      },
      {
        label: "View original video",
        icon: ExternalLinkIcon,
        onClick: () => {
          closeSheetMenu();
          const properties = block.properties as TYoutubeBlock;
          if (properties.url) {
            window.open(properties.url, "_blank");
          }
        },
      },
    ],
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Items
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        static
        className="w-56 flex flex-col z-30 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 rounded-lg px-1 py-1"
      >
        <div className="px-2 my-1 leading-none">
          <Label textColor="text-gray-400" textSize="text-xs">
            BLOCK ACTIONS
          </Label>
        </div>
        <div className="flex flex-col gap-2 divide-y divide-gray-100">
          {actions.filter(Boolean).map((group, index) => {
            if (typeof group === "boolean") {
              return null;
            }
            return (
              <div key={`group_${index}`} className="px-1 py-1">
                {group.map(({ icon, label, onClick }) => (
                  <MenuItem
                    key={label}
                    icon={icon}
                    label={label}
                    onClick={onClick}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Menu.Items>
    </Menu>
  );
};
