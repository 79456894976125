import { useState } from "react";
import styled from "styled-components";
import { TBlock, TBlockType, TTextBlock } from "types";
import { EditableText } from "components/shared/cms";
import { useDispatch } from "store";
import { focusBlock, updateBlock } from "store/reducers";
import { useSheet } from "hooks/useSheet";

const Flex = styled.div`
  display: flex;
`;

export type TextBlockProps = {
  block: TBlock;
  focused: boolean;
  tag?: string;
  onAddBlock?: (type: TBlockType) => void;
  onRemoveBlock?: () => void;
  className?: string;
};

export const TextBlock = ({
  block,
  focused,
  tag = "div",
  onAddBlock,
  onRemoveBlock,
  className,
}: TextBlockProps) => {
  const dispatch = useDispatch();
  const { disabled } = useSheet();
  const properties = block.properties as TTextBlock;
  const [value, setValue] = useState<string>(properties.value);

  const handleBlur = (text: string) => {
    if (text !== properties.value) {
      dispatch(
        updateBlock({
          ...block,
          properties: {
            ...block.properties,
            value: text,
          },
        })
      );
    }
  };

  const handleFocus = () => {
    dispatch(focusBlock(block.id));
  };

  return (
    <Flex>
      <EditableText
        focused={focused}
        disabled={disabled}
        value={disabled ? (value === "" ? " " : value) : value}
        className={className}
        tag={tag}
        onChange={setValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onAddBlock={onAddBlock}
        onRemoveBlock={onRemoveBlock}
      />
    </Flex>
  );
};
