import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.simulations;

export const selectSimulations = createSelector(
  selectState,
  (state) => state.data
);

export const selectSimulationsList = createSelector(selectSimulations, (data) =>
  Object.values(data)
);

export const selectSimulationByID = (id: UUID) =>
  createSelector(selectSimulations, (data) => data[id]);

export const selectSimulationByPolicyID = (id: UUID) =>
  createSelector(selectSimulationsList, (data) =>
    data.find((simulation) => simulation.policyId === id)
  );

export const selectSimulationProgress = (id: UUID) =>
  createSelector(selectSimulationByID(id), (simulation) =>
    simulation ? (simulation.eventsProcessed * 100) / simulation.eventCount : 0
  );
