import { createSlice } from "@reduxjs/toolkit";
import { Policy, UUID } from "types";
import {
  createPolicy,
  deletePolicy,
  fetchPolicies,
  fetchPolicyByID,
  updatePolicy,
} from "store/reducers";

export type PoliciesReducerStateData = Record<UUID, Policy>;

export interface PoliciesReducerState {
  data: PoliciesReducerStateData;
}

const initialState: PoliciesReducerState = {
  data: {},
};

export const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPolicies.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (data: PoliciesReducerStateData, value: Policy) => ({
            ...data,
            [value.id]: value,
          }),
          state.data
        );
      })
      .addCase(fetchPolicyByID.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createPolicy.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updatePolicy.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deletePolicy.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const policiesReducer = policiesSlice.reducer;
