import dayjs from "dayjs";
import { Policy } from "types";
import { useSelector } from "store";
import { selectObjectiveByID } from "store/reducers";
import classNames from "classnames";

export type PolicySentenceAppearance = "first-person" | "factual" | "compact";

type PolicySentenceProps = {
  policy: Policy;
  appearance?: PolicySentenceAppearance;
  capitalize?: boolean;
};

export const PolicySentence = ({
  policy,
  capitalize,
  appearance = "compact",
}: PolicySentenceProps) => {
  const objective = useSelector(selectObjectiveByID(policy.objectiveId));

  const getNumberOfMonths = (): number => {
    const endDate = dayjs(policy.dateEnd);
    return endDate.diff(dayjs(), "month");
  };

  if (!objective) {
    return null;
  }

  return (
    <span>
      {appearance === "first-person" && <span>I want to </span>}
      <b className={classNames(capitalize && "capitalize")}>
        {policy.deltaDirection === "increase"
          ? "increase"
          : policy.deltaDirection === "maintain"
          ? "maintain"
          : "decrease"}
      </b>{" "}
      <b>{objective.name}</b> by{" "}
      <b>
        {policy.deltaAmount}
        {policy.deltaUnit === "percent" ? "%" : ""}
      </b>{" "}
      over the next <b>{getNumberOfMonths()} months</b>
    </span>
  );
};
