import { CDMChart } from "components/shared/charts/CDMChart/CDMChart";
import { useSelector } from "store";
import {
  selectCoreMetricsList,
  selectKeyPerformanceMetricsList,
} from "store/reducers";
import { CoreMetric } from "types";
import { LoadingIcon } from "components/shared/icons";
import { CoreDashboardMetricPoliciesPopover } from "components/shared/features/coreDashboardMetrics/CoreDashboardMetricPoliciesPopover/CoreDashboardMetricPoliciesPopover";

export const WellbeingDashboardMetric = () => {
  return (
    <CoreDashboardMetricPoliciesPopover coreMetricId="00000000-0000-0000-0000-000000000000">
      <CDMChart
        hideSign
        label="Human Wellness Index"
        value={737}
        max={1000}
        divide={1000}
        primaryColor="#008f98"
      />
    </CoreDashboardMetricPoliciesPopover>
  );
};
