import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { Policy, UUID } from "types";

const selectState = (state: RootStore) => state.policies;

export const selectPolicies = createSelector(
  selectState,
  (state) => state.data
);

export const selectPoliciesList = createSelector(selectPolicies, (data) =>
  Object.values(data).sort(
    (a: Policy, b: Policy) =>
      new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
  )
);

export const selectPolicyByID = (id: UUID) =>
  createSelector(selectPolicies, (data) => data[id]);
