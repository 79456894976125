import Cookies from "universal-cookie";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";
import store from "store";
import { logout } from "store/reducers";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  const response = error.response;
  const isDisabled =
    response?.status === 400 &&
    response.data?.message === "Your account has been disabled";
  if (response?.status === 401 || isDisabled) {
    store.dispatch(logout());
  }
  return Promise.reject(error);
});

export class API {
  static hostname = process.env["REACT_APP_BACKEND_API_URL"];

  static async GET<K, T = any>(
    url: string,
    queryParams?: T,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const response = await axios.get<K>(this.formatURL(url), {
      ...this.makeConfig(config),
      params: queryParams,
    });
    return response.data;
  }

  static async POST<T, K>(
    url: string,
    request: T,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const response = await axios.post<K>(
      this.formatURL(url),
      request,
      this.makeConfig(config)
    );
    return response.data;
  }

  static async PUT<T, K>(
    url: string,
    request: T,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const response = await axios.put<K>(
      this.formatURL(url),
      request,
      this.makeConfig(config)
    );
    return response.data;
  }

  static async DELETE<K>(url: string, config?: AxiosRequestConfig): Promise<K> {
    const response = await axios.delete<K>(
      this.formatURL(url),
      this.makeConfig(config)
    );
    return response.data;
  }

  private static makeConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      withCredentials: true,
      headers: { ...this.makeHeaders(), ...config?.headers },
    };
  }

  private static makeHeaders(): AxiosRequestHeaders {
    return {
      Authorization: this.getAuthTokenFromCookies(),
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  private static formatURL(value: string): string {
    if (value.length && value[0] !== "/") {
      value = "/" + value;
    }
    return this.hostname + value;
  }

  private static getAuthTokenFromCookies(): string {
    const cookies = new Cookies();
    return cookies.get("session");
  }
}
