import { Button, GenericButtonProps } from "components/shared/buttons";

export type PrimaryButtonProps = GenericButtonProps & {};

export const PrimaryButton = ({ ...otherProps }: PrimaryButtonProps) => {
  return (
    <Button
      background="bg-green-500"
      textHoverColor="hover:text-white"
      backgroundHover="hover:bg-green-600"
      ringFocusColor="focus:ring-green-600"
      {...otherProps}
    />
  );
};
