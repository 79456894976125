import { useEffect, useState } from "react";
import { RefreshIcon } from "@heroicons/react/outline";
import { UUID } from "types";
import { Problem } from "types/problem";
import { GreenPill, OrangePill, RedPill } from "components/shared/pills";
import { LoadingIcon, Text } from "components/shared";

type MockProblemsTableProps = {
  initialValue?: UUID;
  value?: Problem;
  onChange?: (problem?: Problem) => void;
};

export const MockProblemsTable = ({
  initialValue,
  value,
  onChange,
}: MockProblemsTableProps) => {
  const [problems, setProblems] = useState<Problem[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setProblems([]);
    setLoading(true);
    const response = await fetch(
      "https://gist.githubusercontent.com/vincentracine/f6dd91abc054570b5a5b92eb49302ec2/raw/pi-problems.json"
    );
    const data = await response.json();
    if (initialValue) {
      const item = data.find((entity: Problem) => entity.id === initialValue);
      if (onChange) {
        onChange(item);
      }
    }
    await Promise.all(
      data.map((entity: Problem, i: number) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            setProblems((prevState = []) => [...prevState, entity]);
            resolve(null);
          }, Math.random() * 500 * i);
        });
      })
    );
    setLoading(false);
  };

  if (!problems) {
    return <LoadingIcon />;
  }

  return (
    <>
      <table className="text-left text-sm">
        <thead>
          <tr>
            <th className="pl-6 py-3 text-xs font-medium text-white uppercase tracking-wider" />
            <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
              Statement
            </th>
            <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
              Impact
            </th>
            <th className="px-2 py-3 text-xs font-medium text-white uppercase tracking-wider">
              Details
            </th>
          </tr>
        </thead>
        <tbody>
          {problems.map((problem) => {
            const { name, impact, problemUrl } = problem;
            const isSelected = value?.id === problem.id;
            return (
              <tr key={problem.id}>
                <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                  {isSelected && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-pantone-600" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-pantone-600 focus:ring-pantone-500 sm:left-6"
                    value={problem.id}
                    checked={isSelected}
                    onChange={() =>
                      onChange && onChange(isSelected ? undefined : problem)
                    }
                  />
                </td>

                <td
                  className="pl-1 py-1 cursor-pointer whitespace-nowrap hover:text-pantone-600"
                  onClick={() =>
                    onChange && onChange(isSelected ? undefined : problem)
                  }
                >
                  {name}
                </td>
                <td className="px-2 py-1">
                  {impact === "low" ? (
                    <GreenPill label="Low" />
                  ) : impact === "medium" ? (
                    <OrangePill label="Mod" />
                  ) : (
                    <RedPill label="High" />
                  )}
                </td>
                <td className="px-2 py-1">
                  <a
                    href={problemUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer whitespace-nowrap"
                  >
                    More info
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading ? (
        <div className="ml-5 mt-2">
          <LoadingIcon />
        </div>
      ) : (
        <div className="mt-2 flex flex-row gap-1 cursor-pointer whitespace-nowrap hover:text-pantone-600">
          <RefreshIcon className="w-5 h-5" />
          <Text>Identify more problems</Text>
        </div>
      )}
    </>
  );
};
