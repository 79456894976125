import { Outlet } from "react-router-dom";
import { SettingsSidebar } from "components/shared";

export const AppSettingsLayout = () => {
  return (
    <>
      <header className="py-10">
        <h1 className="text-3xl font-bold text-white">Configuration</h1>
      </header>

      <div className="bg-white rounded-lg shadow min-h-50">
        <main className="flex-1 flex">
          <div className="flex-1 flex overflow-auto">
            <SettingsSidebar />
            <div className="flex-1 px-5">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
