import { SortableContainer } from "react-sortable-hoc";
import styled from "styled-components";

const StyledDragContainer = styled.div`
  display: flex;
  width: calc(100% + 3rem);
  flex-direction: column;
  margin-left: -3rem;
`;

export const DragContainer = SortableContainer(
  ({ className, children, handleSortEnd, ...otherProps }: any) => (
    <StyledDragContainer {...otherProps} className={className}>
      {children}
    </StyledDragContainer>
  )
);
