import { configureStore } from "@reduxjs/toolkit";
import reducer from "store/reducer";

const createStore = (initialState = {}) => {
  return configureStore({
    reducer,
    preloadedState: initialState,
  });
};

export default createStore;
