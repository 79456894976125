import { forwardRef, ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindBackgroundColor,
  TailwindBackgroundHoverColor,
  TailwindBorderColor,
  TailwindFontWeight,
  TailwindHeight,
  TailwindLayout,
  TailwindPadding,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindRingColor,
  TailwindRingFocusColor,
  TailwindRounding,
  TailwindShadow,
  TailwindTextColor,
  TailwindTextHoverColor,
  TailwindTextSize,
  TailwindTextTransform,
  TailwindWidth,
} from "types/tailwind";

export type GenericButtonProps = {
  id?: string;
  type?: "button" | "submit";
  children?: ReactNode;
  disabled?: boolean;
  leadingIcon?: any;
  trailingIcon?: any;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  width?: TailwindWidth;
  height?: TailwindHeight;
  padding?: TailwindPadding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  textColor?: TailwindTextColor;
  textHoverColor?: TailwindTextHoverColor;
  fontWeight?: TailwindFontWeight;
  textTransform?: TailwindTextTransform;
  borderColor?: TailwindBorderColor;
  textSize?: TailwindTextSize;
  background?: TailwindBackgroundColor;
  backgroundHover?: TailwindBackgroundHoverColor;
  ringColor?: TailwindRingColor;
  ringFocusColor?: TailwindRingFocusColor;
  shadow?: TailwindShadow;
  layout?: TailwindLayout;
};

export type ButtonProps = GenericButtonProps & {};

export const Button = forwardRef(
  (
    {
      width = "w-full",
      layout = "inline-flex",
      rounding = "rounded-3xl",
      textColor = "text-white",
      textHoverColor = "",
      borderColor = "border-transparent",
      textSize = "text-base",
      fontWeight = "font-normal",
      height = "",
      type = "button",
      padding = "",
      paddingY = "py-3",
      paddingX = "px-4",
      background = "bg-blue-400",
      backgroundHover = "hover:bg-blue-500",
      ringColor = "ring-transparent",
      ringFocusColor = "focus:ring-blue-500",
      textTransform = "",
      shadow = "",
      children,
      id,
      disabled = false,
      onClick,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      ...otherProps
    }: ButtonProps,
    ref: any
  ) => {
    const classes = classNames(
      "items-center justify-center border whitespace-nowrap ring-2 focus:ring-2 focus:ring-offset-2",
      layout,
      width,
      height,
      padding,
      rounding,
      textColor,
      textHoverColor,
      textSize,
      fontWeight,
      borderColor,
      background,
      backgroundHover,
      ringColor,
      ringFocusColor,
      padding,
      paddingX,
      paddingY,
      shadow,
      textTransform
    );
    return (
      <button
        {...otherProps}
        ref={ref}
        type={type}
        id={id}
        className={classes}
        onClick={onClick}
        disabled={disabled}
      >
        {LeadingIcon && <LeadingIcon className="-ml-1 mr-2 h-5 w-5" />}
        {children}
        {TrailingIcon && <TrailingIcon className="ml-2 -mr-1 h-5 w-" />}
      </button>
    );
  }
);
