import { Outlet } from "react-router-dom";

type AuthLayoutProps = {};

export const AuthLayout = (props: AuthLayoutProps) => {
  return (
    <div {...props} className="bg-ocean-900">
      <Outlet />
    </div>
  );
};
