import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateSheetRequest,
  DataResponse,
  GetSheetResponse,
  GetSheetsResponse,
  TSheet,
  UpdateSheetRequest,
  UUID,
} from "types";
import { mapBlock } from "store/reducers/sheets/blocks.thunks";
import { API } from "lib/API";

const mapSheet = (sheet: TSheet): TSheet => ({
  ...sheet,
  blocks: sheet.blocks.map(mapBlock),
});

export const fetchSheets = createAsyncThunk(
  "sheets/list",
  async (): Promise<TSheet[]> => {
    const { data } = await API.GET<GetSheetsResponse>("/sheets", {
      pageLimit: 200,
    });
    return data.map(mapSheet);
  }
);

export const fetchSheetById = createAsyncThunk(
  "sheets/getById",
  async (id: UUID): Promise<TSheet> => {
    const { data } = await API.GET<GetSheetResponse>(`/sheets/${id}`);
    return mapSheet(data);
  }
);

export const createSheet = createAsyncThunk(
  "sheets/create",
  async (request: CreateSheetRequest): Promise<TSheet> => {
    const { data } = await API.POST<any, DataResponse<TSheet>>("/sheets", {
      ...request,
      blocks: request.blocks.map((block) => ({
        ...block,
        properties: JSON.stringify(block.properties),
      })),
    });
    return mapSheet(data);
  }
);

export const updateSheet = createAsyncThunk(
  "sheets/update",
  async (request: UpdateSheetRequest): Promise<TSheet> => {
    const { data } = await API.POST<any, DataResponse<TSheet>>(
      `/sheets/${request.id}`,
      {
        ...request,
        blocks: request.blocks.map((block) => ({
          ...block,
          properties: JSON.stringify(block.properties),
        })),
      }
    );
    return mapSheet(data);
  }
);

export const deleteSheet = createAsyncThunk(
  "sheets/delete",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/sheets/${id}`);
  }
);
