import { createSlice } from "@reduxjs/toolkit";
import { Simulation, UUID } from "types";
import { fetchSimulations } from "store/reducers";

export type SimulationsReducerStateData = Record<UUID, Simulation>;

export interface SimulationsReducerState {
  data: SimulationsReducerStateData;
}

const initialState: SimulationsReducerState = {
  data: {},
};

export const simulationsSlice = createSlice({
  name: "simulations",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSimulations.fulfilled, (state, action) => {
      state.data = action.payload.reduce(
        (data: SimulationsReducerStateData, value: Simulation) => ({
          ...data,
          [value.id]: value,
        }),
        state.data
      );
    });
  },
});

export const simulationsReducer = simulationsSlice.reducer;
