import { ReactElement, ReactNode, useEffect, useState } from "react";
import { LoadingIcon } from "components/shared/icons";
import { TailwindTextColor } from "types/tailwind";

type DelayRenderProps = {
  delay?: number;
  textColor?: TailwindTextColor;
  children: ReactElement | ReactElement[];
};

export const DelayRender = ({
  delay = 1000,
  textColor,
  children,
}: DelayRenderProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const id = setTimeout(() => {
      setLoading(false);
    }, delay);
    return () => clearTimeout(id);
  }, []);

  if (loading) {
    return <LoadingIcon textColor={textColor} />;
  }

  return children as JSX.Element;
};
