import React, {
  createContext,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Transition } from "@headlessui/react";
import { Portal } from "@reach/portal";

type SheetProviderProps = {
  disabled: boolean;
  children: ReactNode;
};

type SheetContextProperties = {
  disabled: boolean;
  openSheetMenu: (menu: ReactNode) => void;
  closeSheetMenu: () => void;
};

export const SheetContext = createContext<SheetContextProperties>({
  disabled: false,
  openSheetMenu: () => {},
  closeSheetMenu: () => {},
});

type State = {
  menu: ReactNode;
};

export const SheetProvider = ({ children, disabled }: SheetProviderProps) => {
  const [state, setState] = useState<State>({
    menu: undefined,
  });

  const openSheetMenu = (menu: ReactNode): void => {
    setState((prevState) => ({ ...prevState, menu }));
  };

  const closeSheetMenu = (): void => {
    setState((prevState) => ({ ...prevState, menu: undefined }));
  };

  return (
    <SheetContext.Provider
      value={{
        disabled: disabled,
        openSheetMenu,
        closeSheetMenu,
      }}
    >
      {children}
      {state.menu && (
        <Portal>
          <Transition.Root appear show as="div">
            <div
              onClick={closeSheetMenu}
              className="fixed min-h-screen z-0 inset-0 opacity-0 transition-opacity"
            />
            <div className="z-30">{state.menu}</div>
          </Transition.Root>
        </Portal>
      )}
    </SheetContext.Provider>
  );
};
