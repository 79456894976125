import { Outlet } from "react-router-dom";
import { Text, Title } from "components/shared";
import { AutoDismissFlags } from "components/popups";

export const DashboardLayout = () => {
  return (
    <>
      <AutoDismissFlags key="layoutFlags" />
      <div className="h-min-screen bg-ocean-900 hidden lg:flex relative pt-5 pb-40">
        <main className="flex flex-1">
          <div className="w-full min-h-screen max-w-xl lg:max-w-7xl mx-auto pb-12 px-8">
            <Outlet />
          </div>
        </main>
      </div>
      <div className="h-screen lg:hidden bg-ocean-900 flex flex-col text-center justify-center">
        <Title textColor="text-white">We're not mobile ready just yet</Title>
        <Text textColor="text-white">
          Please try to increase your browser size or using a desktop computer.
        </Text>
      </div>
    </>
  );
};
