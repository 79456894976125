import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import classNames from "classnames";
import { ComponentAppearance, Direction } from "types";
import { LineChart, GreenPill, RedPill } from "components/shared";
dayjs.extend(localizedFormat);

type PolicyTableRowProps = {
  name: string;
  direction: Direction;
  onTrack: boolean;
  appearance?: ComponentAppearance;
};

export const MockPolicyTableRow = ({
  name,
  direction,
  onTrack,
  appearance = "full",
}: PolicyTableRowProps) => {
  const renderChart = () => {
    return (
      <td
        className={classNames(
          appearance === "compact" ? "px-1 pl-0" : "px-6",
          "text-sm text-gray-500 font-medium"
        )}
      >
        <div className="flex items-center space-x-2">
          <div className="flex flex-shrink-0 -space-x-1 w-24">
            <LineChart
              appearance={appearance}
              decreasing={direction === "decrease"}
            />
          </div>
        </div>
      </td>
    );
  };
  return (
    <tr>
      {renderChart()}
      <td
        className={classNames(
          appearance === "compact" ? "px-1 pl-0" : "px-6 py-3",
          "max-w-0 w-full whitespace-pre-line text-sm"
        )}
      >
        <div className="flex flex-col space-y-1">
          <div>
            {onTrack ? (
              <GreenPill label="On track" />
            ) : (
              <RedPill label="Failing" />
            )}
          </div>
          <span>{name}</span>
        </div>
      </td>
    </tr>
  );
};
