import { TransparentButton } from "components/shared/buttons";
import { CursorClickIcon } from "@heroicons/react/outline";

type CoreMetricSelectorButtonProps = {
  placeholder?: string;
  label?: string;
  open: boolean;
  onClick?: () => void;
};

export const CoreMetricSelectorButton = ({
  placeholder = "Add another",
  label,
  open,
  onClick,
}: CoreMetricSelectorButtonProps) => {
  return (
    <TransparentButton
      onClick={onClick}
      width=""
      textSize="text-sm"
      leadingIcon={CursorClickIcon}
      background={open ? "bg-zinc-100" : undefined}
    >
      {label || placeholder}
    </TransparentButton>
  );
};
