import { useState } from "react";
import styled from "styled-components";
import cuid from "cuid";
import ReactTooltip from "react-tooltip";
import { Text } from "components/shared/typography";

const Bar = styled.div<{ value: number }>`
  position: relative;
  width: 7rem;
  height: 1rem;
  border-radius: 0.25rem;

  &:after {
    display: block;
    position: absolute;
    content: "";
    height: 1.5rem;
    width: 0.125rem;
    top: -0.25rem;
    border-radius: 0.25rem;
    left: ${({ value }) => value}%;
    background-color: rgb(234 179 8);
  }
`;

const Tooltip = styled(ReactTooltip)`
  display: flex !important;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: rgb(51, 65, 85) !important;
  padding: 0.5rem 0.75rem !important;
  align-items: center;
  justify-content: left;
`;

type AppImpactBarProps = {
  value: number;
  effect?: string;
};

export const AppImpactBar = ({
  value,
  effect = "impact",
}: AppImpactBarProps) => {
  const [id] = useState(cuid());
  const backgroundColor =
    value > 50 ? "bg-green-400" : value > 20 ? "bg-green-100" : "bg-gray-200";

  return (
    <>
      <Bar data-tip data-for={id} value={value} className={backgroundColor} />
      <Tooltip id={id} place="top" effect="solid" arrowColor="rgb(51, 65, 85)">
        <Text>
          {value > 50
            ? `High ${effect}`
            : value > 20
            ? `Moderate ${effect}`
            : `Low ${effect}`}
        </Text>
      </Tooltip>
    </>
  );
};
