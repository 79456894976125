import { TBlockType, UUID } from "types";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import {
  LinkIcon,
  PaperClipIcon,
  PencilIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { useSheet } from "hooks/useSheet";
import { Label, Text } from "components/shared/typography";

type CreateBlockMenuProps = {
  refElement?: Element | null;
  onCreateBlock?: (type: TBlockType) => void;
};

type Action = {
  label: string;
  description: string;
  icon: any;
  type: TBlockType;
  onClick?: () => void;
};

const MenuItem = ({
  icon: Icon,
  label,
  description,
  onClick,
}: Omit<Action, "type">) => {
  return (
    <div
      className="text-gray-900 group flex cursor-pointer rounded-md items-center w-full px-2 py-1 hover:bg-gray-100"
      onClick={onClick}
    >
      <Icon className="w-5 h-5 mr-2" aria-hidden="true" />
      <div>
        <Text textSize="text-sm" fontWeight="font-medium">
          {label}
        </Text>
        <Text textColor="text-gray-400" textSize="text-xs">
          {description}
        </Text>
      </div>
    </div>
  );
};

export const CreateBlockMenu = ({
  refElement,
  onCreateBlock,
}: CreateBlockMenuProps) => {
  const { closeSheetMenu } = useSheet();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: "right-start",
  });
  const actions: Action[] = [
    {
      label: "Text",
      description: "Just start writing with plain text",
      icon: PencilIcon,
      type: "text",
    },
    {
      label: "Heading 1",
      description: "Big section heading.",
      icon: PencilIcon,
      type: "header1",
    },
    {
      label: "Heading 2",
      description: "Medium section heading.",
      icon: PencilIcon,
      type: "header2",
    },
    {
      label: "Heading 3",
      description: "Small section heading.",
      icon: PencilIcon,
      type: "header3",
    },
    {
      label: "Callout",
      description: "Make writing stand out.",
      icon: PencilIcon,
      type: "notice",
    },
    {
      label: "Image",
      description: "Embed an image.",
      icon: PhotographIcon,
      type: "image",
    },
    {
      label: "Youtube",
      description: "Embed a youtube video.",
      icon: VideoCameraIcon,
      type: "youtube",
    },
    {
      label: "File",
      description: "Upload a file.",
      icon: PaperClipIcon,
      type: "file",
    },
  ];

  return (
    <Popover as="div" className="relative inline-block text-left">
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        static
        className="w-72 flex flex-col z-30 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 rounded-lg px-1 py-2"
      >
        <div className="px-2 my-1 leading-none">
          <Label textColor="text-gray-400" textSize="text-xs">
            BASIC BLOCKS
          </Label>
        </div>
        <div className="flex max-h-72 overflow-y-auto flex-col gap-2">
          {actions.map((action) => (
            <MenuItem
              key={action.label}
              label={action.label}
              description={action.description}
              icon={action.icon}
              onClick={() => {
                onCreateBlock && onCreateBlock(action.type);
                closeSheetMenu();
              }}
            />
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
};
