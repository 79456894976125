import { ReactNode } from "react";
import classNames from "classnames";

type ButtonGroupProps = {
  className?: string;
  children: ReactNode;
};

export const ButtonGroup = ({ className, children }: ButtonGroupProps) => {
  return <div className={classNames("flex gap-2", className)}>{children}</div>;
};
