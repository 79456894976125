import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import dayjs from "dayjs";
import { XCircleIcon } from "@heroicons/react/outline";
import { UUID } from "types";
import { useSelector } from "store";
import {
  selectSimulationByPolicyID,
  selectSimulationProgress,
} from "store/reducers";
import { Header, Heading, LoadingIcon, Text } from "components/shared";

const SimulationBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Track = styled.div<{ progress: number }>`
  flex: 1;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 0.5rem;
  width: 100%;

  &:before,
  &:after {
    display: block;
    position: absolute;
    height: 0.5rem;
    border-radius: 0.25rem;
    left: 0;
    right: 0;
    top: 0.75rem;
    background: rgb(229, 231, 235);
    content: "";
    transition: width 100ms;
  }
  &:after {
    width: ${({ progress }) => progress}%;
    background: currentColor;
  }
`;

const StopIconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

const GlobeWrapper = styled.div`
  margin-right: 0.75rem;
  background: rgb(30, 41, 59);
  padding: 0.75rem;
  border-radius: 0.5rem;
  text-align: center;
`;

const Tooltip = styled(ReactTooltip)`
  display: flex !important;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: rgb(51, 65, 85) !important;
  padding: 0.5rem 0.75rem !important;
  align-items: center;
  justify-content: left;
`;

type PolicySimulationBarProps = {
  policyId: UUID;
};

export const PolicySimulationBar = ({ policyId }: PolicySimulationBarProps) => {
  const simulation = useSelector(selectSimulationByPolicyID(policyId));
  const progress = useSelector(selectSimulationProgress(simulation?.id || ""));

  if (!simulation || simulation.status === "done") {
    return null;
  }

  if (simulation.status === "generating") {
    return (
      <SimulationBar>
        <LoadingIcon textColor="text-current" />
        <Text textColor="text-current">Warming up policy</Text>
      </SimulationBar>
    );
  }

  return (
    <>
      <SimulationBar>
        <Track
          progress={progress}
          data-tip
          data-for={simulation.id}
          className="text-pantone-600"
        />
        <StopIconWrapper className="text-gray-400">
          <XCircleIcon />
        </StopIconWrapper>
      </SimulationBar>
      <Tooltip
        id={simulation.id}
        className="w-[22rem]"
        place="top"
        effect="solid"
        arrowColor="rgb(51, 65, 85)"
      >
        <GlobeWrapper>
          <Header textColor="text-white">{progress}%</Header>
          <Text textColor="text-slate-400" textSize="text-xs">
            Progress
          </Text>
        </GlobeWrapper>
        <div>
          <Heading textColor="text-white">Active simulation</Heading>
          <Text textColor="text-slate-300">
            Start date: {dayjs(simulation.dateCreated).format("LL")}
          </Text>
          <Text textColor="text-slate-300">
            Current date: {dayjs(simulation.currentDate).format("LL")}
          </Text>
        </div>
      </Tooltip>
    </>
  );
};
