import { forwardRef, ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindAlignItems,
  TailwindBackgroundColor,
  TailwindBackgroundHoverColor,
  TailwindBorderColor,
  TailwindFlex,
  TailwindFlexDirection,
  TailwindGap,
  TailwindHeight,
  TailwindJustify,
  TailwindLayout,
  TailwindMargin,
  TailwindMarginBottom,
  TailwindMarginLeft,
  TailwindMarginRight,
  TailwindMarginTop,
  TailwindMarginX,
  TailwindMarginY,
  TailwindMinHeight,
  TailwindPadding,
  TailwindPaddingBottom,
  TailwindPaddingLeft,
  TailwindPaddingRight,
  TailwindPaddingTop,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindPointerEvents,
  TailwindPosition,
  TailwindShadow,
  TailwindWidth,
  TailwindZIndex,
} from "types/tailwind";

export type ContainerProps = {
  id?: string;
  layout?: TailwindLayout;
  direction?: TailwindFlexDirection;
  children?: ReactNode;
  onClick?: (event: any) => void;
  width?: TailwindWidth;
  height?: TailwindHeight;
  minHeight?: TailwindMinHeight;
  margin?: TailwindMargin;
  marginY?: TailwindMarginY;
  marginX?: TailwindMarginX;
  marginRight?: TailwindMarginRight;
  marginLeft?: TailwindMarginLeft;
  marginTop?: TailwindMarginTop;
  marginBottom?: TailwindMarginBottom;
  padding?: TailwindPadding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  paddingRight?: TailwindPaddingRight;
  paddingLeft?: TailwindPaddingLeft;
  paddingTop?: TailwindPaddingTop;
  paddingBottom?: TailwindPaddingBottom;
  borderColor?: TailwindBorderColor;
  background?: TailwindBackgroundColor;
  backgroundHover?: TailwindBackgroundHoverColor;
  shadow?: TailwindShadow;
  justify?: TailwindJustify;
  alignItems?: TailwindAlignItems;
  flex?: TailwindFlex;
  pointerEvents?: TailwindPointerEvents;
  zIndex?: TailwindZIndex;
  position?: TailwindPosition;
  gap?: TailwindGap;
  className?: string;
};

export const Container = forwardRef(
  (
    {
      layout = "block",
      position = "",
      direction = "",
      id,
      onClick,
      width,
      height,
      minHeight,
      margin,
      marginY,
      marginX,
      marginTop,
      marginBottom,
      marginRight,
      marginLeft,
      padding,
      paddingY,
      paddingX,
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
      borderColor,
      background,
      backgroundHover,
      shadow,
      justify,
      alignItems,
      flex,
      pointerEvents,
      zIndex,
      gap,
      children,
      className,
    }: ContainerProps,
    ref: any
  ) => {
    const classes = classNames(
      className,
      position,
      layout,
      direction,
      width,
      height,
      minHeight,
      margin,
      marginY,
      marginX,
      marginTop,
      marginBottom,
      marginRight,
      marginLeft,
      padding,
      paddingY,
      paddingX,
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
      borderColor,
      background,
      backgroundHover,
      shadow,
      justify,
      flex,
      pointerEvents,
      zIndex,
      alignItems,
      gap
    );
    return (
      <div id={id} ref={ref} className={classes} onClick={onClick}>
        {children}
      </div>
    );
  }
);
