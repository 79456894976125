import { ReactNode } from "react";
import classNames from "classnames";
import { TailwindTextColor } from "types/tailwind";

type InputGroupProps = {
  label?: string;
  labelTextColor?: TailwindTextColor;
  children?: ReactNode;
};

export const InputGroup = ({
  label,
  labelTextColor = "text-gray-700",
  children,
}: InputGroupProps) => {
  return (
    <div className="mb-5">
      {label && (
        <label
          htmlFor="email"
          className={classNames("block text-sm font-medium", labelTextColor)}
        >
          {label}
        </label>
      )}
      <div className="mt-2">{children}</div>
    </div>
  );
};
