import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataResponse, KeyResult } from "types";
import { API } from "lib/API";

export const fetchKeyResults = createAsyncThunk(
  "keyResults/list",
  async (): Promise<KeyResult[]> => {
    const { data } = await API.GET<DataResponse<KeyResult[]>>("/key-results", {
      pageLimit: 200,
    });
    return data;
  }
);
