import classNames from "classnames";
import { TailwindBackgroundColor, TailwindTextColor } from "types/tailwind";

export type PillProps = {
  label: string;
  textColor?: TailwindTextColor;
  backgroundColor?: TailwindBackgroundColor;
  className?: string;
};

export const Pill = ({
  label,
  textColor,
  backgroundColor,
  className,
}: PillProps) => {
  return (
    <div
      className={classNames(
        "px-2 inline-flex whitespace-nowrap text-xs leading-5 font-semibold rounded-full",
        className,
        textColor,
        backgroundColor
      )}
    >
      {label}
    </div>
  );
};
