import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.cms.sheets;

export const selectSheets = createSelector(selectState, (state) => state);

export const selectSheetsList = createSelector(selectSheets, (data) =>
  Object.values(data)
);

export const selectSheetByID = (id?: UUID) =>
  createSelector(selectSheets, (data) => (id ? data[id] : undefined));
