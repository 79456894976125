import { selectCoreMetricsList } from "store/reducers";
import { useSelector } from "store";
import {
  CoreDashboardMetric,
  WellbeingDashboardMetric,
} from "components/shared";

export const CoreDashboardMetrics = () => {
  const coreMetrics = useSelector(selectCoreMetricsList);
  return (
    <div
      className={`grid bg-ocean-900/90 lg:grid-cols-4 xl:grid-cols-${coreMetrics.length} rounded-md gap-2 px-4`}
    >
      <WellbeingDashboardMetric />
      {coreMetrics.map((metric) => (
        <CoreDashboardMetric key={metric.id} coreMetricId={metric.id} />
      ))}
    </div>
  );
};
