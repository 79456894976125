import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type ChartProps = {
  type:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
  height: number;
  series: Array<any>;
  options: ApexOptions;
  onMounted?: (ctx: any, config: any) => void;
  onClick?: (event: MouseEvent) => void;
};

export const Chart = ({
  type,
  height,
  series,
  options,
  onMounted,
  onClick,
}: ChartProps) => {
  const handleMounted = (ctx: any, config: any) => {
    ctx?.windowResizeHandler();
    onMounted && onMounted(ctx, config);
  };
  const newOptions: ApexOptions = {
    ...options,
    chart: {
      type,
      height,
      ...options,
      events: {
        mounted: handleMounted,
        click: onClick,
      },
    },
  };

  return (
    <ReactApexChart
      type={type}
      options={newOptions}
      series={series}
      height={height}
    />
  );
};
