import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetSimulationsResponse, Simulation } from "types";
import { API } from "lib/API";

export const fetchSimulations = createAsyncThunk(
  "simulations/list",
  async (): Promise<Simulation[]> => {
    const { data } = await API.GET<GetSimulationsResponse>("/simulations");
    return data;
  }
);
