export const DragIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    version="1.1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(96.000000, 28.000000)">
        <circle cx="45" cy="45" r="45" fill="currentColor" />
        <circle cx="274" cy="45" r="45" fill="currentColor" />
        <circle cx="45" cy="228" r="45" fill="currentColor" />
        <circle cx="274" cy="228" r="45" fill="currentColor" />
        <circle cx="45" cy="411" r="45" fill="currentColor" />
        <circle cx="274" cy="411" r="45" fill="currentColor" />
      </g>
    </g>
  </svg>
);
