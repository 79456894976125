import { UUID } from "types";
import { useSelector } from "store";
import { selectKeyPerformanceMetricByID } from "store/reducers";
import { LoadingIcon } from "components/shared/icons";
import { GreenPill, RedPill } from "components/shared/pills";
import dayjs, { Dayjs } from "dayjs";
import { Text } from "components/shared/typography";

type MetricIngestHistoryProps = {
  metricId: UUID;
};

export const MetricIngestHistory = ({ metricId }: MetricIngestHistoryProps) => {
  const entity = useSelector(selectKeyPerformanceMetricByID(metricId as UUID));
  const data = [
    {
      status: 200,
      time: dayjs(),
      oldValue: entity.value === 0 ? 0 : entity.value - 5,
      newValue: entity.value,
    },
    {
      status: 200,
      time: dayjs().subtract(1, "day"),
      oldValue: entity.value === 0 ? 0 : entity.value - 8,
      newValue: entity.value === 0 ? 0 : entity.value - 5,
    },
    {
      status: 400,
      time: dayjs().subtract(2, "day"),
      oldValue: "",
      newValue: "",
    },
    {
      status: 200,
      time: dayjs().subtract(3, "day") as Dayjs,
      oldValue: entity.value === 0 ? 0 : entity.value - 13,
      newValue: entity.value === 0 ? 0 : entity.value - 8,
    },
  ];

  if (!entity) {
    return <LoadingIcon />;
  }

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Time
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Old Value
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            New Value
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {data.map((item) => (
          <tr
            key={item.time.format("LL")}
            className="hover:bg-blue-50 hover:bg-opacity-50"
          >
            <td className="px-6 py-4 text-sm text-gray-900">
              {item.status >= 400 ? (
                <RedPill label={"Failed - " + item.status} />
              ) : (
                <GreenPill label={"OK - " + item.status} />
              )}
            </td>
            <td className="px-6 py-4 text-sm text-gray-900">
              <Text>{item.time.format("LL")}</Text>
            </td>
            <td className="px-6 py-4 text-sm text-gray-900">{item.oldValue}</td>
            <td className="px-6 py-4 text-sm text-gray-900">{item.newValue}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
