import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./List.module.scss";

type ListProps = {
  className?: string;
  children: ReactNode;
};

export const List = ({ children, className }: ListProps) => {
  return <ul className={classNames(styles.List, className)}>{children}</ul>;
};
