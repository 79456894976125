import { Pill, PillProps } from "components/shared";

type OrangePillProps = PillProps & {};

export const OrangePill = (props: OrangePillProps) => {
  return (
    <Pill
      {...props}
      textColor="text-amber-700"
      backgroundColor="bg-amber-300"
    />
  );
};
