import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { API } from "lib/API";
import { ErrorResponse, LoginRequest, LoginResponse } from "types";

export const login = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  {
    rejectValue: ErrorResponse;
  }
>("accounts/login", async (request, { rejectWithValue }) => {
  try {
    const { token } = await API.POST<LoginRequest, LoginResponse>(
      "/signin",
      request
    );
    return { token };
  } catch (err) {
    const error = err as AxiosError<ErrorResponse>;
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});
