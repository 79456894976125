import { UUID } from "types";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useSheet } from "hooks/useSheet";
import { Label } from "components/shared/typography";
import { selectBlockByID, updateBlock } from "store/reducers";
import { useDispatch, useSelector } from "store";
import { Popover } from "@headlessui/react";
import { TextInput } from "components/shared/inputs";
import { PrimaryButton } from "components/shared/buttons";

type YoutubeBlockMenuProps = {
  refElement?: Element | null;
  blockId: UUID;
};

export const YoutubeBlockMenu = ({
  refElement,
  blockId,
}: YoutubeBlockMenuProps) => {
  const dispatch = useDispatch();
  const { closeSheetMenu } = useSheet();
  const block = useSelector(selectBlockByID(blockId));
  const [url, setUrl] = useState("");
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(refElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    closeSheetMenu();
    if (block) {
      dispatch(
        updateBlock({
          ...block,
          properties: {
            url,
          },
        })
      );
    }
  };

  return (
    <Popover as="div">
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        static
        className="w-72 flex flex-col z-30 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 rounded-lg px-1 py-1"
      >
        <form
          className="flex flex-col px-2 my-1 gap-2 leading-none text-xs"
          onSubmit={handleSubmit}
        >
          <Label textColor="text-gray-400" textSize="text-xs">
            EMBED VIDEO
          </Label>
          <TextInput
            name="url"
            placeholder="Enter a youtube video URL"
            textSize="text-sm"
            value={url}
            onChange={(event: any) => setUrl(event.target.value)}
          />
          <PrimaryButton type="submit" paddingY="py-1">
            Submit
          </PrimaryButton>
        </form>
      </Popover.Panel>
    </Popover>
  );
};
