import ReactApexChart from "react-apexcharts";
import { UUID } from "types";

type PolicyHistoryChartProps = {
  policyId: UUID;
  height?: number;
};

export const PolicyHistoryChart = ({
  policyId,
  height = 200,
}: PolicyHistoryChartProps) => {
  const data = {
    "13 Nov 2017": 8107.85,
    "14 Nov 2017": 8128,
    "15 Nov 2017": 8122.9,
    "16 Nov 2017": 8165.5,
    "17 Nov 2017": 8340.7,
    "20 Nov 2017": 8423.7,
    "21 Nov 2017": 8423.5,
    "22 Nov 2017": 8514.3,
    "23 Nov 2017": 8481.85,
    "24 Nov 2017": 8487.7,
    "27 Nov 2017": 8506.9,
    "28 Nov 2017": 8626.2,
    "29 Nov 2017": 8668.95,
    "30 Nov 2017": 8602.3,
    "01 Dec 2017": 8607.55,
    "04 Dec 2017": 8512.9,
    "05 Dec 2017": 8496.25,
    "06 Dec 2017": 8600.65,
    "07 Dec 2017": 8881.1,
    "08 Dec 2017": 9340.85,
  };
  const state: any = {
    series: [
      {
        data: Object.values(data),
      },
    ],
    options: {
      colors: ["#c79436"],
      chart: {
        id: "realtime",
        type: "line",
        height: "auto",
        animations: {
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 10,
        dashArray: 2,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      title: {
        show: false,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 10,
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      labels: Object.keys(data),
      annotations: {
        xaxis: [
          {
            x: new Date(Object.keys(data)[0]).getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Tracking began",
            },
          },
          {
            x: new Date(Object.keys(data)[9]).getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Current",
            },
          },
          {
            x: new Date(Object.keys(data)[14]).getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Predicted",
            },
          },
          {
            x: new Date(Object.keys(data)[17]).getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Predicted",
            },
          },
        ],
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        type="line"
        options={state.options}
        series={state.series}
        height={height}
      />
    </div>
  );
};
