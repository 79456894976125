import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreatePolicyRequest,
  DataResponse,
  GetPoliciesResponse,
  Policy,
  RSSFeed,
  UpdatePolicyRequest,
  UUID,
} from "types";
import { API } from "lib/API";

type FetchPoliciesParams = {
  coreMetricIds?: UUID[];
};

export const fetchPolicies = createAsyncThunk(
  "policies/list",
  async (params: FetchPoliciesParams): Promise<Policy[]> => {
    const requestParams: any = {
      pageLimit: 200,
    };
    if (params.coreMetricIds?.length) {
      requestParams.coreMetricId = params.coreMetricIds;
    }
    const { data } = await API.GET<GetPoliciesResponse>(
      "/policies",
      requestParams
    );
    return data;
  }
);

export const fetchPolicyByID = createAsyncThunk(
  "policies/fetchById",
  async (id: UUID): Promise<Policy> => {
    const { data } = await API.GET<DataResponse<Policy>>(`/policies/${id}`);
    return data;
  }
);

export const fetchPolicyRSSFeedByID = createAsyncThunk(
  "policies/fetchRSSFeed",
  async (id: UUID): Promise<RSSFeed> => {
    const { data } = await API.GET<DataResponse<RSSFeed>>(
      `/policies/${id}/rssfeed`
    );
    return data;
  }
);

export const createPolicy = createAsyncThunk(
  "policies/create",
  async (request: CreatePolicyRequest): Promise<Policy> => {
    const { data } = await API.POST<CreatePolicyRequest, DataResponse<Policy>>(
      "/policies",
      request
    );
    return data;
  }
);

export const updatePolicy = createAsyncThunk(
  "policies/update",
  async (request: UpdatePolicyRequest): Promise<Policy> => {
    const { data } = await API.PUT<UpdatePolicyRequest, DataResponse<Policy>>(
      `/policies/${request.id}`,
      request
    );
    return data;
  }
);

export const deletePolicy = createAsyncThunk(
  "policies/delete",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/policies/${id}`);
  }
);
