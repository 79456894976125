import { FlexContainer, Title } from "components/shared";
import { MockAppsStore } from "components/shared/features/apps";

export const AppsPage = () => {
  return (
    <FlexContainer
      direction="flex-col"
      gap="gap-10"
      flex="flex-1"
      width="w-full"
    >
      <Title>App Store</Title>
      <MockAppsStore />
    </FlexContainer>
  );
};
