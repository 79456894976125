import { v4 as uuid } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopupFlag } from "types";

export interface popupFlagReducerState {
  data: PopupFlag[];
}

const initialState: popupFlagReducerState = {
  data: [],
};

export const popupFlagSlice = createSlice({
  name: "popupFlag",
  initialState,
  reducers: {
    openPopupFlag: (state, action: PayloadAction<Omit<PopupFlag, "id">>) => {
      state.data = [
        ...state.data,
        {
          ...action.payload,
          id: uuid(),
        },
      ];
    },
    closePopupFlag: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(({ id }) => id !== action.payload);
    },
  },
});

export const { openPopupFlag, closePopupFlag } = popupFlagSlice.actions;

export const popupFlagReducer = popupFlagSlice.reducer;
