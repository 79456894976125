import {GenericInputProps, Input} from "./Input";

type TextInputProps = GenericInputProps & {};

export const TextInput = (props: TextInputProps) => {
    return (
        <Input {...props} type="text" />
    );
};



