import { createSlice } from "@reduxjs/toolkit";
import { KeyResult, UUID } from "types";
import { fetchKeyResults } from "store/reducers/keyResults/keyResults.thunks";

export type KeyResultsReducerStateData = Record<UUID, KeyResult>;

export interface KeyResultsReducerState {
  data: KeyResultsReducerStateData;
}

const initialState: KeyResultsReducerState = {
  data: {},
};

const keyResultsSlice = createSlice({
  name: "keyResults",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchKeyResults.fulfilled, (state, action) => {
      state.data = action.payload.reduce(
        (data: KeyResultsReducerStateData, value: KeyResult) => ({
          ...data,
          [value.id]: value,
        }),
        state.data
      );
    });
  },
});

export const keyResultsReducer = keyResultsSlice.reducer;
