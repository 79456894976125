import { useSelector } from "store";
import { selectKeyPerformanceMetricsList } from "store/reducers";
import { KeyPerformanceMetricsTableRow } from "components/shared/features/metrics/KeyPerformanceMetricsTableRow/KeyPerformanceMetricsTableRow";

type KeyPerformanceMetricsTableProps = {};

export const KeyPerformanceMetricsTable = (
  props: KeyPerformanceMetricsTableProps
) => {
  const keyPerformanceMetricsList = useSelector(
    selectKeyPerformanceMetricsList
  );

  return (
    <table className="w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Value
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Core metric
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {keyPerformanceMetricsList.map((keyPerformanceMetric) => (
          <KeyPerformanceMetricsTableRow
            key={keyPerformanceMetric.id}
            keyPerformanceMetricId={keyPerformanceMetric.id}
          />
        ))}
      </tbody>
    </table>
  );
};
