import styled from "styled-components";
import { useEffect, useState } from "react";
import { PaperClipIcon } from "@heroicons/react/outline";
import { TBlock, TFileBlock } from "types";
import { useSheet } from "hooks/useSheet";
import { useDispatch, useSelector } from "store";
import { fetchFile, selectFileByID } from "store/reducers";
import { Text, FileBlockMenu, LoadingIcon } from "components/shared";

const BlockCard = styled.div`
  display: flex;
  align-items: center;
  background-color: rgb(241, 241, 239);
  color: rgb(156, 163, 175);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgb(229, 229, 229);
  }
`;

const FileIcon = styled(PaperClipIcon)`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

type FileBlockProps = {
  block: TBlock;
};

export const FileBlock = ({ block }: FileBlockProps) => {
  const dispatch = useDispatch();
  const { openSheetMenu } = useSheet();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const properties = block.properties as TFileBlock;
  const file = useSelector(selectFileByID(properties?.fileId));

  useEffect(() => {
    if (properties.fileId && !file) {
      setLoading(true);
      dispatch(fetchFile(properties.fileId))
        .unwrap()
        .finally(() => setLoading(false));
    }
  }, [properties?.fileId]);

  const openMenu = () => {
    openSheetMenu(
      <FileBlockMenu refElement={ref} title="EMBED FILE" blockId={block.id} />
    );
  };

  if (!properties.url) {
    return (
      <BlockCard ref={setRef} onClick={openMenu}>
        <FileIcon />
        <Text
          textColor="text-gray-400"
          lineHeight="leading-8"
          textSize="text-sm"
        >
          Embed a file
        </Text>
      </BlockCard>
    );
  }

  const handleClick = () => {
    if (properties.url) {
      window.open(properties.url, "_blank");
    }
  };

  return (
    <BlockCard onClick={handleClick}>
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          <FileIcon />
          <Text
            textColor="text-gray-700"
            lineHeight="leading-8"
            textSize="text-sm"
          >
            {file?.fileName}
          </Text>
        </>
      )}
    </BlockCard>
  );
};
