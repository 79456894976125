import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmojiSadIcon, LightBulbIcon } from "@heroicons/react/solid";
import { Impact } from "types";
import {
  FlexContainer,
  GreenPill,
  Header,
  LoadingIcon,
  Text,
  Title,
} from "components/shared";
import { MockAppToolbar } from "components/shared/features/apps";
import { AppImpactBar } from "pages/features/apps/AppImpactBar/AppImpactBar";
import { DelayRender } from "components/shared/layouts/DelayRender/DelayRender";

type AppSolution = {
  name: string;
  description?: string | null;
  timescale?: number;
  effectiveImpact?: Impact | null;
  costImpact?: Impact | null;
  capexImpact?: Impact | null;
  solutionUrl?: string;
};

export const AppPage = () => {
  return (
    <FlexContainer
      direction="flex-col"
      gap="gap-6"
      flex="flex-1"
      width="w-full"
    >
      <Title>Solution explorer</Title>
      <MockAppToolbar />
      <div className="bg-white overflow-hidden shadow rounded-lg text-gray-900 divide-y divide-gray-200">
        <TabCardTitle />
        <TabCardBody />
      </div>
    </FlexContainer>
  );
};

export const TabCardTitle = () => {
  const { tabId } = useParams();

  const renderTabTitle = (): string => {
    switch (tabId) {
      case "summary":
        return "Solutions summary";
      case "impact":
        return "Impact analysis";
      case "risks":
        return "Risk analysis";
      case "costs":
        return "Cost analysis";
      case "datasets":
        return "Datasets";
      default:
        return "";
    }
  };

  return (
    <div className="px-4 py-5 sm:px-6">
      <Header>{renderTabTitle()}</Header>
    </div>
  );
};

export const TabCardBody = () => {
  const { tabId } = useParams();

  if (!(tabId === "summary" || tabId === "impact")) {
    return (
      <div className="bg-gray-50 text-gray-700 flex flex-col text-center gap-2 px-4 py-5 sm:p-6">
        <Header>This feature is not ready just yet</Header>
        <Text>Please come back when it becomes available.</Text>
      </div>
    );
  }

  if (tabId === "impact") {
    return <SolutionsComparison />;
  }

  return (
    <div className="bg-gray-50">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Location</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            Luxor, Egypt
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Local problem</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul
              role="list"
              className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center">
                  <EmojiSadIcon
                    className="flex-shrink-0 h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2 flex-1 w-0 truncate">
                    High waste generation
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <AppImpactBar value={35} effect="importance" />
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    More info
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Local solutions</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul
              role="list"
              className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center">
                  <LightBulbIcon
                    className="flex-shrink-0 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2 flex-1 w-0 truncate">
                    Citizen driven waste collection
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <AppImpactBar value={70} />
                </div>
                <div className="ml-4 flex-shrink-0">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    More info
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  );
};

export const SolutionsComparison = () => {
  const [solutions, setSolutions] = useState<AppSolution[]>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(
      "https://gist.githubusercontent.com/vincentracine/0d66f7b254ef34d8cd74e303ecd578cb/raw/pi-solutions-comparison.json"
    );
    const data = await response.json();
    setSolutions(data);
  };

  if (!solutions) {
    return <LoadingIcon />;
  }

  return (
    <div className="bg-gray-50">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Solutions comparison
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <ul
                role="list"
                className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                {solutions.map((solution) => {
                  const { timescale, effectiveImpact, capexImpact } = solution;
                  return (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 truncate">
                          {solution.name}
                          <br />
                          <span className="text-gray-400">
                            Deployed in {timescale} months on average
                          </span>
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        {capexImpact === "low" ? (
                          <GreenPill label="$" />
                        ) : capexImpact === "medium" ? (
                          <GreenPill label="$$" />
                        ) : (
                          <GreenPill label="$$$" />
                        )}
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <AppImpactBar
                          value={
                            effectiveImpact === "high"
                              ? 80
                              : effectiveImpact === "medium"
                              ? 40
                              : 15
                          }
                        />
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href={solution.solutionUrl}
                          target="_blank"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          More info
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </DelayRender>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Predicted impact
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <ul role="list" className="list-disc pl-4">
                <li>92% waste sorting precision</li>
                <li>80% lower waste management costs</li>
              </ul>
            </DelayRender>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Primary solution
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <Text>
                An innovative solution for collecting empty beverage containers
                at the source and increasing the recycling rates with a reward
                system.
              </Text>
            </DelayRender>
          </dd>
        </div>

        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Technology enabled
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <Text>Uses AI-enabled photo recognition technology.</Text>
            </DelayRender>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Features</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <Text>
                An innovative way for object recognition technology to implement
                mature AI, offering higher accuracy and faster processing
                speeds. Suitable for countries that do not have deposit systems
                and a successful way to implement Smart Reverse Vending Machine
                projects. The flexibility to handle local products is available
                through customisable AI Library. Additional features include:
              </Text>
              <ul role="list" className="list-disc pl-4">
                <li>built in shredding and crushing</li>
                <li>level monitoring with remote control</li>
                <li>citizen rewards & incentives scheme</li>
              </ul>
            </DelayRender>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Challenges</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <ul role="list" className="list-decimal pl-4">
                <li>
                  Machine can only operate within specified environmental
                  conditions (within 0 to 50 C).
                </li>
                <li>A reliable and clean electrical supply is required. </li>
                <li>
                  Container can only take up to 2.5Lt. of waste post shredding
                  and crushing
                </li>
              </ul>
            </DelayRender>
          </dd>
        </div>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Further information
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <DelayRender delay={2000} textColor="text-gray-900">
              <a href="#" className="text-pantone-600 underline">
                <Text>See here</Text>
              </a>
            </DelayRender>
          </dd>
        </div>
      </dl>
    </div>
  );
};
