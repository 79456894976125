import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.solutions;

export const selectSolutions = createSelector(
  selectState,
  (state) => state.data
);

export const selectSolutionsList = createSelector(selectSolutions, (data) =>
  Object.values(data)
);

export const selectSolutionByID = (id: UUID) =>
  createSelector(selectSolutions, (data) => data[id]);
