import { forwardRef } from "react";
import { Container, ContainerProps } from "components/shared/layouts/Container";

type FlexContainerProps = ContainerProps & {};

export const FlexContainer = forwardRef(
  (props: FlexContainerProps, ref: any) => {
    return (
      <Container ref={ref} direction="flex-row" {...props} layout="flex" />
    );
  }
);
