import { combineReducers } from "redux";
import {
  popupFlagReducer,
  objectivesReducer,
  solutionsReducer,
  cmsReducer,
  filesReducer,
  simulationsReducer,
  accountsReducer,
} from "store/reducers";
import { keyResultsReducer } from "store/reducers/keyResults/keyResults.slice";
import { metricsReducer } from "store/reducers/metrics/metrics.slice";
import { policiesReducer } from "store/reducers/policies/policies.slice";
import { coreMetricsReducer } from "store/reducers/coreMetrics/coreMetrics.slice";
import { baseApi } from "store/base.api";

const appReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  popupFlag: popupFlagReducer,
  solutions: solutionsReducer,
  objectives: objectivesReducer,
  keyResults: keyResultsReducer,
  metrics: metricsReducer,
  policies: policiesReducer,
  coreMetrics: coreMetricsReducer,
  cms: cmsReducer,
  files: filesReducer,
  simulations: simulationsReducer,
  accounts: accountsReducer,
});

export type RootStore = ReturnType<typeof appReducer>;

const reducer = (state: RootStore | undefined, action: any) => {
  return appReducer(state, action);
};

export default reducer;
