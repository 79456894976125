import { UUID } from "types";

export enum ChatMessageAuthor {
  User,
  Bot,
}

export enum ChatMessageType {
  Text,
  UseCasePicker,
  ObjectivePicker,
  ReviewModel,
}

export interface ChatMessage<T = unknown> {
  id: UUID;
  type: ChatMessageType;
  author: ChatMessageAuthor;
  message: string;
  data?: T;
  createdAt: number;
}
