import { SortableHandle } from "react-sortable-hoc";
import { DragIcon } from "assets/icons/DragIcon";
import { BlockControl } from "components/shared/cms";

type DragHandleProps = {
  onClick?: () => void;
  className?: string;
};

export const DragHandle = SortableHandle(
  ({ onClick, className }: DragHandleProps) => (
    <BlockControl className={className} onClick={onClick}>
      {DragIcon}
    </BlockControl>
  )
);
