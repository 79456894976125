import { UUID } from "types";
import { useSelector } from "store";
import { selectCoreMetricByID } from "store/reducers";
import { Text } from "components/shared";
import { generatePath, useNavigate } from "react-router-dom";
import { RoutePath } from "routes/RoutePath";

type CoreMetricsTableRowProps = {
  coreMetricId: UUID;
};

export const CoreMetricsTableRow = ({
  coreMetricId,
}: CoreMetricsTableRowProps) => {
  const navigate = useNavigate();
  const entity = useSelector(selectCoreMetricByID(coreMetricId));

  if (!entity) {
    return null;
  }

  return (
    <tr
      className="cursor-pointer hover:bg-blue-50 hover:bg-opacity-50"
      onClick={() =>
        navigate(generatePath("/settings/core-metrics/:id", { id: entity.id }))
      }
    >
      <td className="px-6 py-4 text-sm text-gray-900">
        <div className="text-sm mb-0.5 font-medium text-gray-900">
          {entity.name}
        </div>
        <div className="text-sm">
          <Text textColor="text-gray-400">
            {entity.description || "No description"}
          </Text>
        </div>
      </td>
    </tr>
  );
};
