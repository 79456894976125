import { useEffect } from "react";
import { useDispatch } from "store";
import { LoadingIcon } from "components/shared";
import { logout } from "store/reducers";

export const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <LoadingIcon />
    </div>
  );
};
